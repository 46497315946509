<template>
  <main style="background-color: #f5f5f5;">
    <!-- BANNERR -->
    <div
      id="banner"
      :style="{
        'background-image':
          'url(' + require('@/assets/img/workwithus/banner.jpg') + ')'
      }"
    >
      <div class="texto-banner">
        <h2 class="text-white text-3xl font-bold">Trabaja con Nosotros</h2>
        <p class="text-white -100-white">Home / Trabaja con nosotros</p>
      </div>
    </div>

    <!-- SECTION NOSOTROSs -->
    <section class="pb-10">
      <div class="container mt-12  px-4 md:px-20">
        <!-- DESCRIPTION -->
        <div class="grid grid-cols-2 mb-12">
          <div
            class="col-span-2 md:col-span-1 mb-4 order-2 md:order-1"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <p class="text-gray-700 mb-0">Trabaja con</p>
            <h4 class="text-3xl text-gray-700 mb-4">Nosotros</h4>
            <p class="text-gray-700 italic font-semibold mb-4">
              "El éxito está conectado con la acción. la gente exitosa se
              mantiene en movimiento. Hacen errores, pero nunca se dan por
              vencido"
            </p>
            <p class="text-gray-600 mb-2 font-thin text-justify">
              Tu trabajo va a llenar gran parte de tu vida, y la única manera de
              hacer un gran trabajo es amar lo que haces.
            </p>
            <p class="text-gray-600 mb-2 font-thin text-justify">
              Debido a la exigente demanda competitiva, te invitamos a ser parte
              de la Cooperativa Credi Ya Ltda., quien se encuentra en la
              búsqueda de profesionales que se caractericen por su sentido de
              liderazgo, trabajo en equipo, honestidad y lealtad.
            </p>
            <p class="text-gray-600 mb-2 font-thin text-justify">
              ¿Te gustaría pertenecer a esta gran familia? Te invitamos a
              revisar nuestras ofertas laborales.
            </p>
          </div>
          <div
            class="col-span-2 md:col-span-1 order-1 md:order-2"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <img
              src="@/assets/img/workwithus/icono.png"
              class="float-center md:float-right m-auto"
              alt="credito crediya"
              width="300"
            />
          </div>
        </div>
      </div>

      <!-- FORMULARIO -->
      <div
        class="container mx-auto w-full md:w-1/2 p-4 md:p-10 bg-white rounded "
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <h2
          class="bg-red-700 uppercase text-white rounded text-center text-2xl p-1 font mb-6"
        >
          Ingrese los datos
        </h2>
        <form>
          <!-- col 1 -->
          <div class="grid grid-cols-2 mb-5">
            <div class="col-span-2 md:col-span-1">
              <label for="name" class="text-gray-700 font-bold block"
                >Nombre Completo</label
              >
              <input
                type="text"
                id="name"
                v-model="name"
                placeholder="JUAN PEREZ"
                class="border border-gray-600 p-1 my-2 -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
              />
            </div>
            <div class="col-span-2 md:col-span-1">
              <label for="phone" class="text-gray-700 font-bold block"
                >Teléfono</label
              >
              <input
                type="text"
                id="phone"
                v-model="phone"
                placeholder="0958764246"
                class="border border-gray-600 p-1 my-2 -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
              />
            </div>
          </div>

          <!-- col 2 -->
          <div class="grid grid-cols-2 mb-5">
            <div class="col-span-2 md:col-span-1">
              <label for="email" class="text-gray-700 font-bold block"
                >Correo</label
              >
              <input
                type="email"
                id="email"
                v-model="email"
                placeholder="email@email.com"
                class="border border-gray-600 p-1 my-2 -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
              />
            </div>
            <div class="col-span-2 md:col-span-1">
              <label for="city" class="text-gray-700 font-bold block"
                >Ciudad</label
              >
              <select
                name="city"
                v-model="city"
                id="city"
                class="border text-sm px-4 py-2 w-full rounded bg-gray-200"
              >
                <option value="Riobamba">Riobamba</option>
                <option value="Guayaquil">Guayaquil</option>
                <option value="Matriz">Matriz</option>
                <option value="Quito">Quito</option>
                <option value="Ibarra">Ibarra</option>
                <option value="Macas">Macas</option>
                <option value="Latacunga">Latacunga</option>
                <option value="Sangolqui">Sangolqui</option>
                <option value="Izamba">Izamba</option>
                <option value="SantoDomingo">Santo Domingo</option>
                <option value="HuachiG">Huachi Grande</option>
                <option value="Quisapincha">Quisapincha</option>
              </select>
            </div>
          </div>
          <!-- col 3 -->
          <div class="grid grid-cols-2 mb-5">
            <div class="col-span-2 md:col-span-1">
              <label for="aspiracion" class="text-gray-700 font-bold block"
                >Aspiracion Salarial</label
              >
              <input
                type="number"
                id="aspiracion"
                v-model="aspiracion"
                placeholder="678"
                class="border border-gray-600 p-1 my-2 -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
              />
            </div>
            <div class="col-span-2 md:col-span-1">
              <div class="col-span-2 md:col-span-6">
                <label for="movilizacion" class="text-black  block">
                  ¿Tiene Movilización?
                </label>
                <select
                  name="movilizacion"
                  v-model="movilizacion"
                  id="movilizacion"
                  class="border text-sm px-4 py-2 w-full rounded bg-gray-200"
                >
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </select>
              </div>
            </div>
          </div>
          <!--ingreos de un select de datos-->

          <div class="grid grid-cols-2 mb-5">
            <div class="col-span-2 md:col-span-1">
              <label for="area" class="text-black  block">
                Área
              </label>
              <select
                v-model="area"
                @change="onChangeParroquia($event)"
                class="border border-gray-600 p-1  -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
                aria-label="Default select example"
              >
                <option selected>Seleccione Área</option>
                <option
                  :value="pro.provincia"
                  v-for="pro in provincias_totales"
                  :key="pro.provincia"
                  >{{ pro.provincia }}</option
                >
              </select>
            </div>
            <div class="col-span-2 md:col-span-1">
              <div class="col-span-2 md:col-span-6">
                <label for="cargo" class="text-black  block">
                  Cargo al que Aplica
                </label>
                <select
                  v-model="cargo"
                  @change="onChangeCiudad($event)"
                  class="border border-gray-600 p-1  -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
                  aria-label="Default select example"
                >
                  <option selected>Seleccione un Cargo</option>
                  <option
                    :value="cant.canton"
                    v-for="cant in cantones_totales"
                    :key="cant.canton"
                    >{{ cant.canton }}</option
                  >
                </select>
              </div>
            </div>
          </div>

          <!--fin de infreso-->
          <!-- col 4 -->
          <div class="grid grid-cols-2 mb-5">
            <div class="col-span-2 md:col-span-1">
              <!--<label for="message" class="text-gray-700 font-bold block"
                >Cargo al que Aplica</label
              >
              <select
                name="message"
                v-model="message"
                id="message"
                class="border text-sm px-4 py-2 rounded bg-gray-200"
              >
                <option value="Asesor de Negocios">Asesor de Negocios</option>
                <option value="Asesor de Captaciones"
                  >Asesor de Captaciones</option
                >
                <option value="Cajero">Cajero</option>
                <option value="Asistente de Negocios"
                  >Asistente de Negocios</option
                >
                <option value="Disenio">Diseñador</option>
                <option value="Jefe de Agencia">Jefe de Agencia</option>
                <option value="Administrativo">Administrativo</option>
              </select>-->
            </div>
            <div class="flex mt-8">
              <!-- botontes -->
              <div
                class="overflow-hidden relative w-1/2 flex justify-center cursor-pointer"
              >
                <button
                  class="bg-gray-700 hover:bg-gray-800 text-white py-1  rounded w-full  h-10 "
                >
                  <span>Hoja de Vida (pdf)</span>
                </button>
                <input
                  class="cursor-pointer absolute block py-2 px-4 w-full opacity-0 pin-r pin-t"
                  type="file"
                  name="documents[]"
                  accept="pdf/*"
                  @change="uploadPdf($event)"
                />
              </div>
              <button
                type="button"
                class="bg-gray-700 hover:bg-gray-800 text-white py-1 rounded w-1/2 mx-2 h-10"
                @click="saveForm"
                @keydown.tab.exact.prevent=""
              >
                Enviar
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  </main>
</template>
<script>
import api from "../../api/workus";
import { provincias } from "@/api/organigrama";

export default {
  name: "WorkWithUs",
  data() {
    return {
      name: "",
      phone: "",
      email: "",
      city: "",
      aspiracion: "",
      movilizacion: "",
      area: "",
      cargo: "",
      file: "",
      provincias_totales: [],
      cantones_totales: [],
      ciudades_totales: []
    };
  },

  mounted() {
    this.call_provincias();
  },
  methods: {
    saveForm() {
      if (
        !this.name ||
        !this.phone ||
        !this.email ||
        !this.city ||
        !this.aspiracion ||
        !this.movilizacion ||
        !this.area ||
        !this.cargo ||
        !this.file
      ) {
        this.$swal("Llene todo los campos", "", "error");
      } else {
        api
          .saveForm(
            this.name,
            this.phone,
            this.email,
            this.city,
            this.aspiracion,
            this.movilizacion,
            this.area,
            this.cargo,
            this.file
          )
          .then(resp => {
            console.log(resp);
            if (resp.status == "ok") {
              this.$swal("Formulario Guardado", "", "success").then(() => {
                this.name = "";
                this.phone = "";
                this.email = "";
                this.city = "";
                this.aspiracion = "";
                this.movilizacion = "";
                this.area = "";
                this.cargo = "";
                this.file = "";
              });
            } else {
              this.$swal("Error al guardar", "", "error");
            }
          });
      }
    },
    // LLAMA A TODAS LAS PROVINCIAS
    call_provincias() {
      this.provincias_totales = provincias;
    },

    // CAMBIA A LA CANTONES
    onChangeParroquia(event) {
      let provincia_selecciona = event.target.value;
      this.provincias_totales.forEach(prov => {
        if (provincia_selecciona == prov.provincia) {
          this.cantones_totales = prov.cantones;
        }
      });
    },

    // CAMBIA A LA CIUDADES
    onChangeCiudad(event) {
      let canton_seleccionado = event.target.value;
      this.cantones_totales.forEach(cant => {
        if (canton_seleccionado == cant.canton) {
          this.ciudades_totales = cant.parroquias;
        }
      });
    },
    show() {
      this.$modal.show("modal-terminos");
    },
    opened() {
      console.log("se abrio");
      // this.$refs.nombre.focus();
    },
    hide() {
      this.$modal.hide("modal-new-user");
    },
    // PDF UPLOADER
    uploadPdf(event) {
      let data = new FormData();
      data.append("name", event.target.files[0].name);
      data.append("file", event.target.files[0]);
      if (data != "")
        api.uploadPdf(data).then(resp => {
          console.log("file", resp);
          this.file = resp;
        });
    }
  }
};
</script>

<style scoped>
#banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 350px;
  width: 100%;
  position: relative;
}
.texto-banner {
  position: absolute;
  left: 10%;
  top: 40%;
}
.texto-principal {
  position: absolute;
  left: 10%;
  top: 40%;
}
</style>
