export const provincias = [
  {
    provincia: "NEGOCIOS",
    cantones: [
      {
        canton: "RECAUDADOR"
      },
      {
        canton: "ASESOR DE CRÉDITOS"
      },
      {
        canton: "ASESOR DE INVERSIONES"
      },
      {
        canton: "ASISTENTE DE NEGOCIOS"
      }
    ]
  },
  {
    provincia: "OPERATIVO",
    cantones: [
      {
        canton: "CAJERO"
      },
      {
        canton: "SUPERVISOR OPERATIVO"
      }
    ]
  },
  {
    provincia: "ADMINISTRATIVO",
    cantones: [
      {
        canton: "CONTABILIDAD"
      },
      {
        canton: "ASISTENTE DE CONTABILIDAD "
      },
      {
        canton: "FINANCIERO"
      },
      {
        canton: "RIESGOS "
      },
      {
        canton: "CUMPLIMIENTO"
      },
      {
        canton: "PROCESOS"
      },
      {
        canton: "SISTEMAS"
      },
      {
        canton: "TALENTO HUMANO"
      },
      {
        canton: "JEFE DE SEGURIDAD INFORMÁTICA"
      }
    ]
  },

  {
    provincia: "MARKETING",
    cantones: [
      {
        canton: "DISEÑO"
      },
      {
        canton: "JEFE DE MARKETING"
      },
      {
        canton: "PRODUCTOR AUDIO VISUAL"
      }
    ]
  }
];
