<template>
  <main style="background-color: #f5f5f5;">
    <!-- BANNER -->
    <div
      id="banner"
      :style="{
        'background-image':
          'url(' +
          require('@/assets/img/proveedores/banner-proveedores.jpg') +
          ')'
      }"
    >
      <div class="texto-banner">
        <h2 class="text-white text-3xl font-bold">Proveedores</h2>
        <p class="text-gray-100">Home / Proveedores</p>
      </div>
    </div>

    <!-- SECTION TRANSPARENCIA -->
    <section class="py-10">
      <!-- TABLE -->
      <div
        class="container mx-auto w-full md:w-3/4 p-4 md:p-10 bg-white rounded "
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div class="bg-red-700 uppercase text-white rounded text-xl p-1 mb-6">
          <div class="flex justify-between mx-6 md:mx-20">
            <span>DETALLE</span>
            <span>Ver</span>
          </div>
        </div>
        <div class="container flex justify-between px-2 md:px-12 py-1">
          <div class="left flex">
            <img
              src="@/assets/img/transparency/icono.png"
              alt="icono crediya"
              class="mr-2"
              style="height:60px; margin: -15px"
            />
            <h5 class="uppercase text-lg gris">
              ANEXO 3 - LISTA DE CHEQUEO - REQUISITOS GENERALES
            </h5>
          </div>
          <div class="right">
            <a
              href="https://drive.google.com/file/d/1mg6SSONao8uZ8Li0qm52SEYDcJV12WZX/view?usp=sharing"
              target="_blank"
              class="container bg-gray-100 px-10 rounded hover:bg-gray-300 hover:text-gray-500 cursor-pointer py-1"
            >
              <font-awesome-icon :icon="['fas', 'eye']" class="gris " />
            </a>
          </div>
        </div>
        <div class="container flex justify-between px-2 md:px-12 py-1">
          <div class="left flex">
            <img
              src="@/assets/img/transparency/icono.png"
              alt="icono crediya"
              class="mr-2"
              style="height:60px; margin: -15px"
            />
            <h5 class="uppercase text-lg gris">
              ANEXO 3-A - LISTA DE CHEQUEO
            </h5>
          </div>
          <div class="right">
            <a
              href="https://drive.google.com/file/d/1lQqv1ntLAl9VNYpAUAYrqIP4VAsVooVg/view?usp=sharing"
              target="_blank"
              class="container bg-gray-100 px-10 rounded hover:bg-gray-300 hover:text-gray-500 cursor-pointer py-1"
            >
              <font-awesome-icon :icon="['fas', 'eye']" class="gris " />
            </a>
          </div>
        </div>

        <!-- LIST 1 -->
        <div class="container flex justify-between px-2 md:px-12 py-1">
          <div class="left flex">
            <img
              src="@/assets/img/transparency/icono.png"
              alt="icono crediya"
              class="mr-2"
              style="height:60px; margin: -15px"
            />
            <h5 class="uppercase text-lg gris">
              INSTRUCCIONES PRE CALIFICACION PROVEEDORES
            </h5>
          </div>
          <div class="right">
            <a
              href="https://drive.google.com/file/d/1DUll8-D_qwSByb06wcwbel-eoaqrj-Bt/view?usp=sharing"
              target="_blank"
              class="container bg-gray-100 px-10 rounded hover:bg-gray-300 hover:text-gray-500 cursor-pointer py-1"
            >
              <font-awesome-icon :icon="['fas', 'eye']" class="gris " />
            </a>
          </div>
        </div>

        <!-- LIST 2 -->
        <div class="container flex justify-between px-2 md:px-12 py-1">
          <div class="left flex">
            <img
              src="@/assets/img/transparency/icono.png"
              alt="icono crediya"
              class="mr-2"
              style="height:60px; margin: -15px"
            />
            <h5 class="uppercase text-lg gris">
              INFORMACIÓN GENERAL DEL POSTULANTE
            </h5>
          </div>
          <div class="right">
            <a
              href="https://drive.google.com/file/d/1r69nIOLZVgtN6snAL2SHxEjSxASCgBYl/view?usp=sharing"
              target="_blank"
              class="container bg-gray-100 px-10 rounded hover:bg-gray-300 hover:text-gray-500 cursor-pointer py-1"
            >
              <font-awesome-icon :icon="['fas', 'eye']" class="gris " />
            </a>
          </div>
        </div>

        <!-- LIST 2 -->
        <div class="container flex justify-between px-2 md:px-12 py-1">
          <div class="left flex">
            <img
              src="@/assets/img/transparency/icono.png"
              alt="icono crediya"
              class="mr-2"
              style="height:60px; margin: -15px"
            />
            <h5 class="uppercase text-lg gris">
              DESEMPEÑO PASADO Y ACTUAL Y EXPERIENCIA
            </h5>
          </div>
          <div class="right">
            <a
              href="https://drive.google.com/file/d/1i3H1LmXFBiR6gyjMauRAqRHmCNVk79RJ/view?usp=sharing"
              target="_blank"
              class="container bg-gray-100 px-10 rounded hover:bg-gray-300 hover:text-gray-500 cursor-pointer py-1"
            >
              <font-awesome-icon :icon="['fas', 'eye']" class="gris " />
            </a>
          </div>
        </div>
        <!-- LIST 2 -->
        <div class="container flex justify-between px-2 md:px-12 py-1">
          <div class="left flex">
            <img
              src="@/assets/img/transparency/icono.png"
              alt="icono crediya"
              class="mr-2"
              style="height:60px; margin: -15px"
            />
            <h5 class="uppercase text-lg gris">
              CAPACIDAD Y COMPETENCIA PARA ENTREGAS DE BIENES O SERVICIOS
            </h5>
          </div>
          <div class="right">
            <a
              href="https://drive.google.com/file/d/1kqffuqG7FF0AOrlfprIcxbVPDDQhxRgX/view?usp=sharing"
              target="_blank"
              class="container bg-gray-100 px-10 rounded hover:bg-gray-300 hover:text-gray-500 cursor-pointer py-1"
            >
              <font-awesome-icon :icon="['fas', 'eye']" class="gris " />
            </a>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
export default {
  name: "WorkWithUs"
};
</script>

<style scoped>
#banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 350px;
  width: 100%;
  position: relative;
}
.texto-banner {
  position: absolute;
  left: 10%;
  top: 40%;
}
.texto-principal {
  position: absolute;
  left: 10%;
  top: 40%;
}
</style>
