<template>
  <div>
    <div
      class="container w-full flex justify-center mt-32 mb-32"
      v-if="isLoading"
    >
      <bounce-loader :loading="isLoading" :color="'#f00'" :size="100" />
    </div>
    <main style="background-color: #f5f5f5;" v-if="!isLoading">
      <!-- BANNER -->
      <div
        id="banner"
        :style="{
          'background-image': `url('${url}${product.banner}')`
        }"
      >
        <div class="texto-banner">
          <h2
            class="text-white text-3xl font-bold"
            style="text-shadow: 2px 2px 4px rgb(0 0 0 / 10%);"
          >
            {{ product.name }}
          </h2>
          <p class="text-gray-100">Home / {{ product.name }}</p>
        </div>
      </div>

      <!-- CONTENT -->
      <section>
        <div
          class="container mt-12  px-4 md:px-20"
          data-aos="fade-down"
          data-aos-duration="1000"
        >
          <!-- DESCRIPTION -->
          <div class="grid grid-cols-2 mb-12">
            <div class="col-span-2 md:col-span-1 mb-4 order-2 md:order-1">
              <!-- <p class="text-gray-700 mb-0" v-if="product.simulador">Créditos</p> -->
              <h4 class="text-3xl text-gray-700 mb-4">{{ product.name }}</h4>
              <p class="text-gray-600 text-justify">
                {{ product.description }}
              </p>
              <br />
              <a
                v-if="product.simulator === 'credito'"
                class="inline-block bg-red-700 text-white px-8 py-1 mt-4 hover:bg-red-600"
                href="/simulador-credito"
                >Simulador</a
              >

              <a
                style="margin-left: 20px;"
                v-if="product.simulator === 'credito'"
                class="inline-block bg-red-700 text-white px-8 py-1 mt-4 hover:bg-red-600"
                href="/formulario-credito"
                >Solicita crédito</a
              >
              <a
                v-if="product.simulator === 'inversiones'"
                class="inline-block bg-red-700 text-white px-8 py-1 mt-4 hover:bg-red-600"
                href="/simulador-inversiones"
                >Simulador</a
              >
              <a
                style="margin-left: 20px;"
                v-if="product.simulator === 'inversiones'"
                class="inline-block bg-red-700 text-white px-8 py-1 mt-4 hover:bg-red-600"
                href="/formulario-inversiones"
                >Solicita Inversión</a
              >
            </div>
            <div class="col-span-2 md:col-span-1 order-1 md:order-2">
              <img
                :src="url + product.icon"
                class="float-center md:float-right m-auto"
                alt="credito crediya"
                width="300"
              />
            </div>
          </div>

          <!-- BENEFITS -->
          <div
            class="grid grid-cols-2 mb-12"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <div class="col-span-2 md:col-span-1">
              <img :src="url + product.imgbeneficio" alt="beneficios crediya" />
            </div>
            <div class="col-span-2 md:col-span-1 p-10 bg-white">
              <h5 class="text-gray-800 text-3xl">Beneficios</h5>
              <ul class="list-disc">
                <li
                  class="text-gray-700 mb-2 ml-6"
                  v-for="a in product.characteristics.split(';')"
                  :key="a._id"
                >
                  {{ a }}
                </li>
              </ul>
            </div>
          </div>

          <!-- REQUERIMENTS -->
          <div
            class="grid grid-cols-2 pb-12"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <div class="col-span-2 md:col-span-1 p-10 bg-white">
              <h5 class="text-gray-800 text-3xl">Requisitos</h5>
              <ul class="list-disc">
                <li
                  class="text-gray-700 mb-2 ml-6"
                  v-for="a in product.requirements.split(';')"
                  :key="a._id"
                >
                  {{ a }}
                </li>
              </ul>
            </div>
            <div class="col-span-2 md:col-span-1">
              <img
                :src="url + product.imgrequisitos"
                alt="beneficios crediya"
              />
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import api from "@/api/product";
import url from "@/api/url";

export default {
  name: "Product",
  data() {
    return {
      url,
      product: {},
      isLoading: true
    };
  },
  watch: {
    $route() {
      this.getProduct();
    }
  },
  created() {
    this.getProduct();
  },
  methods: {
    getProduct() {
      const id = this.$route.params.id;
      api.getProduct(id).then(product => {
        console.log("producto q llega", product);
        this.product = product[0];
        this.isLoading = false;
      });
    }
  }
};
</script>

<style scoped>
/* Banner */
#banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 300px;
  width: 100%;
  position: relative;
}
.texto-banner {
  position: absolute;
  left: 10%;
  top: 60%;
}
</style>
