<template>
  <main style="background-color: #f5f5f5;">
    <!-- BANNER -->
    <div
      id="banner"
      :style="{
        'background-image':
          'url(' + require('@/assets/img/simulator/banner.jpg') + ')'
      }"
    ></div>
    <section class="container p-4 md:p-10 mx-auto">
      <h2 class="gris uppercase text-2xl text-center font-semibold">
        Simulador de Créditos
      </h2>
      <p class="text-center gris">Ingrese los datos</p>
      <form class="container p-4 md:p-10 mx-auto">
        <!-- FILA 1 -->
        <div class="grid grid-cols-2">
          <!-- TIPO DE CREDITO -->
          <div class="col-span-2 md:col-span-1">
            <div
              class="container-input bg-gray-700 rounded w-full md:w-3/4 float-right"
            >
              <label
                for="tipoCredito"
                class="text-white w-1/2 inline-block px-4 -ml-1"
                >Tipo de Crédito</label
              >
              <select
                name="tipo"
                id="tipoCredito"
                v-model="tipoCredito"
                class="w-1/2 p-2 my-1 mr-1 rounded focus:outline-none"
              >
                <option value="crediTour">Credi Tour</option>
                <option value="crediLaboral">Credi Laboral</option>
                <option value="crediManager">Credi Manager</option>
                <option value="consumoPrioritario">Consumo Prioritario</option>
                <option value="microcreditoOrdinario"
                  >Microcredito Ordinario</option
                >
              </select>
            </div>
          </div>

          <!-- CANTIDAD -->
          <div class="col-span-2 md:col-span-1">
            <div
              class="container-input bg-gray-700 rounded w-full md:w-3/4 ml-0 md:ml-20 mt-5 md:mt-0"
            >
              <label
                for="tipoCredito"
                class="text-white w-1/2 inline-block px-4 -ml-1"
                >Cantidad</label
              >
              <input
                type="number"
                id="cantidad"
                v-model="cantidad"
                class="w-1/2 p-2 my-1 mr-1 rounded focus:outline-none text-center"
                placeholder="Monto"
              />
            </div>
          </div>
        </div>

        <!-- FILA 2 -->
        <div class="grid grid-cols-2 mt-5">
          <!-- PLAZO -->
          <div class="col-span-2 md:col-span-1">
            <div
              class="container-input bg-gray-700 rounded w-full md:w-3/4 float-right"
            >
              <label
                for="tipoCredito"
                class="text-white w-1/2 inline-block px-4 -ml-1"
                >Plazo en Meses</label
              >
              <input
                type="number"
                id="plazo"
                v-model="plazo"
                class="w-1/2 p-2 my-1 mr-1 rounded focus:outline-none text-center"
                min="6"
                max="80"
                maxlength="3"
                placeholder="Meses"
              />
            </div>
          </div>
          <!-- AMORTIZACION -->
          <div class="col-span-2 md:col-span-1">
            <div
              class="container-input bg-gray-700 rounded w-full md:w-3/4 ml-0 md:ml-20 mt-5 md:mt-0"
            >
              <label
                for="amortizacion"
                class="text-white w-1/2 inline-block px-4 -ml-1"
                >Amortización</label
              >
              <select
                name="tipo"
                id="amortizacion"
                v-model="amortizacion"
                class="w-1/2 p-2 my-1 mr-1 rounded focus:outline-none"
              >
                <option value="cuotaFija" selected>Cuota Fija</option>
                <option value="cuotaVariable">Cuota Variable</option>
              </select>
            </div>
          </div>
        </div>

        <!-- ENVIAR -->
        <div class="grid grid-cols-2 mt-10">
          <div class="col-span-1">
            <button
              type="button"
              class="bg-gray-700 px-10 py-3 rounded text-white float-right focus:outline-none"
              @click="simulation"
              @keydown.tab.exact.prevent=""
            >
              Simular
            </button>
          </div>
          <div class="col-span-1">
            <button
              type="button"
              class="bg-gray-500 px-10 py-3 rounded text-white ml-6 md:ml-20 focus:outline-none"
              @click="limpiar"
              @keydown.tab.exact.prevent=""
            >
              Limpiar
            </button>
          </div>
        </div>
      </form>
    </section>

    <!-- RESULTS -->
    <section class="container w-full">
      <bounce-loader
        class="mx-auto my-10"
        :loading="isLoading"
        :color="'#f00'"
        :size="100"
      />
    </section>

    <section
      class="container py-5 px-4 md:px-32 mx-auto bg-white w-full"
      v-if="showTable"
    >
      <h2
        class=" uppercase text-2xl text-center text-white bg-red-700 rounded py-1"
      >
        Tabla de Amortización
      </h2>
      <table class="w-full my-5 rounded shadow">
        <thead class="">
          <tr class="border border-gray-200 bg-white pt-5">
            <th class="uppercase text-red-800 py-2">Nº Cuota</th>
            <th class="uppercase text-red-800">Fecha</th>
            <th class="uppercase text-red-800">Saldo</th>
            <th class="uppercase text-red-800" v-if="!showAmortizacion">
              Capital
            </th>
            <th class="uppercase text-red-800">Interés</th>
            <th class="uppercase text-red-800" v-if="showAmortizacion">
              Amortización
            </th>
            <th class="uppercase text-red-800">Seguro</th>
            <th class="uppercase text-red-800">Cuota</th>
          </tr>
        </thead>
        <tbody class="text-center bg-gray-100">
          <tr
            v-for="(a, i) in list"
            :key="a._id"
            class="border-b border-b border-gray-200 hover:bg-gray-100 hover:bg-orange-100"
          >
            <td class="py-2 mb-1">{{ i + 1 }}</td>
            <td class="mb-2">
              <!-- {{ fecha.getFullYear() }}-{{ fecha.getMonth() + i }}-{{
                fecha.getDate()
              }}-->
              {{ a.fecha_proxima }}
            </td>
            <td>
              {{ a.cantidad }}
            </td>
            <td v-if="!showAmortizacion">
              {{ a.capital }}
            </td>
            <td>
              {{ a.intereses }}
            </td>
            <td v-if="showAmortizacion">
              {{ a.amortizacion_calculada }}
            </td>
            <td>
              {{ a.segurodegravamen }}
            </td>
            <td>
              {{ a.cuota }}
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </main>
</template>

<script>
import api from "@/api/simulator";
//import { ok } from "assert";
export default {
  name: "SimulatorCredit",
  data() {
    return {
      list: [],
      tipoCredito: "",
      cantidad: "",
      plazo: "",
      amortizacion: "",
      fecha: new Date(),
      showTable: false,
      isLoading: false,
      showAmortizacion: false
    };
  },
  methods: {
    simulation() {
      if (
        this.tipoCredito == "" ||
        this.cantidad == "" ||
        this.plazo == "" ||
        this.amortizacion == ""
      ) {
        this.$swal("Llene todo los campos", "", "error");
      } else {
        this.isLoading = true;
        this.showTable = false;
        api
          .consultSimulation(
            this.tipoCredito,
            this.cantidad,
            this.plazo,
            this.amortizacion
          )
          .then(resp => {
            console.log(resp);
            if (resp.status == "ok") {
              this.isLoading = false;
              this.showTable = true;
              // this.$swal("Consultado", "", "success").then(() => {});
              this.list = resp.tablaSimulador;
              if (this.list[0].amortizacion_calculada) {
                this.showAmortizacion = true;
              } else {
                this.showAmortizacion = false;
              }
            } else {
              this.$swal("Error", "", "error");
            }
          });
        console.log(
          this.tipoCredito,
          this.cantidad,
          this.plazo,
          this.amortizacion
        );
      }
    },
    limpiar() {
      this.tipoCredito = "";
      this.cantidad = "";
      this.plazo = "";
      this.amortizacion = "";
      this.showTable = false;
      this.isLoading = false;
    }
  }
};
</script>

<style scoped>
/* Banner */
#banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 450px;
  width: 100%;
  position: relative;
}
</style>
