<template>
  <main style="background-color: #f5f5f5;">
    <!-- SECTION QUIENES SOMOS -->
    <section class="quienes-somos">
      <div class="grid grid-cols-2 mx-auto px-4 md:px-32 py-4">
        <!-- col 1 -->
        <div
          class="col-span-2 md:col-span-1"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <img
            src="@/assets/img/about/manager.png"
            alt="gerente crediya"
            width="500"
            class="mx-auto px-10 md:px-5 mt-24"
          />
        </div>

        <!-- col 2 -->
        <div
          class="col-span-2 md:col-span-1 my-auto"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <h1 class="gris text-5xl font-bold leading-10 mb-5 mt-20">
            ¿Quiénes <br />
            somos?
          </h1>
          <p class="gris text-justify leading-5 text-sm mb-2">
            La Cooperativa de Ahorro y Crédito Credi Ya Ltda. apalanca el inicio
            de sus actividades bajo acuerdo ministerial N.-006- dpt-c-2011 del
            24 de marzo del 2011. Nació gracias a la visión y firmeza de un
            grupo de 11 personas emprendedoras de espíritu afable y luchador,
            quienes convencidos de esta noble y fuerte causa aportaron un
            capital inicial de 5 mil dólares, permitiéndose otorgar créditos de
            hasta un monto máximo de 1000 dólares al sector del comercio
            minorista, verdadero motor de la economía a pequeña escala con
            beneficio directo a las familias de bajos recursos.
          </p>
          <p class="gris text-justify leading-5 text-sm mb-2">
            Con latente empeño inicia sus actividades en sus primeras oficinas
            ubicadas en la avenida Indoamérica ex redondel de las focas
            Parroquia Izamba, donde se colocó el mobiliario y los equipos de
            oficina que servirían para impulsar este anhelado y bien encausado
            proyecto.
          </p>
          <p class="gris text-justify leading-5 text-sm mb-2">
            Al ir encausando y consolidando este sueño Credi Ya, abrió sus
            puertas al público el 06 de agosto del 2011, en sus nuevas y cómodas
            oficinas en una de las arterias principales de la ciudad de Ambato
            calle bolívar 07-22 y Joaquín Ayllón donde hasta la actualidad
            brinda un servicio con calidez palpable, apoyando a diario
            principalmente a comerciantes, agricultores, ganaderos y
            microempresarios de nuestra amada provincia de Tungurahua.
          </p>
          <p class="gris text-justify leading-5 text-sm mb-2">
            Con ese mismo fervor nos hemos ampliado a nuestras provincias
            hermanas y fraternas, hoy estamos en Chimborazo, Pichincha, Guayas,
            Imbabura, Morona Santiago, Santo Domingo y Cotopaxi convirtiéndose
            en uno de nuestros grandes objetivos para crecer en este año 2024
            hasta nueve Agencias más por los grandes resultados obtenidos
            durante los 12 años de vida institucional.
          </p>
          <p class="gris text-justify leading-5 text-sm mb-2">
            Ahora confesando que nuestro progreso y bendición proviene de parte
            de Dios podemos darle toda la gloria al estar a punto de inaugurar
            el primer edificio inclusivo del país, con una infraestructura
            sólida, calidad acogedora y funcional donde continuaremos haciendo y
            cumpliendo con nuestra misión que es brindar soluciones que le
            permitan a nuestro socio mejorar su calidad de vida.
          </p>
          <hr class="hr-per mt-5" />
          <p class="gris font-bold mt-2">Msc. Cristina Santamaría</p>
          <span class="font-thin">Gerente General</span>
        </div>
      </div>
    </section>

    <!-- SECTION VISION MISION -->
    <section class="vision-mision">
      <div class="grid grid-cols-5 mx-auto px-4 md:px-32 bg-white">
        <div
          class="col-span-5 md:col-span-3 p-4 md:p-10"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h5 class="gris font-semibold text-2xl">MISIÓN</h5>
          <p class="gris text-justify">
            Honramos tu confianza con servicios financieros eficientes y
            procesos de mejora continua, alineados a principios y valores
            cristianos.
          </p>
          <h5 class="gris font-semibold text-2xl mt-5">VISIÓN</h5>
          <p class="gris text-justify">
            Al 2026 ser una institución financiera referente a nivel nacional,
            que consolide su gestión con: innovación, sostenibilidad y
            responsabilidad social mejorando la experiencia del socio y cliente.
          </p>
        </div>
        <!-- col 2 -->
        <div
          class="col-span-5 md:col-span-2 z-10"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <img
            src="@/assets/img/about/office.png"
            alt="gerente crediya"
            width="400"
            class="mx-auto"
          />
        </div>
      </div>
    </section>

    <!-- SECTION VALORES INSTITUCIONALES -->
    <section>
      <div
        class="img-banner-bg -mt-10 z-0"
        :style="{
          'background-image':
            'url(' + require('@/assets/img/about/banner.png') + ')'
        }"
      >
        <div
          class="texto-principal-right"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <h3 class="text-white text-4xl font-bold text-right">
            VALORES <br />INSTITUCIONALES
          </h3>
        </div>
      </div>
    </section>

    <!-- SECTION VALORES -->
    <section class="valores">
      <div class="grid grid-cols-5 mx-auto px-4 md:px-32">
        <!-- col 1 -->
        <div class="col-span-5 md:col-span-2 z-10">
          <img
            src="@/assets/img/about/building.png"
            alt="edificio crediya"
            width="450"
            class="mx-auto -mt-10"
          />
        </div>
        <!-- col 2 -->
        <div class="col-span-5 md:col-span-3 p-3 md:p-3">
          <div
            class="container"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="100"
          >
            <h5 class="gris font-semibold text-lg mt-3">LEALTAD</h5>
            <p class="gris text-justify text-sm mb-1">
              Con nuestra institución y quienes son parte de ella, sin
              traicionar los valores, principios y actitudes cooperativista.
            </p>
            <h5 class="gris font-semibold text-lg mt-3">DISCIPLINA</h5>
            <p class="gris text-justify text-sm mb-1">
              Cumpliendo a cabalidad normas, políticas y procedimientos que
              constituyen los pilares principales de la institución.
            </p>
          </div>
          <div
            class="container"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            <h5 class="gris font-semibold text-lg mt-3">EQUIDAD</h5>
            <p class="gris text-justify text-sm mb-1">
              A través de un ambiente de justicia y transparencia para el
              otorgamiento de productos y servicios a nuestros socios y
              clientes, proveedores, entes de control y talento humano.
            </p>
          </div>
          <div
            class="container"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="300"
          >
            <h5 class="gris font-semibold text-lg mt-3">HONESTIDAD</h5>
            <p class="gris text-justify text-sm mb-1">
              Con los asociados, recursos financieros, documentos, que sean de
              la Cooperativa, éstos serán utilizados con absoluta rectitud e
              integridad organizacional. Trabajamos con transparencia y ética
              cuidando siempre el bienestar de nuestros socios.
            </p>
          </div>
          <div
            class="container"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="400"
          >
            <h5 class="gris font-semibold text-lg mt-3">RESPONSABILIDAD</h5>
            <p class="gris text-justify text-sm mb-1">
              Para asumir nuestras acciones, estando siempre preparados en
              informar sobre las actividades ejecutadas, de manera que el socio
              y cliente incremente su confianza en la capacidad del personal y
              de la Cooperativa como Institución sólida y transparente.
            </p>
          </div>
          <div
            class="container"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="500"
          >
            <h5 class="gris font-semibold text-lg mt-3">RESPETO</h5>
            <p class="gris text-justify text-sm mb-1">
              Hacia nuestros socios/clientes y la comunidad ecuatoriana,
              basándonos en nuestros principios de ética y cultura.
            </p>
          </div>
          <div
            class="container"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="500"
          >
            <h5 class="gris font-semibold text-lg mt-3">INTEGRIDAD</h5>
            <p class="gris text-justify text-sm mb-1">
              Actuar con integridad tiene que ver con entender, aceptar, y
              escoger vivir conforme con principios éticos y morales, incluyendo
              la honestidad, la rectitud, y la decencia
            </p>
          </div>
          <div
            class="container"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="600"
          ></div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "About"
};
</script>
<style scoped>
.hr-per {
  border-color: #cd1e29;
}
.hr-per::after {
  content: "";
  width: 150px;
  border: 2px solid #cd1e29;
  height: 2px;
  margin-left: 20px;
  margin-top: -4px;
  display: block;
}

.img-banner-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 250px;
  width: 100%;
  position: relative;
}
.texto-principal-right {
  position: absolute;
  right: 10%;
  top: 40%;
}
</style>
