<template>
  <main
    id="bkg"
    :style="{
      'background-image':
        'url(' + require('@/assets/img/formularios/Elimina-datos.png') + ')'
    }"
  >
    <section class="quienes-somos">
      <div class="grid grid-cols-2 mx-auto px-4 md:px-32 py-4">
        <!-- col 1 -->
        <div
          class="col-span-2 md:col-span-1"
          data-aos="fade-right"
          data-aos-duration="1000"
          style="margin-top: 50px;"
        >
          <!-- <img
            src="@/assets/img/formularios/Text-Solicitud-de-Creditos.png"
            alt="textI"
            width="600"
            class="mx-auto px-10 md:px-1 mt-24"
          />-->
          <!-- <img
            src="@/assets/img/formularios/Text-Descripcion-Solicitud.png"
            alt="textII"
            width="700"
            class="mx-auto px-10 md:px-5 mt-24"
          /> -->
          <!-- <img
            src="@/assets/img/formularios/Text-Gracias-por-Escribirnos.png"
            alt="textIII"
            width="700"
            class="mx-auto px-10 md:px-5 mt-24"
          /> -->
        </div>

        <!-- col 2 -->
        <div
          class="col-span-2 md:col-span-1 "
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <!-- <div
        class="container mx-auto w-full md:w-1/2  bg-red rounded "
        data-aos="fade-up"
        data-aos-duration="1000"
      > -->
          <form
            class="p-4 md:p-6"
            style="background: #ffffff; margin-top: 90px; margin-left: 60px; border-radius: 20px;"
          >
            <div
              class="grid grid-cols-1 mb-5"
              style="border-top-color: #CC0000;"
            >
              <label
                for="formularios"
                class="text-black text-2xl font-semibold"
                style="text-align: center; "
                >Formulario de Solicitud de eliminacion de datos de CrediYa
                MÓVIL</label
              >
              <label
                class="text-black  font-semibold"
                style="text-align: center; "
                >Para enviar la solicitud de eliminación de Información
                ingresada en el sistema CrediYa MÓVIL debe llenar la siguiente
                formulario</label
              >

              <label
                class="text-black  font-semibold"
                style="text-align: center; "
                >"Su solicitud será revisada y notificada en un trascurso de 5
                días laborables"</label
              >
            </div>
            <!-- col 1 -->
            <div class=" grid grid-cols-2">
              <div class="col-span-2 md:col-span-6">
                <label for="names" class="text-black  block"
                  >Nombres y Apellidos</label
                >
                <input
                  v-model="names"
                  type="text"
                  id="names"
                  placeholder="Nombres y Apellidos"
                  class="border border-gray-600 p-1  -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
                />
              </div>
            </div>
            <div class=" grid grid-cols-2">
              <div class="col-span-2 md:col-span-6">
                <label for="phone" class="text-black  block"
                  >Cédula de identidad</label
                >
                <input
                  v-model="identification"
                  type="text"
                  id="identification"
                  placeholder="Cédula de identidad"
                  class="border border-gray-600 p-1  rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
                />
              </div>
            </div>

            <!-- col 2 -->
            <div class=" grid grid-cols-2">
              <div class="col-span-2 md:col-span-6 pr">
                <label for="city" class="text-black  block"
                  >Teléfono Celular</label
                >
                <input
                  v-model="phone"
                  type="text"
                  id="phone"
                  placeholder="0991234567"
                  class="border border-gray-600 p-1 -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
                />
              </div>
            </div>
            <div class=" grid grid-cols-2">
              <div class="col-span-2 md:col-span-6">
                <label for="email" class="text-black  block"
                  >Correo Electrónico
                </label>
                <input
                  v-model="email"
                  type="email"
                  id="email"
                  placeholder="email@email.com"
                  class="border border-gray-600 p-1  -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
                />
              </div>
            </div>

            <!--c ol 4 -->

            <!-- col 6 -->
            <div class="grid grid-cols-2">
              <div class="col-span-2 md:col-span-6">
                <label for="monto" class="text-black  block">
                  Motivo
                </label>
                <input
                  v-model="mount"
                  type="text"
                  id="mount"
                  placeholder="Detalle"
                  class="border border-gray-600 p-1  -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
                />
              </div>
            </div>

            <div class=" grid grid-cols-2">
              <div class="col-span-2 md:col-span-6">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />

                  <a
                    href="#"
                    class="form-check-label text-black py-1 px-2 rounded w-full mx-2 items-center"
                    @click.prevent="show"
                  >
                    Acepto los términos y condiciones
                  </a>
                </div>
              </div>
              <br />
              <div class="col-span-2 md:col-span-1">
                <!-- botontes -->
                <div class="mt-8 flex justify-around">
                  <button
                    type="button"
                    id="guardar"
                    class="bg-gray-500 hover:bg-red-600 text-black px-6 py-1 rounded"
                    @click="saveDeleteAcount"
                    @keydown.tab.exact.prevent=""
                    style="background: #ca0f12 !important; color: white !important;"
                  >
                    SOLICITAR
                  </button>

                  <router-link
                    to="/"
                    class="bg-gray-500 hover:bg-gray-600 text-black px-6 py-1 rounded"
                    style="background: #3C3C3B !important; color: white !important;"
                    >REGRESAR</router-link
                  >
                </div>
              </div>
            </div>

            <modal
              name="modal-terminos"
              :adaptive="true"
              transition="pop-out"
              :focus-trap="true"
              height="auto"
              :width="700"
              :scrollable="true"
              @opened="opened"
            >
              <div class="px-10 py-6">
                <h3 class="mb-2 text-xl" style="text-align: center;">
                  Acepta los Términos y Condiciones
                </h3>
                <form>
                  <div class="form-group mb-2">
                    <p
                      for="nombre"
                      class="block font-normal text-xs mb-1"
                      style="text-align: justify;"
                    >
                      Con la finalidad de que la Cooperativa de Ahorro y Crédito
                      Credi Ya Ltda. pueda dar trámite a mi solicitud de nuevos
                      productos, evaluar y estudiar mi comportamiento crediticio
                      y comercial, y mantener actualizada la información
                      financiera, crediticia y comercial ante los operadores de
                      bancos de datos, AUTORIZO a Cooperativa de Ahorro y
                      Crédito Credi Ya Ltda. para el Reporte y Consulta de
                      Información ante los Operadores de Bancos de Datos de
                      Información Financiera y/o Crediticia (Ley 1266 de 2008) y
                      (Ley 2157 de 2021).
                    </p>
                  </div>
                  <div class="form-group mb-2">
                    <p
                      for="lorem"
                      class="block font-normal text-xs mb-1"
                      style="text-align: justify;"
                    >
                      De manera libre, voluntaria y expresa autorizo a la
                      Cooperativa de Ahorro y Crédito Credi Ya Ltda. para que
                      lleve a cabo el tratamiento de mis datos personales. En
                      virtud de dicha autorización de tratamiento, la
                      Cooperativa de Ahorro y Crédito Credi Ya Ltda. podrá
                      recolectar, almacenar, usar, circular, transferir,
                      transmitir, suprimir y/o actualizar mis datos e
                      información personal, la cual, es suministrada por mí a
                      través de sus formularios web, para solicitar créditos,
                      realizar inversiones y poder solicitar cada uno de los
                      productos y servicios que ofrezca la cooperativa. Mis
                      datos e información personal podrán ser sujetos de
                      tratamiento por la Cooperativa de Ahorro y Crédito Credi
                      Ya Ltda., sus Encargados o Personas Responsables a quienes
                      se les haya transmitido o transferido la información. En
                      ese sentido, el tratamiento por la presente autorización
                      permitido a la Cooperativa de Ahorro y Crédito Credi Ya
                      Ltda. y a quien tenga la calidad de Encargado o Persona
                      Responsable podrá tener por objeto las finalidades
                      legítimas de:
                    </p>
                  </div>
                  <div class="form-group mb-2">
                    <p
                      for="lorem"
                      class="block font-normal text-xs mb-1"
                      style="text-align: justify;"
                    >
                      a) Obtenga toda la información y referencias relativas a
                      mi persona, a mi comportamiento crediticio y comercial,
                      mis hábitos de pago, el manejo de la(s) cuenta(s)
                      corriente(s) y de ahorros, de tarjetas de crédito, de
                      otros productos financieros y/o de servicios contratados
                      con el sector financiero y/o con el sector real y, en
                      general, el cumplimiento dado a mis obligaciones;<br />
                      b) Que pueda realizar consultas y procesar mi información
                      ante los operadores de bancos de datos, operadores de
                      información y riesgo (Equifax) o cualquier otra que en un
                      futuro se establezca;<br />
                      c) Entregue o comparta mi información con personas
                      jurídicas que administran bases de datos, para efectos de
                      prevención y control de fraudes y selección de riesgos;<br />
                      d) Cumplir las obligaciones contractuales y
                      reglamentarias, así como para la prestación de los
                      servicios contratados;<br />
                      e) Atender y dar solución a las solicitudes, peticiones,
                      quejas o reclamos formulados a la Cooperativa;<br />
                      f) Crear y actualizar los perfiles transaccionales;<br />
                      g) Realizar gestiones comerciales;<br />
                      h) Ofrecer productos o servicios, propios o de
                      terceros;<br />
                      i) Realizar labores para que se avalen, afiancen o
                      garanticen las operaciones de crédito;<br />
                      j) Informar sobre las campañas, estrategias promocionales
                      y de mercadeo;<br />
                      k) Recibir información, ofertas comerciales y
                      publicitarias de productos de la Cooperativa y de aliados
                      de éste;<br />
                      l) Recibir cualquier tipo de información y/o comunicación
                      que la Cooperativa estime necesario; y,<br />
                      m) Se adelanten todos los procesos de relacionamiento con
                      el cliente (soportados o no en tecnología), para un
                      abordaje integral con todos mis productos y soluciones
                      contratadas con la Cooperativa de Ahorro y Crédito Credi
                      Ya Ltda., entre otros permitidos por la Ley.
                    </p>
                  </div>
                  <div class="form-group mb-2">
                    <p
                      for="lorem"
                      class="block font-normal text-xs mb-1"
                      style="text-align: justify;"
                    >
                      El presente texto tendrá validez desde su aceptación de
                      los términos y condiciones marcando el check de aceptación
                      electrónica del formulario de solicitud, durante la
                      vigencia de la relación del Cliente con la Cooperativa de
                      Ahorro y Crédito Credi Ya Ltda. y/o de quien en el futuro
                      represente sus derechos, u ostente los derechos y/u
                      obligaciones a cargo de Cooperativa de Ahorro y Crédito
                      Credi Ya Ltda., y en general por el término establecido en
                      la Ley.
                    </p>
                  </div>
                </form>
              </div>
            </modal>
          </form>
        </div>
      </div>
      <!-- </div> -->
    </section>
  </main>
</template>
<script>
import api from "@/api/formularios";
import validar_cedula from "@/api/validar_cedula";
import validar_celular from "@/api/validar_celular";
import validar_email from "@/api/validar_email";

export default {
  name: "CreditRequest",
  data() {
    return {
      names: "",
      identification: "",
      email: "",
      phone: "",
      mount: "",
      province: "",
      city: "",
      parish: "",
      agencyNear: "",
      type: "",
      economicActivity: "",
      plazocredito: ""
    };
  },

  methods: {
    show() {
      this.$modal.show("modal-terminos");
    },
    opened() {
      console.log("se abrio");
      // this.$refs.nombre.focus();
    },
    hide() {
      this.$modal.hide("modal-new-user");
    },
    saveDeleteAcount() {
      if (
        this.names == "" ||
        this.identification == "" ||
        this.email == "" ||
        this.phone == "" ||
        this.mount == ""
      ) {
        this.$swal("Llene todo los campos", "", "error");
      } else {
        if (validar_cedula(this.identification)) {
          if (validar_celular(this.phone)) {
            if (validar_email(this.email)) {
              api
                .saveDeleteAcount(
                  this.names,
                  this.identification,
                  this.email,
                  this.phone,
                  this.mount
                )
                .then(resp => {
                  if (resp.status == "ok") {
                    this.$swal(
                      "¡Gracias por escribirnos!",
                      "Hemos enviado un correo electrónico para su notificación",
                      "success"
                    ).then(() => {
                      this.names = "";
                      this.identification = "";
                      this.email = "";
                      this.phone = "";
                      this.mount = "";
                    });
                  } else {
                    this.$swal("Error al guardar", "", "error");
                  }
                });
            } else {
              this.$swal("Email no válido", "", "error");
            }
          } else {
            this.$swal("Celular no válido", "", "error");
          }
        } else {
          this.$swal("Cédula no válida", "", "error");
        }
      }
    }
  }
};
</script>

<style scoped>
#banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 350px;
  width: 100%;
  position: relative;
}
.texto-banner {
  position: absolute;
  left: 10%;
  top: 40%;
}
.texto-principal {
  position: absolute;
  left: 10%;
  top: 40%;
}
.completo {
  width: 100%;
}
.titulo {
  padding-top: 30px;
  font-size: 20pt;
}
.margin {
  margin: 2px;
}
.logo {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.imgcss {
  width: auto;
  height: 90px;
}
.pr {
  padding-right: -4px;
}
.bg-blue {
  background-color: #1c4992;
}
</style>
