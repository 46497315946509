import axios from "axios";
import url from "./url";
function enviarCedula(cedula) {
  console.log("====", cedula);
  return axios.post(`${url}/apis/api-data-socio`, { cedula }).then(resp => {
    console.log("===resp==", resp);
    if (resp.data.status == "ok") {
      console.log("cedula enviada", resp.data);
      return resp.data;
    } else {
      console.log("Cédula incorrecta", "", "error");
      return resp.data;
    }
  });
}

function enviarToken(token, cedula) {
  console.log("token====", token);
  console.log("ci====", cedula);
  return axios
    .post(`${url}/apis/api-recive-token`, { cedula, token })
    .then(resp => {
      console.log("===resp token==", resp.data);
      return resp.data;
    });
}
export default {
  enviarCedula,
  enviarToken
};
