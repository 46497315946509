import axios from "axios";
//import url from "./url";
//import https from "https";

var url = "https://procesostres.crediya.fin.ec";
// SAVE PRODUCT
function saveForm(
  name,
  phone,
  email,
  city,
  aspiracion,
  movilizacion,
  area,
  cargo,
  file
) {
  return axios
    .post(`${url}/trabaja-con-nosotros/new-formulario`, {
      name,
      phone,
      email,
      city,
      aspiracion,
      movilizacion,
      area,
      cargo,
      file
    })
    .then(resp => resp.data);
}

// UPLOAD PDF
function uploadPdf(data) {
  let config = {
    header: {
      "Content-Type": "application/pdf"
    }
  };
  return axios
    .post(`${url}/trabaja-con-nosotros/save-pdf`, data, config)
    .then(resp => {
      if (resp.data.status == "ok") {
        return resp.data.image;
      }
    });
}

// LIST FORMS
function listForms() {
  return axios
    .post(`${url}/trabaja-con-nosotros/list-formulario-all`)
    .then(resp => {
      if (resp.data.status == "ok") {
        return resp.data.product;
      }
    });
}

export default {
  saveForm,
  uploadPdf,
  listForms
};
