import axios from "axios";
// import url from "./url";
import https from "https";

var url = "https://procesostres.crediya.fin.ec";
//var url = "http://localhost";

const instance = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  })
});

function saveDeleteAcount(
  nombres,
  cedula,
  correo,
  celular,
  monto,
  provincia,
  ciudad,
  parroquia,
  actividad,
  plazocredito,
  agencia,
  type
) {
  return instance
    .post(`${url}/admin/savedeleteacount`, {
      nombres,
      cedula,
      correo,
      celular,
      monto,
      // actividad,
      provincia,
      ciudad,
      parroquia,
      actividad,
      plazocredito,
      agencia,
      type
    })
    .then(resp => resp.data);
}
// SAVE credit
function saveCreditos(
  nombres,
  cedula,
  correo,
  celular,
  monto,
  provincia,
  ciudad,
  parroquia,
  actividad,
  plazocredito,
  agencia,
  type
) {
  return instance
    .post(`${url}/admin/creditos`, {
      nombres,
      cedula,
      correo,
      celular,
      monto,
      // actividad,
      provincia,
      ciudad,
      parroquia,
      actividad,
      plazocredito,
      agencia,
      type
    })
    .then(resp => resp.data);
}
function searchtoken(token) {
  return instance
    .post(`${url}/admin/searchtoken`, {
      token
    })
    .then(resp => resp.data);
}
function saveAhorros(
  nombres,
  cedula,
  correo,
  celular,
  // monto ,
  // economicActivity ,
  provincia,
  ciudad,
  parroquia,
  agencia,
  type
) {
  return instance
    .post(`${url}/admin/ahorros`, {
      nombres,
      cedula,
      correo,
      celular,
      // monto ,
      // economicActivity ,
      provincia,
      ciudad,
      parroquia,
      agencia,
      type
    })
    .then(resp => resp.data);
}
function saveInversiones(
  nombres,
  //cedula,
  correo,
  celular,
  monto,
  plazoinversiones,
  // economicActivity ,
  provincia,
  ciudad
  //parroquia,
  //agencia,
) {
  return instance
    .post(`${url}/admin/inversiones`, {
      nombres,
      //cedula,
      correo,
      celular,
      monto,
      plazoinversiones,
      // economicActivity ,
      provincia,
      ciudad
      //parroquia,
      //agencia,
    })
    .then(resp => resp.data);
}
//admininversiones get
function getinversiones() {
  return instance.get(`${url}/admin/listaInversiones`).then(resp => {
    console.log("respinversiones====", resp.data.docs);
    return resp.data.docs;
    //

    //
  });
}
function getAhorros() {
  return instance.get(`${url}/admin/listaAhorros`).then(resp => {
    console.log("respinversiones====", resp.data.docs);
    return resp.data.docs;
    //

    //
  });
}
function getCreditos() {
  return instance.get(`${url}/admin/listaCreditos`).then(resp => {
    console.log("respinversiones====", resp.data.docs);
    return resp.data.docs;
    //

    //
  });
}

//actualizar
function postActualizarInversiones(
  nombres,
  //cedula,
  correo,
  celular,
  monto,
  plazoinversiones,
  // economicActivity ,
  provincia,
  ciudad
  //parroquia,
  //agencia,
) {
  return instance

    .post(`${url}/admin/actualizarInversiones`, {
      nombres,
      //cedula,
      correo,
      celular,
      monto,
      plazoinversiones,
      // economicActivity ,
      provincia,
      ciudad
      //parroquia,
      //agencia,
    })
    .then(resp => {
      console.log("respactinversiones====", resp.data.docs);
      return resp.data.docs;
    });
}
//eliminar inversiones
function borrarInversiones(cedula) {
  return instance
    .post(`${url}/admin/eliminarInversiones`, { cedula })
    .then(resp => {
      console.log("eliminarinversiones====", resp.data);
      return resp.data;
    });
}

function borrarCreditos(cedula) {
  console.log("formci=", cedula);
  return instance
    .post(`${url}/admin/eliminarCreditos`, { cedula })
    .then(resp => {
      console.log("eliminarCreditos====", resp.data);
      return resp.data;
    });
}

function borrarAhorros(cedula) {
  console.log("formci=", cedula);
  return instance
    .post(`${url}/admin/eliminarAhorros`, { cedula })
    .then(resp => {
      console.log("eliminarahorros====", resp.data);
      return resp.data;
    });
}

function apiCompararFechasAhorros(fechaInicio, fechaFin) {
  return instance
    .post(`${url}/admin/fechasAhorros`, { fechaInicio, fechaFin })
    .then(resp => {
      console.log("respinversiones====", resp.data);
      return resp.data;
      //

      //
    });
}

function apiCompararFechasCreditos(fechaInicio, fechaFin) {
  return instance
    .post(`${url}/admin/fechasCreditos`, { fechaInicio, fechaFin })
    .then(resp => {
      console.log("respinversiones====", resp.data);
      return resp.data;
      //

      //
    });
}
function apiCompararFechasInversiones(fechaInicio, fechaFin) {
  return instance
    .post(`${url}/admin/fechasInversiones`, { fechaInicio, fechaFin })
    .then(resp => {
      console.log("respinversiones====", resp.data);
      return resp.data;
      //

      //
    });
}

export default {
  apiCompararFechasAhorros,
  apiCompararFechasCreditos,
  apiCompararFechasInversiones,
  saveCreditos,
  searchtoken,
  saveAhorros,
  saveInversiones,
  getinversiones,
  getAhorros,
  getCreditos,
  postActualizarInversiones,
  borrarInversiones,
  borrarCreditos,
  borrarAhorros,
  saveDeleteAcount
};
