<template>
  <main style="background-color: #f5f5f5;">
    <!-- BANNER -->
    <div
      id="banner"
      :style="{
        'background-image':
          'url(' + require('@/assets/img/saras/saras.png') + ')'
      }"
    >
      <div class="texto-banner">
        <h1 class="text-white text-3xl font-bold">Política - SARAS</h1>
        <h2 class="text-white text-3 font-bold">
          La estructura estratégica y de gobierno de la Cooperativa se encuentra
          en total compromiso por velar el cumplimiento
        </h2>
        <h2 class="text-white text-3 font-bold">
          de las políticas y procedimiento para la Administración de Riesgo
          Ambiental y Social - SARAS. Por lo que suministrarán
        </h2>
        <h2 class="text-white text-3 font-bold">
          los recursos necesarios como talento humano, económicos y elementales
          para que las operaciones de microcrédito sean
        </h2>
        <h2 class="text-white text-3 font-bold">
          colocadas y desembolsadas considerando las estrategias y controles
          para mantener la seguridad medio ambiental y social
        </h2>
        <h2 class="text-white text-3 font-bold">
          de las operaciones de crédito aprobadas en la Cooperativa.
        </h2>
      </div>
    </div>

    <!-- SECTION TRANSPARENCIA -->
    <section class="py-10">
      <!-- TABLE -->
      <div
        class="container mx-auto w-full md:w-3/4 p-4 md:p-10 bg-white rounded "
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div class="bg-red-700 uppercase text-white rounded text-xl p-1 mb-6">
          <div class="flex justify-between mx-6 md:mx-20">
            <span>Documentos</span>
            <span>Ver</span>
          </div>
        </div>
        <!-- LIST 1 -->
        <div class="container flex justify-between px-2 md:px-12 py-1">
          <div class="left flex">
            <img
              src="@/assets/img/transparency/icono.png"
              alt="icono crediya"
              class="mr-2"
              style="height:60px; margin: -15px"
            />
            <h5 class="uppercase text-lg gris">
              Lista de actividades Excluidas
            </h5>
          </div>
          <div class="right">
            <a
              href="https://procesostres.crediya.fin.ec/images/docs/ACTIVIDADESEXCLUIDAS.pdf"
              target="_blank"
              class="container bg-gray-100 px-10 rounded hover:bg-gray-300 hover:text-gray-500 cursor-pointer py-1"
            >
              <font-awesome-icon :icon="['fas', 'eye']" class="gris " />
            </a>
          </div>
        </div>
        <div class="bg-green-300  text-back rounded text-l p-1 mb-6">
          <div class=" justify mx ">
            <p>
              <span
                >En caso de existir alguna QUEJA O RECLAMO relacionado con temas
                de Riesgo ambiental y Social</span
              >
            </p>
          </div>
          <div class=" justify mx-10">
            <p><span>1.- Descargar el siguiente documento</span></p>
            <p><span>2.- Llenar debidamente el documento y firmar</span></p>
            <p>
              <span
                >3.- Entregar el documento en cualquier oficina de la
                cooperativa de ahorro y crédito Credi Ya</span
              >
            </p>
          </div>
        </div>
        <!-- LIST 1.1 -->
        <div class="container flex justify-between px-2 md:px-12 py-1">
          <div class="left flex">
            <img
              src="@/assets/img/transparency/icono.png"
              alt="icono crediya"
              class="mr-2"
              style="height:60px; margin: -15px"
            />
            <h5 class="uppercase text-lg gris">
              quejas y reclamos Formato I
            </h5>
          </div>
          <div class="right">
            <a
              href="https://procesostres.crediya.fin.ec/images/docs/HOJADEQUEJASYRECLAMOSDOCUMENTOI.pdf"
              target="_blank"
              class="container bg-gray-100 px-10 rounded hover:bg-gray-300 hover:text-gray-500 cursor-pointer py-1"
            >
              <font-awesome-icon :icon="['fas', 'eye']" class="gris " />
            </a>
          </div>
        </div>
        <!-- LIST 1 -->
        <div class="container flex justify-between px-2 md:px-12 py-1">
          <div class="left flex">
            <img
              src="@/assets/img/transparency/icono.png"
              alt="icono crediya"
              class="mr-2"
              style="height:60px; margin: -15px"
            />
            <h5 class="uppercase text-lg gris">
              quejas y reclamos formato II
            </h5>
          </div>
          <div class="right">
            <a
              href="https://procesostres.crediya.fin.ec/images/docs/HOJADEQUEJASYRECLAMOSDOCUMENTOII.pdf"
              target="_blank"
              class="container bg-gray-100 px-10 rounded hover:bg-gray-300 hover:text-gray-500 cursor-pointer py-1"
            >
              <font-awesome-icon :icon="['fas', 'eye']" class="gris " />
            </a>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
export default {
  name: "WorkWithUs"
};
</script>

<style scoped>
#banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 350px;
  width: 100%;
  position: relative;
}
.texto-banner {
  position: absolute;
  left: 10%;
  top: 40%;
}
.texto-principal {
  position: absolute;
  left: 10%;
  top: 40%;
}
</style>
