<template>
  <footer style="background-color: #282828;">
    <!-- BANNER LOGO -->
    <section id="logo_banner" class="bg-white p-1">
      <div class="container">
        <div class="flex justify-center m-2">
          <img
            class="img-fluid h-20"
            src="@/assets/img/logo-black.png"
            alt="logo crediya"
          />
        </div>
      </div>
    </section>

    <div class="container p-10">
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 md:col-span-4">
          <h5 class="text-white mb-4">Acerca de Nosotros</h5>
          <p class="gris text-sm mb-5">
            La Cooperativa de Ahorro y Crédito Credi Ya Ltda.; es una entidad
            que realiza intermediaciones financieras y de responsabilidad social
            ayudando a mejorar las condiciones de vida de nuestros socios desde
            el año 2011.
          </p>
          <hr class="gris" />
          <font-awesome-icon :icon="['fas', 'envelope']" class="gris" />
          <span class="gris" style="margin-left:20px;"
            >info@crediya.fin.ec</span
          >
          <hr class="gris" />
          <font-awesome-icon :icon="['fas', 'phone']" class="gris" />
          <span class="gris" style="margin-left:20px;">(03) 2427433</span>
          <hr class="gris" />
          <font-awesome-icon :icon="['fas', 'map-marked']" class="gris" />
          <span class="gris" style="margin-left:20px;"
            >Matriz: Simón Bolivar 7-22 y Joaquín Ayllon</span
          >
        </div>
        <div class="col-span-12 md:col-span-2">
          <h5 class="text-white mb-4">Links Rápidos</h5>
          <ul class="p-0 mr-2">
            <li class="mb-3">
              <router-link to="/producto/crediconsumo-prioritario">
                <font-awesome-icon
                  :icon="['fas', 'angle-double-right']"
                  class="rojo"
                />
                <span class="gris ml-3 text-sm">Créditos</span>
              </router-link>
            </li>
            <li class="mb-3">
              <router-link to="/simulador-credito">
                <font-awesome-icon
                  :icon="['fas', 'angle-double-right']"
                  class="rojo"
                />
                <span class="gris ml-3 text-sm">Simuladores</span>
              </router-link>
            </li>
            <li class="mb-3">
              <router-link to="/producto/inversion-gold">
                <font-awesome-icon
                  :icon="['fas', 'angle-double-right']"
                  class="rojo"
                />
                <span class="gris ml-3 text-sm">Inversiones</span>
              </router-link>
            </li>
            <li class="mb-3">
              <router-link to="/producto/ahorro-ardillita">
                <font-awesome-icon
                  :icon="['fas', 'angle-double-right']"
                  class="rojo"
                />
                <span class="gris ml-3 text-sm">Ahorros</span>
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-span-12 md:col-span-2 mt-8">
          <ul class="p-0 mr-2">
            <li class="mb-3">
              <router-link to="/trabaja-con-nosotros">
                <font-awesome-icon
                  :icon="['fas', 'angle-double-right']"
                  class="rojo"
                />
                <span class="gris ml-3 text-sm">Trabaja con nosotros</span>
              </router-link>
            </li>
            <li class="mb-3">
              <router-link to="/nosotros">
                <font-awesome-icon
                  :icon="['fas', 'angle-double-right']"
                  class="rojo"
                />
                <span class="gris ml-3 text-sm">Nosotros</span>
              </router-link>
            </li>
            <li class="mb-3">
              <router-link to="/transparencia">
                <font-awesome-icon
                  :icon="['fas', 'angle-double-right']"
                  class="rojo"
                />
                <span class="gris ml-3 text-sm">Indicadores financieros</span>
              </router-link>
            </li>
            <li class="mb-3">
              <router-link to="/contactos">
                <font-awesome-icon
                  :icon="['fas', 'angle-double-right']"
                  class="rojo"
                />
                <span class="gris ml-3 text-sm">Agencias</span>
              </router-link>
            </li>
            <li class="mb-3">
              <router-link to="/educafinan">
                <font-awesome-icon
                  :icon="['fas', 'angle-double-right']"
                  class="rojo"
                />
                <span class="gris ml-3 text-sm">Educación Financiera</span>
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-span-12 md:col-span-4 mt-5">
          <h5 class="text-white mb-4">Deseas más Información</h5>
          <p class="gris mb-4 text-sm">
            Si necesitas más información puedes visitar nuestras agencias de
            Ambato Matriz, Quito y Riobamba.
          </p>
          <hr class="gris" />
          <h5 class="text-white mb-4">Síguenos</h5>
          <div class="flex justify-between redes">
            <a
              href="https://www.facebook.com/CooperativaCrediYa"
              target="_blank"
              ><font-awesome-icon
                :icon="['fab', 'facebook']"
                class="gris hover:text-gray-400"
            /></a>

            <a
              href="https://www.youtube.com/channel/UCJGS6ReeuATf0jqVb_wj0Zg"
              target="_blank"
              ><font-awesome-icon
                :icon="['fab', 'youtube']"
                class="gris hover:text-gray-400"
            /></a>

            <a
              href="https://twitter.com/CoopCrediYa?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
              target="_blank"
              ><font-awesome-icon
                :icon="['fab', 'twitter']"
                class="gris hover:text-gray-400"
            /></a>

            <a href="https://whatsapp.com/" target="_blank"
              ><font-awesome-icon
                :icon="['fab', 'whatsapp']"
                class="gris hover:text-gray-400"
            /></a>

            <a
              href="https://www.instagram.com/coopcrediya/?hl=es"
              target="_blank"
              ><font-awesome-icon
                :icon="['fab', 'instagram']"
                class="gris hover:text-gray-400"
            /></a>
          </div>
          <div class="flex justify-between mt-5">
            <a
              href="https://www.cosede.gob.ec/pago-seguro-de-depositos/"
              target="_blank"
            >
              <img
                src="@/assets/img/footer/cosede.png"
                alt="cosede"
                class="h-8 opacity-100 hover:opacity-75"
              />
            </a>
            <a href="https://www.bce.fin.ec/" target="_blank">
              <img
                src="@/assets/img/footer/banco-central.png"
                alt="banco central"
                class="h-8 opacity-100 hover:opacity-75"
              />
            </a>
            <a href="https://www.seps.gob.ec/" target="_blank">
              <img
                src="@/assets/img/footer/superintendencia.png"
                alt="banco central"
                class="h-8 opacity-100 hover:opacity-75"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style scoped>
/* COLORES DE LETRAS */
.rojo {
  color: #cd1f27;
}
.bg-rojo {
  background-color: #cd1e2a;
}
.gris {
  color: rgb(182, 181, 181);
}
hr {
  margin: 10px 0;
  background-color: #575757;
  height: 1px;
  /* background-color: #ccc; */
  border: none;
}
</style>
