import axios from "axios";
import url from "./url";

// SAVE PRODUCT
function saveProduct(
  name,
  description,
  simulator,
  characteristics,
  requirements,
  banner,
  icon,
  imgbeneficio,
  imgrequisitos
) {
  return axios
    .post(`${url}/product/new-product`, {
      name,
      description,
      simulator,
      characteristics,
      requirements,
      banner,
      icon,
      imgbeneficio,
      imgrequisitos
    })
    .then(resp => resp.data);
}

// UPDATE PRODUCT
function updateProduct(
  id,
  slug,
  name,
  description,
  simulator,
  characteristics,
  requirements,
  banner,
  icon,
  imgbeneficio,
  imgrequisitos
) {
  return axios
    .post(`${url}/product/save-edit-product`, {
      id,
      slug,
      name,
      description,
      simulator,
      characteristics,
      requirements,
      banner,
      icon,
      imgbeneficio,
      imgrequisitos
    })
    .then(resp => resp.data);
}

// UPLOAD IMAGE
function uploadImage(data) {
  let config = {
    header: {
      "Content-Type": "image/png"
    }
  };
  return axios.post(`${url}/product/save-image`, data, config).then(resp => {
    if (resp.data.status == "ok") {
      return resp.data.image;
    }
  });
}

// LIST PRODUCTS
function listProducts() {
  return axios.post(`${url}/product/list-product-all`).then(resp => {
    // console.log(resp.data);
    if (resp.data.status == "ok") {
      return resp.data.product;
    }
  });
}

// GET PRODUCT
function getProduct(slug) {
  return axios.post(`${url}/product/list-product-slug`, { slug }).then(resp => {
    console.log("get Product resp: ", resp.data);
    if (resp.data.status == "ok") {
      return resp.data.product;
    }
  });
}
// DELETE PRODUCT
function deleteProduct(id) {
  return axios.post(`${url}/product/delete-product`, { id }).then(resp => {
    console.log("Product delete resp: ", resp.data);
    if (resp.data.status == "ok") {
      return resp.data.product;
    }
  });
}

export default {
  saveProduct,
  uploadImage,
  listProducts,
  getProduct,
  updateProduct,
  deleteProduct
};
