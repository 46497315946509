<template>
  <main style="background-color: #f5f5f5;">
    <!-- BANNER -->
    <div
      id="banner"
      :style="{
        'background-image':
          'url(' + require('@/assets/img/simulator/banner-inv.jpg') + ')'
      }"
    ></div>
    <section class="container py-5 px-4 md:px-32 mx-auto">
      <h2
        class=" uppercase text-2xl text-center font-semibold text-white bg-red-700 rounded py-1"
      >
        Simulador de Inversiones
      </h2>
      <div
        class="container p-4 md:p-10 mx-auto bg-white mt-6 rounded-lg shadow-md"
      >
        <!-- FILA 1 -->
        <div class="grid grid-cols-2 ">
          <!-- CANTIDAD -->
          <div class="col-span-2 md:col-span-1">
            <div class="rounded w-1/2 float-right mr-0 md:mr-10">
              <label
                for="tipoCredito"
                class="block px-4 -ml-1 font-semibold gris text-center mb-8"
                >Monto a Invertir</label
              >
              <input
                type="number"
                id="cantidad"
                v-model="cantidad"
                class="w-full p-2 my-1 mr-1 rounded focus:outline-none text-center border-2 block border-gray-700"
                placeholder="Monto"
              />
            </div>
          </div>

          <!-- PLAZO -->
          <div class="col-span-2 md:col-span-1">
            <div class="rounded w-1/2 ml-0 md:ml-20">
              <label
                for="tipoCredito"
                class="block px-4 -ml-1 font-semibold gris text-center"
                >Tiempo a Invertir</label
              >
              <p class="gris text-sm text-center mb-3">
                Coloca el tiempo en meses
              </p>
              <select
                name="plazo"
                id="plazo"
                v-model="plazo"
                class="w-full p-2 my-1 mr-1 rounded focus:outline-none text-center border-2 block border-gray-700"
              >
                <option value="1">1 Mes</option>
                <option value="2">2 Meses</option>
                <option value="3">3 Meses</option>
                <option value="6">6 Meses</option>
                <option value="12">12 Meses</option>
                <option value="18">18 Meses</option>
                <option value="24">24 Meses</option>
              </select>
            </div>
          </div>
        </div>

        <!-- ENVIAR -->
        <div class="grid grid-cols-2 mt-10">
          <div class="col-span-1">
            <button
              type="button"
              class="bg-gray-700 px-10 py-3 mr-0 md:mr-10 rounded text-white float-right focus:outline-none"
              @click="simulation"
              @keydown.tab.exact.prevent=""
            >
              Simular
            </button>
          </div>
          <div class="col-span-1">
            <button
              type="button"
              class="bg-gray-500 px-10 py-3 rounded text-white ml-6 md:ml-20 focus:outline-none"
              @click="limpiar"
              @keydown.tab.exact.prevent=""
            >
              Limpiar
            </button>
          </div>
        </div>

        <!-- RESULT -->
        <div
          class="result bg-gray-700 rounded-lg p-4 w-full md:w-8/12 mx-auto mt-10"
        >
          <div class="flex">
            <!-- COLUMNA 1 -->
            <div class="w-1/2">
              <h4 class="text-white text-2xl font-semibold mb-4">Cálculo</h4>
              <div class="container flex justify-between mb-2">
                <label for="capital" class="text-white">Capital</label>
                <input
                  type="number"
                  name="capital"
                  readonly
                  v-model="cantidad"
                  class="w-2/4 text-right rounded text-sm"
                />
              </div>
              <div class="container flex justify-between mb-2">
                <label for="capital" class="text-white">Interés a Ganar</label>
                <input
                  type="number"
                  name="capital"
                  readonly
                  v-model="interesGanar"
                  class="w-2/4 text-right rounded text-sm"
                />
              </div>
              <div class="container flex justify-between mb-2">
                <div class="flex flex-col">
                  <label for="capital" class="text-white text-sm">IRF</label>
                  <span class="text-white" style="font-size:.5rem"
                    >(Impuesto Rendimiento Financiero)</span
                  >
                </div>
                <input
                  type="number"
                  name="capital"
                  readonly
                  v-model="irf"
                  class="w-2/4 text-right rounded text-sm"
                />
              </div>
            </div>

            <!-- COLUMNA 2 -->
            <div class="w-1/2">
              <h4 class="text-white text-xl font-normal mt-1 mb-3 text-center">
                Total Neto a recibir
              </h4>
              <h3 class="text-white text-3xl font-bold mx-2 px-2 text-center ">
                $ {{ total }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import api from "@/api/simulator";
export default {
  name: "SimulatorCredit",
  data() {
    return {
      cantidad: "",
      plazo: "",
      interesGanar: "",
      irf: "",
      total: 0
    };
  },
  methods: {
    simulation() {
      if (this.cantidad == "" || this.plazo == "") {
        this.$swal("Llene todo los campos", "", "error");
      } else {
        api.consultInversion(this.cantidad, this.plazo).then(resp => {
          console.log(resp);
          if (resp.status == "ok") {
            this.$swal("Éxito", "", "success").then(() => {});
            this.interesGanar = resp.tablaInvesiones.data.interesGanado;
            this.irf = resp.tablaInvesiones.data.retencion;
            this.total =
              parseFloat(this.cantidad) +
              parseFloat(resp.tablaInvesiones.data.valorRecibir);
          } else {
            this.$swal("Error", "", "error");
          }
        });
      }
    },
    limpiar() {
      this.cantidad = "";
      this.plazo = "";
    }
  }
};
</script>

<style scoped>
/* Banner */
#banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 450px;
  width: 100%;
  position: relative;
}
</style>
