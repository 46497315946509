import Vue from "vue";
import App from "./App.vue";
import "@/assets/css/tailwind.css";
import router from "@/router";
import store from "@/store";

// LOADER SPINER
import { VueSpinners } from "@saeris/vue-spinners";

// MODAL
import VModal from "vue-js-modal";
// wizad
import VueFormWizard from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import VueAnalytics from "vue-analytics";

Vue.use(VueFormWizard);

// FONT ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUserSecret,
  faAngleDoubleRight,
  faEnvelope,
  faPhone,
  faMapMarked,
  faPen,
  faTrash,
  faEye
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faYoutube,
  faInstagram,
  faTwitter,
  faWhatsapp
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faEye,
  faPen,
  faTrash,
  faPhone,
  faMapMarked,
  faEnvelope,
  faAngleDoubleRight,
  faUserSecret,
  faFacebook,
  faYoutube,
  faInstagram,
  faTwitter,
  faWhatsapp
);

// swiper
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
// Carousel
import VueCarousel from "vue-carousel";

// sweetalert
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

// scroll animation
import AOS from "aos";
import "aos/dist/aos.css";

Vue.use(require("vue-moment"));
Vue.use(VueSweetalert2);
Vue.use(VModal);
Vue.use(VueCarousel);
Vue.use(VueAwesomeSwiper);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(VueSpinners);
Vue.config.productionTip = false;

// messenger chat plugin
import VueFbCustomerChat from "vue-fb-customer-chat";
Vue.use(VueFbCustomerChat, {
  page_id: 1757418337889277, //  change 'null' to your Facebook Page ID,
  theme_color: "#fa3c4c", // theme color in HEX
  locale: "en_US" // default 'en_US'
});

// WHATSAPP
import VueSocialChat from "vue-social-chat";
Vue.use(VueSocialChat);
Vue.use(VueAnalytics, {
  id: "UA-225382509-1",
  router
});

new Vue({
  router,
  store,
  created() {
    AOS.init();
  },
  render: h => h(App)
}).$mount("#app");
