<template>
  <main style="background-color: #f5f5f5;">
    <!-- BANNER -->
    <div
      id="banner"
      :style="{
        'background-image':
          'url(' + require('@/assets/img/responsability/banner.jpg') + ')'
      }"
    >
      <div class="texto-banner">
        <h2 class="text-white text-3xl font-bold">Terminos y condiciones</h2>
        <p class="text-gray-100">Home / Términos y Condiciones</p>
      </div>
    </div>

    <!-- CONTENT -->
    <section>
      <div class="container mt-12  px-4 md:px-20">
        <!-- DESCRIPTION -->
        <div class="grid grid-cols-2 mb-12">
          <div
            class="col-span-2 md:col-span-2 mb-2"
            data-aos="fade-down"
            data-aos-duration="1000"
          >
            <h4 class="text-3xl text-gray-700 mb-4 uppercase text-center">
              Términos y Condiciones Uso de Canales Electrónicos
            </h4>
            <p class="gris text-justify mb-10">
              Este acuerdo contiene los términos y condiciones que rigen el
              acceso y uso de los canales electrónicos de la Cooperativa de
              Ahorro y Crédito CREDI YA Ltda. (Credi Ya Virtual, Credi Ya Móvil,
              Aplicaciones Móviles y demás plataformas tecnológicas o canales
              que ponga a disposición), que permiten a sus Socios y Clientes
              acceder a productos y servicios financieros a través de Internet y
              de sus dispositivos móviles
            </p>
            <p class="gris font-semibold text-2xl">
              1. Definiciones.
            </p>
            <p class="gris text-justify mb-5">
              Cooperativa de Ahorro y Crédito CREDI YA Ltda. Cuenta con canales
              electrónicos para brindar servicios financieros que incluyen
              transacciones y en general operaciones relacionadas con los
              productos y servicios disponibles, bajo los siguientes términos y
              condiciones.
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              a) Canales Electrónicos: se refieren a Credi Ya Virtual, Credi Ya
              Móvil, aplicaciones móviles y demás plataformas tecnológicas o
              canales de la Cooperativa de Ahorro y Crédito CREDI YA Ltda., que
              permitan acceder a los servicios que ofrece.
            </p>
            <p class="gris text-justify mb-5" style="margin-left: 5%;">
              b) La Cooperativa: Se refiere a Cooperativa de Ahorro y Crédito
              CREDI YA Ltda
            </p>

            <p class="gris font-semibold text-2xl">
              2. Usuario.
            </p>
            <p class="gris text-justify mb-5">
              Para efectos de estos términos y condiciones, el término “USUARIO”
              alude a la persona que registra sus datos personales, correo
              electrónico y número de teléfono móvil, para poder hacer uso de
              los canales electrónicos de Cooperativa de Ahorro y Crédito CREDI
              YA Ltda.
            </p>

            <p class="gris font-semibold text-2xl">
              3. Afiliación
            </p>
            <p class="gris text-justify mb-5">
              Para acceder y utilizar los Canales Electrónicos, el USUARIO
              entiende y acepta que La Cooperativa requiere durante el proceso
              de afiliación, la creación de un usuario y una contraseña personal
              e intransferible (con un nivel de seguridad que garantice su
              inviolabilidad o impida su inferimiento por terceros) y/o la
              creación de un PIN de seguridad; los cuales constituyen los medios
              de autenticación que permitirán a La Cooperativa verificar su
              identidad para darle acceso a los Canales Electrónicos y él
              USUARIO pueda solicitar o aceptar servicios y operaciones
              financieras ofrecidos por La Cooperativa, así como servirán para
              validar la autenticidad de las transacciones que realice por medio
              de ellos.
            </p>
            <p class="gris text-justify mb-5">
              El acceso y uso de los Canales Electrónicos, se entenderá como una
              confirmación de su aceptación, sin limitación alguna, de los
              términos y condiciones aquí establecidos, así como de su futuro
              cumplimiento. El USUARIO acepta además que todo aviso,
              notificación y, en general, toda comunicación relacionada con los
              Canales Electrónicos de La Cooperativa serán remitidas de manera
              electrónica en el mismo canal utilizado o a través de un mensaje
              de datos enviado al número de teléfono celular o dirección de
              correo electrónico que mantenga registrados. No obstante, La
              Cooperativa se reserva el derecho de implementar otros medios para
              efectuar las notificaciones señaladas.
            </p>

            <p class="gris font-semibold text-2xl">
              4. Servicios disponibles
            </p>
            <p class="gris text-justify mb-5">
              Dependiendo de la naturaleza de su relación con La Cooperativa,
              los servicios puestos a la disposición del USUARIO a través de los
              Canales Electrónicos incluyen, los siguientes:
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              a) Transferencias directas e interbancarias
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              b) Pago de servicios y facturas
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              c) Recargas de tiempo aire celular
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              d) Recargas de otros servicios, por ejemplo, televisión pagada,
              tarjetas prepago
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              e) Consultas de saldos y movimientos
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              f) Otros Servicios que La Cooperativa ponga a disposición de
              acuerdo a la normativa vigente.
            </p>
            <p class="gris text-justify mb-5">
              El acceso y registro en Credi Ya Virtual y Credi Ya Móvil no
              tendrá costo.
            </p>
            <p class="gris text-justify mb-5">
              El USUARIO podrá solicitar y aceptar a través de los Canales
              Electrónicos los servicios ofrecidos por La Cooperativa, los que
              de ser aprobados serán otorgados en función de las políticas
              internas de La Cooperativa. Las características y condiciones de
              los servicios serán particularizadas al momento de solicitarlos y
              quedarán formalizadas con su aceptación por medio de Instrucciones
              Electrónicas, sin necesidad de la suscripción de documentación
              física adicional.
            </p>
            <p class="gris text-justify mb-5">
              A su discreción, La Cooperativa. podrá establecer límites de
              montos y transacciones que pueden realizarse en sus Canales
              Electrónicos. Asi mismo, cada cierto tiempo La Cooperativa podrá
              modificar, añadir, eliminar o suspender servicios ofrecidos a
              través de sus Canales Electrónicos. Estos Términos y Condiciones
              regirían para todos los futuros Servicios ofrecidos por Canales
              Electrónicos. Al utilizar cualquier Servicio nuevo que estuviera
              disponible, estará aceptando este documento y obligándose a
              cumplirlo en todas sus partes, así como cualquier actualización
              relacionada con el nuevo Servicio.
            </p>
            <p class="gris font-semibold text-2xl">
              5. Acceso y uso de los Canales Electrónicos
            </p>
            <p class="gris text-justify mb-5">
              Para poder hacer uso de los servicios permitidos por los Canales
              Electrónico, el USUARIO se compromete a cumplir con los procesos
              de registro requeridos por La Cooperativa y que serán informados
              por los Canales Electrónicos. Una vez superado este procedimiento
              inicial, el USUARIO deberá establecer una contraseña propia, cuya
              custodia y uso será de su exclusiva responsabilidad, y que deberá
              utilizar para poder acceder a la aplicación.
            </p>
            <p class="gris text-justify mb-5">
              Para el acceso y uso de los Canales Electrónicos, además del
              ingreso de su usuario y contraseña y/o PIN registrados, La
              Cooperativa podrá requerirle el ingreso de códigos de seguridad
              y/o datos biometricos de un solo uso los cuales servirán como
              medida de autenticación adicional para prevenir fraudes y
              transacciones no autorizadas.
            </p>
            <p class="gris text-justify mb-5">
              El número de teléfono celular y dirección de correo electrónicos
              que proporcione a La Cooperativa , deben ser válidos y de uso
              exclusivamente personal, consintiendo recibir en ellos, en los
              casos que se requiera, los códigos de seguridad de un solo uso.
              Por tanto, exime a La Cooperativa por cualquier tipo de error en
              el número celular o dirección de correo electrónico que le
              proporcione.
            </p>
            <p class="gris text-justify mb-5">
              El USUARIO entiende y acepta que, si al acceder a los Canales
              Electrónicos, los intentos fallidos de ingresar la contraseña o el
              código de seguridad y/o dato biométrico de un solo uso sobrepasan
              el número máximo permitido por La Cooperativa, su usuario podrá
              ser bloqueado por seguridad, sin que esta situación pueda
              entenderse en ningún caso como una falla en los Canales
              Electrónicos o el incumplimiento o indisponibilidad de los
              Servicios ofertados por La Cooperativa.
            </p>
            <p class="gris text-justify mb-5">
              Las claves, códigos y en general identificaciones electrónicas
              provistas por La Cooperativa para el registro, ingreso y operación
              de los Canales Electróncos, incluidas las claves que fueren
              requeridas para la aprobación de operaciones, son personales,
              intransferibles y confidenciales, por lo que el USUARIO debe
              mantenerlas en estricta reserva y utilizarlas solo para los fines
              para los que fueron proporcionadas. Por ende, todas las
              actuaciones realizadas con estas identificaciones electrónicas se
              entienden efectuadas por el USUARIO, y como tales, que le
              pertenecen y son de su exclusiva responsabilidad, con todos los
              efectos jurídicos que ello conlleva.
            </p>
            <p class="gris text-justify mb-5">
              Asi mismo, como medida de seguridad para permitirle el acceso al
              USUARIO a los Canales Electrónicos, La Cooperativa podría
              requerirle el ingreso de cierta información adicional o que
              conteste algunas preguntas de desafío que refuercen la
              confirmación de su identidad.
            </p>
            <p class="gris text-justify mb-5">
              El USUARIO entiende y acepta expresamente que el código de
              seguridad de una sola vez enviado por La Cooperativa o generado en
              los Canales Electrónicos de La Cooperativa para la confirmación de
              un Servicio y/o transacción en cualquier Canal Electrónico,
              constituyen una firma electrónica habilitada para manifestar su
              aceptación expresa de los Servicios solicitados a través de
              Instrucciones Electrónicas, sin necesidad de verificación
              adicional o firma de documentos de forma física. Así mismo,
              entiende y acepta que la confirmación de Servicios brindados por
              La Cooperativa mediante el registro de códigos de seguridad de una
              sola vez, será la constancia suficiente, sin la necesidad de
              verificación adicional por parte de La Cooperativa o la
              ratificación de su parte por medios físicos u otros canales
              electrónicos, de su manifestación de voluntad.
            </p>
            <p class="gris text-justify mb-5">
              La Cooperativa no es ni será responsable por cualquier error u
              omisión cometido o por la información que usted ingrese para la
              realización de operaciones o transferencias a través de los
              Canales Electrónicos. Por tanto, el USUARIO asume plena
              responsabilidad por el uso de los Canales Electrónicos, debiendo
              verificar los datos ingresados, previo a confirmar cualquier tipo
              de transacción o solicitud de Servicio.
            </p>
            <p class="gris font-semibold text-2xl">
              6. Consentimiento
            </p>
            <p class="gris text-justify mb-5">
              El USUARIO acepta y acuerda las tarifas que cobra La Cooperativa
              por los servicios financieros a través de los Canales Electrónicos
              que presta. Cuyos valores consta en los Tarifarios aprobados por
              el Organismo de Control, conforme a lo dispuesto en la Normativa
              Vigente, mismo que debidamente firmado forma parte integrante del
              presente instrumento, así como el hecho que La Cooperativa puede
              crear nuevas tarifas o modificar las actuales tarifas en la firma
              determinada en las normas legales y reglamentarias
              correspondientes, haciendo conocer al USUARIO la creación y/o
              modificación inclusive a través de informativos en sus agencias o
              a través de canales electrónicos. El USUARIO acepta y acuerda
              pagar a La Cooperativa tales tarifas, facultando expresamente a La
              Cooperativa a debitarlos de cualquiera de las cuentas, valores,
              inversiones y derechos de cualquier tipo.
            </p>
            <p class="gris text-justify mb-5">
              La Cooperativa podrá cobrar, o abstenerse de cobrar, cargos o
              comisiones por cualquiera de los servicios brindados en o a través
              de los Canales Electrónicos. La decisión de cobrar o no tales
              valores será unilateral y exclusiva de La Cooperativa, y éste
              podrá modificarla en cualquier tiempo sin necesidad de aprobación
              o adhesión alguna, ni previa ni ulterior, por parte del USUARIO.
            </p>
            <p class="gris text-justify mb-5">
              Sin perjuicio de lo anterior, La Cooperativa retendrá o aplicará
              los tributos que de conformidad con la ley graven actualmente, o a
              futuro, a las transacciones que el USUARIO realice a través de los
              Canales Electrónicos, particularmente lo relativo a pagos a
              terceros y/o transferencias interbancarias.
            </p>
            <p class="gris text-justify mb-5">
              El USUARIO acepta y acuerda que La Cooperativa tendrá absoluta
              potestad de otorgar acceso a los Canales Electrónicos dependiendo
              de, entre otros factores, la veracidad de la información
              proporcionada, la disponibilidad y operación continua de la
              plataforma tecnológica, sistema computarizado y la línea de
              procesamiento de comunicaciones de La Cooperativa así como del
              proveedor del servicio de Internet que usted tiene contratado, de
              su navegador, de su equipo o dispositivo de acceso y de las líneas
              telefónicas y/o eléctricas. Por tanto, el USUARIO entiende y
              acepta que La Cooperativa puede suspender, de manera temporal o
              permanente, el acceso o la provisión de Servicios o cualquiera de
              las funciones de los Canales Electrónicos, de manera unilateral y
              sin previo aviso, por cualquiera de las siguientes causas:
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              a) Si detectare falsedad en la información suministrada para el
              registro en los Canales Electrónicos o en cualquier otro documento
              presentado que demuestre falta de transparencia de su parte;
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              b) Si, a su solo criterio, advirtiere indicios de operaciones
              fraudulentas, inusuales, irregulares o sospechosas;
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              c) Por motivos técnicos o funcionales propios de dichos canales o
              de terceros;
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              d) Por motivos de caso fortuito o fuerza mayor;
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              e) Por disposición de la normativa ecuatoriana vigente;
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              f) Por orden de la autoridad administrativa o judicial competente;
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              g) Si se evidenciare un uso inadecuado o ilegal;
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              h) Si se detectare el incumplimiento de alguna disposición legal,
              particularmente aquellas referidas al sistema de prevención del
              lavado de activos o del financiamiento de delitos
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              i) Por cualquier otra definida por La Cooperativa;
            </p>
            <p class="gris text-justify mb-5">
              Circunstancias en las cuales La Cooperativa no asumirá
              responsabilidad alguna. En caso de que el uso de los Canales
              Electrónicos sea suspendido o presente dificultades técnicas,
              usted podrá continuar efectuando sus transacciones en las agencias
              de La Cooperativa.
            </p>
            <p class="gris text-justify mb-5">
              El USUARIO acepta y acuerda su obligación que matiene sobre el
              cuidado prolijo que mantiene con las claves y/o dispositivos
              electrónicos, además es de fundamental importancia para evitar
              actos dolosos en nuestra contra, que puedan causar perjuicios
              especialmente por transacciones, transferencias, retiros,
              operaciones, conocimiento de información y en general otros actos
              que pudieran efectuar personas inescrupulosas que lleguen a tener
              dichas claves y/o dispositivos electrónicos por descuido del
              USUARIO. El cuidado que debe tener el USUARIO busca proteger a la
              Cooperativa de actos dolosos. El USUARIO acepta y acuerda mantener
              en secreto la clave o seguridades asignadas, así como los cambios
              de claves que se efectuarén.
            </p>
            <p class="gris text-justify mb-5">
              En caso que terceras personas no autorizadas hayan obtenido las
              claves, y/o dispositivos electrónicos, en caso de pérdida,
              destrucción o sustracción u otros mecanismos, el USUARIO acepta y
              acuerda que comunicará a La Cooperativa inmediatamente del suceso.
              A través de un documento escrito en el que deberá contar la
              recepción respectiva, comunicación electrónica debidamente
              recibida por La Cooperativa, por lo que no será responsable de
              ninguna transacción u operación realizada anterior a la
              comunicación. El USUARIO podrá realizar el bloqueo inmediato de
              las cuentas a través de los canales autorizados de La Cooperativa
              publicados en la página web.
            </p>
            <p class="gris font-semibold text-2xl">
              7. Autorización para tratamiento de datos personales
            </p>
            <p class="gris text-justify mb-5">
              El USUARIO autoriza libre y voluntariamente a La Cooperativa para
              que realice el tratamiento de datos personales, crediticios y/o
              datos no públicos, incluyendo aquellos que pueda entregar
              posteriormente o que La Cooperativa llegue a tener o haya tenido
              acceso. Por lo mencionado, el USUARIO acepta y autoriza el
              tratamiento inclusive de los datos sensibles, tales como
              biométricos, de salud, crediticios, transaccionales, datos sujetos
              a sigilo o reserva, entre otros.
            </p>
            <p class="gris text-justify mb-5">
              El tratamiento de Datos tendrá las siguientes finalidades:
              elaborar y/o segmentar perfiles; realizar análisis crediticios
              incluso mediante técnicas automáticas; desarrollar acciones
              comerciales de productos y servicios financieros y/o comerciales;
              y/o, comercializar y promocionar (incluyendo el envío de
              publicidad por cualquier medio) diferentes productos y servicios
              financieros y/o comerciales.
            </p>
            <p class="gris text-justify mb-5">
              Para efectos de esta autorización se entiende por “tratamiento” a
              cualquier operación o conjunto de operaciones realizadas sobre los
              Datos del USUARIO ya sea por procedimientos técnicos de carácter
              automatizado, parcialmente automatizado o no automatizado, tales
              como, pero sin limitarse a: la recogida, recopilación, obtención,
              registro, organización, estructuración, evaluación, análisis,
              conservación, custodia, adaptación, modificación, eliminación,
              indexación, extracción, consulta, elaboración, utilización,
              procesamiento, consolidación, anonimización, seudonimización,
              posesión, aprovechamiento, rentabilización, distribución, cesión,
              comunicación, disposición, divulgación, o transferencia, o
              cualquier otra forma de habilitación de acceso, cotejo,
              interconexión, limitación, supresión, destrucción y, en general,
              cualquier uso de Datos
            </p>
            <p class="gris text-justify mb-5">
              De igual forma el USUARIO autoriza expresa y específicamente a La
              Cooperativa tratar y mantener los Datos incluso una vez finalizada
              cualquier relación contractual y/o de prestación de servicios, en
              los plazos establecidos por ley, con el objeto de que La
              Cooperativa y/o terceros autorizados de manera individual o
              conjunta puedan prestar servicios, elaborar y segmentar perfiles,
              realizar análisis crediticios incluso mediante técnicas
              automáticas que utilicen los datos actuales, históricos y
              estadísticos, para la valoración de riesgos, confección y análisis
              de nuevas propuestas comerciales de servicios de cualquier tipo,
              inclusive como elemento de análisis en etapas pre-contractuales,
              contractuales y post- contractuales y, con el fin de remitirme
              comunicaciones comerciales y/o contactarme por cualquier medio
              para la oferta, promoción y contratación de productos y/o
              servicios
            </p>
            <p class="gris text-justify mb-5">
              Para cumplir con las finalidades previamente descritas, los datos
              del USUARIO podrán consultarse, compartirse, transferirse y/o
              comunicarse a sus proveedores, subsidiarias, sociedades afiliadas,
              relacionadas y/o auxiliares, empresas encargadas del tratamiento
              de datos y/o sociedades con las que La Cooperativa mantenga
              relación a nivel nacional o internacional. Para el efecto, La
              Cooperativa adoptará las medidas técnicas y organizativas
              aplicables para cumplir con la confidencialidad y seguridad
              correspondientes.
            </p>
            <p class="gris text-justify mb-5">
              Cualquier comunicación relacionada con los derechos del USUARIO,
              como es el caso de acceso, eliminación, rectificación,
              actualización, revisión, portabilidad y oposición, de los cuales
              el USUARIO tiene conocimiento, podra dirigirla al funcionario
              designado por La Cooperativa a la dirección de correo electrónico
              <a href="seguridaddelainformacion@crediya.fin.ec"
                ><u>seguridaddelainformacion@crediya.fin.ec</u></a
              >
              o mediante comunicación escrita entregada en las Agencias de La
              Cooperativa ubicadas a nivel nacional, o presentar cualquier
              solicitud ante la Autoridad de Protección de Datos del Ecuador
            </p>
            <p class="gris text-justify mb-5">
              Con esta aceptación el USUARIO garantizará la veracidad de los
              datos entregados a La Cooperativa y se compromete a comunicar
              inmediatamente cualquier variación sobre los mismos. La
              Cooperativa no será responsable de los resultados derivados del
              procesamiento de información que se realicen como consecuencia de
              datos erróneos, desactualizados o inexactos. La Cooperativa se
              reserva el derecho a no prestar los servicios correspondientes en
              caso de que se proporcione datos erróneos, desactualizados o
              inexactos. El USUARIO reconoce el derecho de La Cooperativa a
              limitar o terminar la relación comercial en el caso de que esta
              autorización sea revocada.
            </p>
            <p class="gris font-semibold text-2xl">
              8. Responsabilidad
            </p>
            <p class="gris text-justify mb-5">
              El USUARIO excluye a La Cooperativa de toda responsabilidad civil
              o administrativa que pueda imputársele por las siguientes
              situaciones o casos:
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              a) Las interrupciones o demoras en la prestación de Servicios a
              través de Canales Electrónicos, ocasionadas por eventos de fuerza
              mayor o caso fortuito;
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              b) La prestación de Servicios a través de Canales Electrónicos en
              formas no previstas por La Cooperativa, estos Términos y
              Condiciones o algún otro acuerdo aplicable a la relación entre el
              USUARIO y La Cooperativa.
            </p>
            <p class="gris text-justify mb-5">
              La Cooperativa no asume ni podrá adjudicársele responsabilidad
              alguna por daños o virus que puedan alterar o dañar su equipo
              electrónico, teléfono celular, dispositivo móvil o alguna otra
              propiedad, en relación con el acceso a los Canales Electrónicos o
              por su uso o por descargas que a través de estos hiciera.
            </p>
            <p class="gris text-justify mb-5">
              En el caso de menores de edad, el(los) padre(s), tutor legal o
              quien posea la patria potestad o custodia del menor, al prestar su
              consentimiento a este documento, acepta y asume total
              responsabilidad por las acciones del(los) menor(es) al utilizar
              los Canales Electrónicos. La Cooperativa no se responsabiliza por
              el acceso de menores de edad a los Canales Electrónicos, sin
              autorización del(los) padre(s), tutor legal o quien posea la
              patria potestad o custodia del menor o el acceso de terceras
              personas que lo hayan hecho con el usuario, contraseña, PIN y/o
              códigos de seguridad que le corresponden como titular.
            </p>
            <p class="gris text-justify mb-5">
              Es obligación del USUARIO mantener actualizados los datos
              personales que se encuentren registrados en La Cooperativa e
              informar por los canales habilitados, en efecto, de cualquier
              cambio en su dirección de domicilio, trabajo, correo electrónico,
              números telefónicos e información personal, etc; por lo cual, La
              Cooperativa no será responsable de los daños que pudieran
              derivarse del incumplimineto de esta obligación.
            </p>
            <p class="gris font-semibold text-2xl">
              9. Compromiso
            </p>
            <p class="gris text-justify mb-5">
              El USUARIO acepta y acuerda mantener absoluta reserva y estricta
              confidencialidad de cualquier tipo de información de La
              Cooperativa que llegue a conocer con ocasión y como consecuencia
              del presente documento, sin que pueda divulgar su contenido ni
              total ni parcialmente.
            </p>
            <p class="gris text-justify mb-5">
              El USUARIO deberá utilizar los canales electrónicos y los
              productos y servicios que se ofrecen a través de ella, de acuerdo
              con estos Términos y Condiciones y con las regulaciones que se
              establezcan en otros convenios o términos y condiciones aplicables
              a servicios o productos asociados a los CANALES ELECTRÓNICOS.
            </p>
            <p class="gris text-justify mb-5">
              El USUARIO se compromete a respetar en todo momento el orden
              público y la legislación vigente, por lo que no podrá emplear los
              productos y servicios que se ofrecen a través de los CANALES
              ELECTRÓNICOS para fines ilícitos o fraudulentos tipificados como
              tales en la legislación ecuatoriana, o de cualquier forma que
              pudiere causar daños o perjuicios a la Cooperativa o a terceros,
              incluido el daño a la reputación e imagen de la Cooperativa. El
              USUARIO se compromete también a acceder y operar los canales
              electrónicos y los productos y servicios ofrecidos a través de
              ella, siguiendo los parámetros y recomendaciones de seguridad
              proporcionadas por la Cooperativa en su canales electrónicos o en
              algún otro documento físico o virtual dispuesto para ello, entre
              los que se incluye tener actualizados los sistemas de detección de
              software maliciosos o los parches de seguridad de los navegadores,
              entre otros, por lo que reconoce que la Cooperativa no responderá
              por los daños y perjuicios que pudiere llegar a sufrir por
              incumplir dichos procedimientos o por hechos de terceros sobre los
              que la Cooperativa no tuviere control o responsabilidad, tales
              como riesgos inherentes a los sistemas de navegación, dispositivos
              o en general medios empleados para acceder a la aplicación y hacer
              uso de ella, incluidos los daños y perjuicios ocasionados por
              cualquier tipo de software maliciosos, virus o cualquier otro tipo
              de elemento dañino.
            </p>
            <p class="gris text-justify mb-5">
              En caso de que el USUARIO detecte en los Canales electrónicos
              algún funcionamiento irregular, vulneración de sus sistemas de
              seguridad o algún contenido que pudiere ocasionar daños o
              perjuicios a La Cooperativa, al USUARIO o a terceros, o que fuere
              contrario al orden público y ordenamiento jurídico vigente, se
              compromete a notificar de forma inmediata a La Cooperativa, a
              través de los canales de atención al cliente dispuestos por este
              último. La recepción de la notificación por parte de La
              Cooperativa, no supone su aceptación de los hechos denunciados;
              sin embargo, podrá suspender la operación de los Canales
              Electrónicos o retirar los contenidos denunciados como ilícitos o
              lesivos.
            </p>
            <p class="gris font-semibold text-2xl">
              10. Propiedad Intelectual
            </p>
            <p class="gris text-justify mb-5">
              La Cooperativa se reserva para sí todos los derechos de propiedad
              intelectual e industrial sobre los Canales Electrónicos y sus
              sistemas, herramientas, aplicaciones y canales electrónicos que se
              utilizaren para su acceso y operación, lo que incluye su interfaz
              para interactuar con los usuarios, bases de datos o programas de
              ordenador subyacentes, códigos fuentes, contenido y elementos
              tales como gráficos, imágenes, textos, videos, sonidos, entre
              otros. De igual manera, La Cooperativa se reserva la propiedad y
              uso de los signos distintivos que aparecieran en la aplicación o
              en sus enlaces, de los que fuera titular, licenciatario o
              beneficiario bajo cualquier título, tales como marcas, diseños,
              logotipos, nombres comerciales, lemas comerciales, apariencias
              distintivas y demás elementos sujetos a protección, que hayan sido
              registrados o se encuentren en proceso de registro ante la
              autoridad competente.
            </p>
            <p class="gris font-semibold text-2xl">
              11. Licitud de fondos
            </p>
            <p class="gris text-justify mb-5">
              El USUARIO declara que los fondos de las transacciones son
              lícitos, no provienen de/ni serán destinados a ninguna actividad
              ilegal o delictiva, ni consentirá que se efectúen depósitos o
              transferencias a su cuenta, provenientes de estas actividades.
              Expresamente autoriza a la Cooperativa de Ahorro y Crédito CREDI
              YA Ltda., a realizar las verificaciones y debida diligencia
              correspondiente e informar de manera inmediata y documentada a la
              autoridad competente en casos de investigación o cuando se
              detectaren transacciones inusuales e injustificadas, por lo que no
              ejercerá ningún reclamo o acción judicial. En el caso de que el
              USUARIO mantenga coincidencias con las listas de control
              proporcionadas por la UAFE, La Cooperativa realizará las acciones
              respectivas para su control conforme a lo establecido en los
              manuales y políticas de la Cooperativa.
            </p>
            <p class="gris font-semibold text-2xl">
              12. Limites de montos aplicables
            </p>
            <p class="gris text-justify mb-5">
              A través de los Canales Electrónicos se podrán realizar
              transacciones hasta por los montos autorizados por La Cooperativa.
              Dichos montos autorizados podrán variar periódicamente, por
              decisión unilateral de La Cooperativa, o cuando así lo dispongan
              las autoridades competentes.
            </p>
            <p class="gris font-semibold text-2xl">
              13. Consultas
            </p>
            <p class="gris text-justify mb-5">
              En caso de necesitar asistencia relacionada con los Canales
              Electrónicos o si necesita comunicarse con La Cooperativa, debe
              llamar a (03) 2 427 433 a nivel nacional o acudir a la agencia más
              cercana. Por este medio autoriza a La Cooperativa grabar, usar y/o
              copiar cualquier llamada o formulario o Instrucción Electrónica
              que nos envíe. El USUARIO entiende y acepta que, por seguridad en
              el manejo de información, La Cooperativa no solicita la entrega de
              información personal, usuario, contraseña, código de seguridad o
              PIN, a través de correo electrónico, por lo que es su
              responsabilidad mantener en secreto dicha información.
            </p>
            <p class="gris text-justify mb-5">
              Para información adicional relacionada con La Cooperativa o alguna
              de sus agencias, su centro de negocios y las localidades y horas
              de servicio de sus agencias, o de los productos y servicios que
              ofrece La Cooperativa, acceda a los Canales Electrónicos o
              llámenos a los números arriba detallados. También puede visitar
              <u>www.crediya.fin.ec</u> o alguna de nuestras agencias a nivel
              nacional.
            </p>
            <p class="gris font-semibold text-2xl">
              14. Vigencia
            </p>
            <p class="gris text-justify mb-5">
              Este documento tendrá vigencia indefinida. No obstante, el USUARIO
              entiende y acepta que La Cooperativa tendrá el derecho de
              modificar los Términos y Condiciones detallados en este acuerdo,
              en cualquier momento. En caso de efectuar cualquier modificación a
              los mismos, estos serán puestos a su disposición a través de los
              diferentes canales de La Cooperativa. Por tanto, él USUARIO se
              compromete a revisar frecuentemente las actualizaciones de estos
              Términos y Condiciones en los Canales Electrónicos o a través del
              sitio web <u>www.crediya.fin.ec/terminos-y-condiciones</u> y
              acepta que el solo acceso y uso posterior de los Canales
              Electrónicos, será considerado como su aceptación y conformidad
              con dichos cambios o modificaciones.
            </p>
            <p class="gris font-semibold text-2xl">
              15. Cobertura y Horarios
            </p>
            <p class="gris text-justify mb-5">
              El USUARIO podrá acceder a los Canales Electrónicos desde
              cualquier ubicación del territorio nacional o del exterior. Los
              Canales Electrónicos operarán de forma ininterrumpida. No
              obstante, las operaciones podrán procesarse en un día distinto al
              que fueron solicitadas, dependiendo del horario en que hayan sido
              ingresadas.
            </p>
            <p class="gris font-semibold text-2xl">
              16. Legislación Aplicable
            </p>
            <p class="gris text-justify mb-5">
              El uso y contenido de los Canales Electrónicos, así como los
              servicios y productos que lo integran, están regulados por la
              legislación ecuatoriana, por lo que cualquier conflicto
              relacionado con cualquiera de aquellos, deberá ser resuelto bajo
              esa legislación.
            </p>
            <p class="gris font-semibold text-2xl">
              17. ACEPTACIÓN
            </p>
            <p class="gris text-justify mb-5">
              Para el uso de los Canales Electrónicos, el USUARIO declara que
              conoce de forma clara y precisa la siguiente información que ha
              sido proporcionada por La Cooperativa:
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              a) Su derecho para recibir la información relacionada con los
              productos y servicios ofrecidos en los Canales Electrónicos, a
              través de otros canales de La Cooperativa, incluidos canales no
              electrónicos;
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              b) Su derecho para solicitar el bloqueo o baja de su registro en
              los Canales Electrónicos;
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              c) Su derecho para objetar, cuando sea procedente, el cobro de los
              cargos relacionados con el acceso y uso de los Canales
              Electrónicos o de los servicios ahí brindados;
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              d) Los procedimientos que debe seguir para ejercer los derechos u
              opciones previstos en este numeral; y,
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              e) Cualquier otro dato que debe ser conocido para la utilización
              de medios electrónicos, según la Ley de Comercio Electrónico,
              Firmas Electrónicas y Mensaje de Datos, y su Reglamento.
            </p>
            <p class="gris text-justify mb-5">
              El acceso, uso y operación de los Canales Electrónicos por parte
              del USUARIO constituye aceptación sin reserva alguna, por parte de
              este último. Consecuentemente, el USUARIO será responsable, en
              forma inmediata, por el cumplimiento íntegro y oportuno de los
              presentes Términos y Condiciones. Será, así mismo, responsabilidad
              del USUARIO leer íntegramente las actualizaciones de estos
              Términos y Condiciones, que se comunicarán de forma periódica a
              través de los Canales Electrónicos.
            </p>
            <p class="gris text-justify mb-5">
              Para el caso de juicio el USUARIO acepta la renuncia general de
              domicilio y queda sometido a los jueces o tribunales de la Ciudad
              de Ambato, y al procedimiento establecido por la normativa
              ecuatoriana.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- PROJECT 11 -->
    <section class="bg-red-600 pl-4 md:pl-20 pt-4 md:pt-16 pb-5">
      <div class="grid grid-cols-5 mx-auto px-4 md:px-32 bg-white">
        <!-- col 1 -->

        <!-- col 2 -->
        <div
          class="col-span-5 md:col-span-3 p-4 md:p-10"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <light-box />
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import LightBox from "../../components/howWeAre/LightBox.vue";

export default {
  name: "TerminosApp",
  components: {
    LightBox
  }
};
</script>

<style scoped>
/* Banner */
#banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 350px;
  width: 100%;
  position: relative;
}

.texto-banner {
  position: absolute;
  left: 10%;
  top: 40%;
}
.texto-principal {
  position: absolute;
  left: 10%;
  top: 40%;
}
.texto-principal-right {
  position: absolute;
  right: 10%;
  top: 40%;
}
</style>
