<template>
  <carousel
    :perPage="1"
    :autoplay="true"
    :autoplayTimeout="3000"
    :paginationEnabled="false"
    :navigationEnabled="false"
    :autoplay-hover-pause="false"
    :loop="true"
    class="relative"
  >
    <slide>
      <div class="grid grid-cols-2">
        <!-- col 1 -->
        <div class="col-span-2 md:col-span-1">
          <img
            src="@/assets/img/home/testimonials/img1.png"
            alt="productos crediya"
          />
        </div>

        <!-- col 2 -->
        <div
          class="col-span-2 md:col-span-1 p-4 md:p-12"
          style="background-color:#cb1f2a;"
        >
          <h4 class="text-white text-lg mb-6">
            Testimonio de nuestros clientes
          </h4>
          <div class="flex justify-between mb-5">
            <img
              src="@/assets/img/home/testimonials/icono.png"
              alt="icono crediya"
              class="h-24"
            />
            <img
              src="@/assets/img/home/testimonials/comillas.png"
              alt="icono crediya"
              class="h-24"
            />
          </div>
          <p class="text-justify text-white mb-5">
            Estoy agradecida con la Cooperativa Credi Ya que me ayudó mucho en
            el progreso de mi Negocio, ellos me incentivaron al ahorro diario al
            final ese ahorro se incrementó gracias al interés que mensualmente
            entrega Credi Ya.
            <br />
            <br />
            Sra. Marcia Fiallos Lascano propietaria de Carnes Embutidos Pollo
            “Doña Marcia” llevo 20 años dedicada a mi negocio estoy ubicada en
            el interior del Mercado Colón sección Carnes puesto N°4.
          </p>
          <p class="text-white font-semibold">
            Carnes Embutidos Pollo Doña Marcia
          </p>
        </div>
      </div>
    </slide>
    <slide>
      <div class="grid grid-cols-2">
        <!-- col 1 -->
        <div class="col-span-2 md:col-span-1">
          <img
            src="@/assets/img/home/testimonials/img2.png"
            alt="productos crediya"
          />
        </div>

        <!-- col 2 -->
        <div
          class="col-span-2 md:col-span-1 p-4 md:p-12"
          style="background-color:#cb1f2a;"
        >
          <h4 class="text-white text-lg mb-6">
            Testimonio de nuestros clientes
          </h4>
          <div class="flex justify-between mb-5">
            <img
              src="@/assets/img/home/testimonials/icono.png"
              alt="icono crediya"
              class="h-24"
            />
            <img
              src="@/assets/img/home/testimonials/comillas.png"
              alt="icono crediya"
              class="h-24"
            />
          </div>
          <p class="text-justify text-white mb-5">
            Gracias a CrediYa por ser una Institución Financiera Segura y
            Confiable que me incentivo ahorrar; con esa ayuda he ido creciendo
            en mi negocio y he salido adelante.
            <br />
            <br />
            Sra. Jenny Chicaiza propietaria de la panadería “Trigo de Oro” su
            negocio empezó a sus 17 años de edad conjuntamente con su esposo,
            llevan ya 27 años dedicados a la panificación, están ubicados en la
            Colon y Manuela Cañizares.
          </p>
          <p class="text-white font-semibold">Panadería Trigo de Oro</p>
        </div>
      </div>
    </slide>
    <slide>
      <div class="grid grid-cols-2">
        <!-- col 1 -->
        <div class="col-span-2 md:col-span-1">
          <img
            src="@/assets/img/home/testimonials/img3.png"
            alt="productos crediya"
          />
        </div>

        <!-- col 2 -->
        <div
          class="col-span-2 md:col-span-1 p-4 md:p-12"
          style="background-color:#cb1f2a;"
        >
          <h4 class="text-white text-lg mb-6">
            Testimonio de nuestros clientes
          </h4>
          <div class="flex justify-between mb-5">
            <img
              src="@/assets/img/home/testimonials/icono.png"
              alt="icono crediya"
              class="h-24"
            />
            <img
              src="@/assets/img/home/testimonials/comillas.png"
              alt="icono crediya"
              class="h-24"
            />
          </div>
          <p class="text-justify text-white mb-5">
            Estoy agradecido con la Cooperativa Credi Ya, que brinda un buen
            servicio y Créditos para salir de situaciones difíciles, me ayudado
            a crecer mi negocio, además mantenemos nuestros ahorros e
            inversiones seguros, generando un buen interés para seguir adelante
            con mi empresa.
            <br />
            <br />
            Sr. Félix López dueño de “Aliss Store” estamos ubicado en la Bolívar
            y Ayllón, nos dedicamos a la confección de ternos de damas,
            caballeros, niños y ofrecemos camisas y accesorios para la gente
            elegante como usted.
          </p>
          <p class="text-white font-semibold">Aliss Store</p>
        </div>
      </div>
    </slide>
    <slide>
      <div class="grid grid-cols-2">
        <!-- col 1 -->
        <div class="col-span-2 md:col-span-1">
          <img
            src="@/assets/img/home/testimonials/img4.png"
            alt="productos crediya"
          />
        </div>

        <!-- col 2 -->
        <div
          class="col-span-2 md:col-span-1 p-4 md:p-12"
          style="background-color:#cb1f2a;"
        >
          <h4 class="text-white text-lg mb-6">
            Testimonio de nuestros clientes
          </h4>
          <div class="flex justify-between mb-5">
            <img
              src="@/assets/img/home/testimonials/icono.png"
              alt="icono crediya"
              class="h-24"
            />
            <img
              src="@/assets/img/home/testimonials/comillas.png"
              alt="icono crediya"
              class="h-24"
            />
          </div>
          <p class="text-justify text-white mb-5">
            Gracias al apoyo de mi esposo, mi hija y de la Institución, que
            mantiene mis inversiones seguras para el crecimiento de mi empresa.
            <br />
            <br />
            Sra. Elsa Peréz propietaria del Almacén “La Máquina” ofrece todo
            tipo de máquinas para la confección, está ubicada en la calle Ayllón
            0842 y Darquea tiene 30 años en el mercado.
          </p>
          <p class="text-white font-semibold">Almacén la Máquina</p>
        </div>
      </div>
    </slide>
  </carousel>
</template>

<script>
export default {
  name: "HomeCarrouselPrincipal"
};
</script>
