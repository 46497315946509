import axios from "axios";
import url from "./url";

// LOGIN
function postLogin(email, password) {
  return (
    axios
      .post(`${url}/users/login`, {
        email,
        password
      })
      .then(resp => {
        console.log("desde el back login:", resp.data);
        if (resp.data.status == "ok") {
          // this.$store.dispatch("saveToken", resp.data.token);
          return resp.data;
        }
      })
      // .then(data => {
      //   this.$store.dispatch("saveToken", data.token)
      //   this.$router.push({ name: 'lista-productos'})
      // })
      .catch(error => {
        console.log(error);
      })
  );
}

// SAVE USER
function saveUser(
  nombre,
  apellido,
  email,
  password,
  ci,
  celular,
  ciudad,
  agencia,
  type
) {
  return axios
    .post(`${url}/users/new-user`, {
      nombre,
      apellido,
      email,
      password,
      ci,
      celular,
      ciudad,
      agencia,
      type
    })
    .then(resp => resp.data);
}

// LIST USERS
function listUsers() {
  return axios.post(`${url}/users/list-user`).then(resp => {
    if (resp.data.status == "ok") {
      console.log(resp.data.users);
      return resp.data.users;
    }
  });
}

// UPLOAD IMAGE
function uploadImage(data) {
  let config = {
    header: {
      "Content-Type": "image/png"
    }
  };
  return axios.post(`${url}/product/save-image`, data, config).then(resp => {
    if (resp.data.status == "ok") {
      // console.log(resp.data.image);
      return resp.data.image;
    }
  });
}

export default {
  postLogin,
  saveUser,
  listUsers,
  uploadImage
};
