<template>
  <main style="background-color: #f5f5f5;">
    <!-- BANNER -->
    <div
      id="banner"
      :style="{
        'background-image':
          'url(' + require('@/assets/img/payments/reclamos.jpg') + ')'
      }"
    >
      <div class="texto-banner">
        <h2 class="text-white text-3xl font-bold">Quejas y Reclamos</h2>
        <p class="text-gray-100">Home / Reclamos</p>
      </div>
    </div>

    <!-- SECTION NOSOTROS -->
    <section class="pb-10">
      <div class="container mt-12  px-4 md:px-20">
        <!-- DESCRIPTION -->
        <h4 class="text-3xl text-gray-700 mb-4">QUEJAS Y RECLAMOS</h4>
        <div class="grid grid-cols-2">
          <div
            class="col-span-2 md:col-span-1 mb-4 order-2 md:order-1"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <p class="text-gray-700 italic font-semibold mb-4">
              - El formulario y su tramitación son gratuitos<br />
              - Lea detenidamente la información que se le solicita<br />
              - Adjunte la documentación requerida.<br />
              - Determine la petición concreta que realiza a la Cooperativa<br />
              - Todos los campos son obligatorios.<br />
            </p>
          </div>
          <div
            class="col-span-2 md:col-span-1 order-1 md:order-2"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <p
              class="text-gray-700 italic font-semibold mb-4"
              style="text-align: justify;"
            >
              De conformidad con el art. 12, IV Capítulo V del título XIV
              “Código de transparencia y de derechos del usuario” de la
              codificación de Resoluciones de la Superintendencia de Bancos y
              Seguros y de la Junta Bancaria (Tomado como referencia hasta que
              la Superintendencia de Economía Popular y Solidaria emita el marco
              normativo correspondiente), la cooperativa debe atender el
              presente reclamo en el plazo de quince (15) días tratándose de
              reclamos originados en el país y de dos (2) meses, cuando el
              reclamo se produzca por operaciones relacionadas con transacciones
              internacionales.
            </p>
          </div>
        </div>
      </div>
    </section>
    <div
      style="text-align: end; text-decoration: none; border-bottom: 1px solid red; color: #000000; "
    >
      <b><h2>Leer todos los términos de recepción de reclamos</h2></b>
    </div>
    <!-- CONTENT -->
    <section>
      <div>
        <form
          style="background: #ffffff; margin-top: 10px; margin-left: 60px; border-radius: 20px; margin-left: 2px"
        >
          <label
            for="reclamos"
            class="text-black text-2xl font-semibold"
            style="text-align: left; "
            >Identificación del Usuario que presenta el reclamo</label
          >
          <!--INICIO DE MODIFICIACION-->
          <div class="contenido-texto py-4">
            <div class="flex">
              <div class="w-3/12">
                <div class=" grid grid-cols-2">
                  <div class="col-span-2 md:col-span-6">
                    <label for="tipoidentification" class="text-black  block"
                      >Cédula/Pasaporte</label
                    >
                    <select
                      name="tipoidentification"
                      v-model="tipoidentification"
                      id="tipoidentification"
                      class="border text-sm px-4 py-2  rounded "
                    >
                      <option value="C">Cédula</option>
                      <option value="R">RUC</option>
                      <option value="P">Pasaporte</option>
                      <option value="F">Refugiado</option>
                      <option value="X">Entidades Extranjeras</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="w-3/12">
                <div class=" grid grid-cols-2">
                  <div class="col-span-2 md:col-span-6 pr">
                    <label for="identification" class="text-black  block"
                      >Número identificación</label
                    >
                    <input
                      v-model="identification"
                      name="identification"
                      type="text"
                      id="identification"
                      placeholder="Cédula de identidad"
                      class="border border-gray-600 p-1 -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
                    />
                  </div>
                </div>
              </div>
              <div class="w-3/12">
                <div class=" grid grid-cols-2">
                  <div class="col-span-2 md:col-span-6">
                    <label for="tipooperacion" class="text-black  block"
                      >Operación
                    </label>
                    <select
                      name="tipooperacion"
                      v-model="tipooperacion"
                      id="tipooperacion"
                      class="border text-sm px-4 py-2  rounded "
                    >
                      <option value="NV">Nueva</option>
                      <option value="VG">Vigente</option>
                      <option value="RV">Renovada</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="w-3/12">
                <div class="grid grid-cols-2">
                  <div class="col-span-2 md:col-span-6">
                    <label for="landline" class="text-black  block">
                      Teléfono Fijo
                    </label>
                    <input
                      v-model="landline"
                      name="landline"
                      type="text"
                      id="landline"
                      placeholder="0999999999"
                      class="border border-gray-600 p-1  -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="contenido-texto py-4">
            <div class="flex">
              <div class="w-3/12">
                <div class="grid grid-cols-2">
                  <div class="col-span-2 md:col-span-6">
                    <label for="lastname" class="text-black  block"
                      >Apellidos
                    </label>
                    <input
                      v-model="lastname"
                      name="lastname"
                      type="text"
                      id="lastname"
                      placeholder="Apellidos"
                      class="border border-gray-600 p-1  -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
                    />
                  </div>
                </div>
              </div>
              <div class="3/12">
                <div class="grid grid-cols-2">
                  <div class="col-span-2 md:col-span-6">
                    <label for="names" class="text-black  block">Nombres</label>
                    <input
                      v-model="names"
                      name="names"
                      type="text"
                      id="names"
                      placeholder="Nombres"
                      class="border border-gray-600 p-1 -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
                    />
                  </div>
                </div>
              </div>
              <div class="w-3/12">
                <div class="grid grid-cols-2">
                  <div class="col-span-2 md:col-span-6">
                    <label for="phone" class="text-black  block">
                      Celular
                    </label>
                    <input
                      v-model="phone"
                      name="phone"
                      type="text"
                      id="phone"
                      placeholder="0999999999"
                      class="border border-gray-600 p-1  -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
                    />
                  </div>
                </div>
              </div>
              <div class="w-3/12">
                <div class="grid grid-cols-2">
                  <div class="col-span-2 md:col-span-6">
                    <label for="email" class="text-black  block">
                      Correo Electrónico
                    </label>
                    <input
                      v-model="email"
                      name="email"
                      type="text"
                      id="email"
                      placeholder="correo@correo.com"
                      class="border border-gray-600 p-1  -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="contenido-texto py-4">
            <div class="flex">
              <div class="w-3/12">
                <div class="col-span-2 md:col-span-6">
                  <label for="province" class="text-black  block">
                    Provincia
                  </label>
                  <select
                    v-model="province"
                    name="province"
                    @change="onChangeParroquia($event)"
                    class="border border-gray-600 p-1  -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
                    aria-label="Default select example"
                  >
                    <option selected>Seleccione Provincia</option>
                    <option
                      :value="pro.provincia"
                      v-for="pro in provincias_totales"
                      :key="pro.provincia"
                      >{{ pro.provincia }}</option
                    >
                  </select>
                </div>
              </div>
              <div class="w-3/12">
                <div class="col-span-2 md:col-span-6">
                  <label for="city" class="text-black  block">
                    Ciudad/Cantón
                  </label>
                  <select
                    v-model="parish"
                    name="parish"
                    @change="onChangeCiudad($event)"
                    class="border border-gray-600 p-1  -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
                    aria-label="Default select example"
                  >
                    <option selected>Seleccione un cantón</option>
                    <option
                      :value="cant.canton"
                      v-for="cant in cantones_totales"
                      :key="cant.canton"
                      >{{ cant.canton }}</option
                    >
                  </select>
                </div>
              </div>
              <div class="w-3/12">
                <div class="col-span-2 md:col-span-6">
                  <label for="city" class="text-black  block">
                    Parroquia
                  </label>
                  <select
                    v-model="city"
                    name="city"
                    class="border border-gray-600 p-1 -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
                    aria-label="Default select example"
                  >
                    <option selected>Seleccionar una Ciudad</option>
                    <option
                      :value="ciudad.parroquia"
                      v-for="ciudad in ciudades_totales"
                      :key="ciudad.parroquia"
                      >{{ ciudad.parroquia }}</option
                    >
                  </select>
                </div>
              </div>
              <div class="w-3/12">
                <div class="grid grid-cols-2">
                  <div class="col-span-2 md:col-span-6">
                    <label for="barrio" class="text-black  block">
                      Barrio
                    </label>
                    <input
                      v-model="barrio"
                      type="text"
                      name="barrio"
                      id="barrio"
                      placeholder="dolorosa"
                      class="border border-gray-600 p-1  -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="contenido-texto py-4">
            <div>
              <div class="w-12/12">
                <div class="col-span-2 md:col-span-6">
                  <label for="referencia" class="text-black  block">
                    Referencia: Junto a / Cerca de
                  </label>
                  <input
                    v-model="referencia"
                    type="text"
                    name="referencia"
                    id="referencia"
                    placeholder="dolorosa"
                    class="border border-gray-600 p-1  -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
                  />
                </div>
              </div>
            </div>
          </div>
          <h4>Identificación de la Oficina donde se dió el incidente</h4>
          <div class="contenido-texto py-4">
            <div class="flex">
              <div class="w-3/12">
                <div class="col-span-2 md:col-span-6">
                  <label for="agencia" class="text-black  block">
                    Agencia
                  </label>
                  <select
                    name="agencia"
                    v-model="agencia"
                    id="agencia"
                    class="border text-sm px-4 py-2 rounded "
                  >
                    <option value="Guayaquil">Guayaquil</option>
                    <option value="Matriz">Matriz</option>
                    <option value="Quito">Quito</option>
                    <option value="Riobamba">Riobamba</option>
                    <option value="Ibarra">Ibarra</option>
                    <option value="Macas">Macas</option>
                    <option value="Quisapincha">Quisapincha</option>
                    <option value="Latacunga">Latacunga</option>
                    <option value="Sangolqui">Sangolqui</option>
                    <option value="SantoDomingo">Santo Domingo</option>
                    <option value="Izamba">Izamba</option>
                    <option value="HuachiGrande">Huachi Grande</option>
                  </select>
                </div>
              </div>
              <div class="w-3/12">
                <div class="col-span-2 md:col-span-6">
                  <label for="fincidente" class="text-black  block">
                    Fecha Incidente
                  </label>
                  <input
                    v-model="fincidente"
                    type="date"
                    name="fincidente"
                    id="fincidente"
                    class="border border-gray-600 p-1  -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
                  />
                </div>
              </div>
              <div class="w-3/12">
                <div class="col-span-2 md:col-span-6">
                  <label for="ncuenta" class="text-black  block">
                    Número de cuenta
                  </label>
                  <input
                    v-model="ncuenta"
                    name="ncuenta"
                    type="text"
                    id="ncuenta"
                    class="border border-gray-600 p-1  -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="contenido-texto py-4">
            <div class="flex">
              <div class="w-6/12">
                <label for="tiporeclamo" class="text-black  block">
                  Tipo de Reclamo
                </label>
                <select
                  v-model="tiporeclamo"
                  name="tiporeclamo"
                  @change="onChangeMenu($event)"
                  class="border border-gray-600 p-1  -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
                  aria-label="Default select example"
                >
                  <option selected>Seleccione Tipo de reclamo</option>
                  <option
                    :value="que.menu"
                    v-for="que in quejas_totales"
                    :key="que.menu"
                    >{{ que.menu }}</option
                  >
                </select>
              </div>
              <div class="w-6/12">
                <label for="detalle" class="text-black  block">
                  Detalle de reclamo
                </label>
                <select
                  v-model="detalle"
                  name="detalle"
                  id="detalle"
                  class="border border-gray-600 p-1  -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-11/12"
                  aria-label="Default select example"
                >
                  <option selected>Seleccione un detalle de reclamo</option>
                  <option
                    :value="cant.submenus"
                    v-for="cant in reclamos_totales"
                    :key="cant.submenu"
                    >{{ cant.submenu }}</option
                  >
                </select>
              </div>
            </div>
          </div>
          <div class="contenido-texto py-4">
            <div class="flex">
              <div class="w-6/12">
                <label for="descripText1" class="text-black  block">
                  Descripción de los hechos materia de este reclamo
                </label>
                <textarea
                  id="descripText1"
                  v-model="descripText1"
                  name="descripText1"
                  rows="4"
                  cols="50"
                  style=" border: 1px solid #000;"
                >
                </textarea>
              </div>
              <div class="w-6/12">
                <label for="descripText2" class="text-black  block"
                  >Petición en concreto que dirige a la cooperativa</label
                >
                <textarea
                  id="descripText2"
                  v-model="descripText2"
                  name="descripText2"
                  rows="4"
                  cols="50"
                  style=" border: 1px solid #000;"
                >
                </textarea>
              </div>
            </div>
          </div>
          <!--<h2>Documentos que se adjuntan</h2>
            <h4>Por favor suba los siguientes documentos para formalizar el pedido</h4>
            <div class="contenido-texto py-4">
                <div class="flex">
                  <div class="w-3/12">
                    <li>Subir Cédula/ Pasaporte</li>
                  </div>
                  
                </div>
            </div>-->
          <!--FIN DE MODIFICIACION-->
          <div class=" grid grid-cols-2">
            <div class="col-span-2 md:col-span-1">
              <!-- botontes -->
              <div class="mt-8 flex justify-around">
                <button
                  type="button"
                  id="guardar"
                  class="bg-gray-500 hover:bg-red-600 text-black px-6 py-1 rounded"
                  @click="saveQueja"
                  @keydown.tab.exact.prevent=""
                  style="background: #ca0f12 !important; color: white !important;"
                >
                  ENVIAR
                </button>

                <router-link
                  to="/"
                  class="bg-gray-500 hover:bg-gray-600 text-black px-6 py-1 rounded"
                  style="background: #3C3C3B !important; color: white !important;"
                  >REGRESAR</router-link
                >
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- </div> -->
    </section>
  </main>
</template>
<script>
import api from "@/api/quejas";
import { menus } from "@/api/quejareclamo";
import { provincias } from "@/api/provincias";
import validar_cedula from "@/api/validar_cedula";
import validar_celular from "@/api/validar_celular";
import validar_email from "@/api/validar_email";

export default {
  name: "Quejas",
  data() {
    return {
      tipoidentification: "",
      identification: "",
      tipooperacion: "",
      names: "",
      landline: "",
      phone: "",
      phoneII: "",
      email: "",
      province: "",
      parish: "",
      city: "",
      barrio: "",
      referencia: "",
      agencia: "",
      fincidente: "",
      ncuenta: "",
      tiporeclamo: "",
      detalle: "",
      descripText1: "",
      descripText2: "",
      provincias_totales: [],
      cantones_totales: [],
      ciudades_totales: [],
      quejas_totales: [],
      reclamos_totales: []
    };
  },
  mounted() {
    this.call_provincias();
    this.call_quejas();
  },

  methods: {
    // LLAMA A TODAS LAS PROVINCIAS
    call_provincias() {
      this.provincias_totales = provincias;
    },
    call_quejas() {
      this.quejas_totales = menus;
    },

    // CAMBIA A LA CANTONES
    onChangeParroquia(event) {
      let provincia_selecciona = event.target.value;
      this.provincias_totales.forEach(prov => {
        if (provincia_selecciona == prov.provincia) {
          this.cantones_totales = prov.cantones;
        }
      });
    },
    // CAMBIA A LA CANTONES
    onChangeMenu(event) {
      console.log("ingreso a la quejaseleccion");
      let queja_selecciona = event.target.value;
      console.log("asda", queja_selecciona);

      this.quejas_totales.forEach(quej => {
        console.log("***", quej);
        if (queja_selecciona == quej.menu) {
          this.reclamos_totales = quej.submenus;
        }
      });
    },

    // CAMBIA A LA CIUDADES
    onChangeCiudad(event) {
      let canton_seleccionado = event.target.value;
      this.cantones_totales.forEach(cant => {
        if (canton_seleccionado == cant.canton) {
          this.ciudades_totales = cant.parroquias;
        }
      });
    },

    show() {
      this.$modal.show("modal-terminos");
    },
    opened() {
      console.log("se abrio");
      // this.$refs.nombre.focus();
    },
    hide() {
      this.$modal.hide("modal-new-user");
    },
    saveQueja() {
      if (
        (console.log(
          "tipoidentification",
          this.tipoidentification,
          "identification",
          this.identification,
          "tipooperacion",
          this.tipooperacion,
          "a names",
          this.landline,
          "a landline",
          this.lastname,
          "a name",
          this.names,
          "a phone",
          this.phone,
          "a email",
          this.email,
          "a province",
          this.province,
          "a parish",
          this.parish,
          "a city",
          this.city,
          "a barrio",
          this.barrio,
          "a referencia",
          this.referencia,
          "a agencia",
          this.agencia,
          "a fincidente",
          this.fincidente,
          "a ncuenta",
          this.ncuenta,
          "a tiporeclamo",
          this.tiporeclamo,
          "a detalle",
          this.detalle,
          "a descripText1",
          this.descripText1,
          "a descripText2",
          this.descripText2
        ),
        this.tipoidentification == "" ||
          this.identification == "" ||
          this.tipooperacion == "" ||
          this.landline == "" ||
          this.lastname == "" ||
          this.names == "" ||
          this.phone == "" ||
          this.email == "" ||
          this.province == "" ||
          this.parish == "" ||
          this.city == "" ||
          this.barrio == "" ||
          this.referencia == "" ||
          this.agencia == "" ||
          this.fincidente == "" ||
          this.ncuenta == "" ||
          this.tiporeclamo == "" ||
          this.detalle == "" ||
          this.descripText1 == "" ||
          this.descripText2 == "")
      ) {
        this.$swal("Llene todo los campos", "", "error");
      } else {
        if (validar_cedula(this.identification)) {
          if (validar_celular(this.phone)) {
            if (validar_email(this.email)) {
              api
                .saveQuejas(
                  this.tipoidentification,
                  this.identification,
                  this.tipooperacion,
                  this.landline,
                  this.lastname,
                  this.names,
                  this.phone,
                  this.email,
                  this.province,
                  this.parish,
                  this.city,
                  this.barrio,
                  this.referencia,
                  this.agencia,
                  this.fincidente,
                  this.ncuenta,
                  this.tiporeclamo,
                  this.detalle,
                  this.descripText1,
                  this.descripText2
                )
                .then(resp => {
                  if (resp.status == "ok") {
                    this.$swal(
                      "¡Gracias por escribirnos!",
                      "Información enviada con éxito.",
                      "success"
                    ).then(() => {
                      this.identification = "";
                      this.email = "";
                    });
                  } else {
                    this.$swal("Error al guardar", "", "error");
                  }
                });
            } else {
              this.$swal("Email no válido", "", "error");
            }
          } else {
            this.$swal("Celular no válido", "", "error");
          }
        } else {
          this.$swal("Cédula no válida", "", "error");
        }
      }
    }
  }
};
</script>

<style scoped>
#banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 350px;
  width: 100%;
  position: relative;
}
.texto-banner {
  position: absolute;
  left: 10%;
  top: 40%;
}
.texto-principal {
  position: absolute;
  left: 10%;
  top: 40%;
}
.hr-per {
  border-color: #cd1e29;
  width: 117%;
  margin-left: -40px;
}
.hr-per::after {
  content: "";
  width: 190px;
  border: 2px solid #cd1e29;
  height: 2px;
  margin-left: 40px;
  margin-top: -4px;
  display: block;
}
.completo {
  width: 100%;
}
.titulo {
  padding-top: 30px;
  font-size: 20pt;
}
.margin {
  margin: 2px;
}
.logo {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.imgcss {
  width: auto;
  height: 90px;
}
.pr {
  padding-right: -4px;
}
.bg-blue {
  background-color: #1c4992;
}
</style>
