function validar_cedula(_cedula) {
  var cad = _cedula.trim();
  var total = 0;
  var longitud = cad.length;
  var longcheck = longitud - 1;
  var regreso;
  if (cad[0].match(/[a-zA-Z]/)) {
    return false;
  } else {
    if (cad !== "" && longitud === 10) {
      for (let i = 0; i < longcheck; i++) {
        if (i % 2 === 0) {
          var aux = cad.charAt(i) * 2;
          if (aux > 9) aux -= 9;
          total += aux;
        } else {
          total += parseInt(cad.charAt(i)); // parseInt o concatenará en lugar de sumar
        }
      }

      total = total % 10 ? 10 - (total % 10) : 0;

      if (cad.charAt(longitud - 1) == total) {
        //document.getElementById("salida").innerHTML = ("Cedula Válida");
        regreso = true;
        console.log("valido");
      } else {
        // document.getElementById("salida").innerHTML = ("Cedula Inválida");
        regreso = false;
        console.log("novalido");
      }
    }
  }

  return regreso;
}

export default validar_cedula;
