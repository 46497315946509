<template>
  <main>
    <h1>Nuevo Usuario</h1>
    <new-user />
    <list-users :list="list" />
  </main>
</template>
<script>
import ListUsers from "../../components/users/ListUsers.vue";
import NewUser from "../../components/users/NewUser.vue";
import api from "@/api/user";
export default {
  components: { NewUser, ListUsers },
  name: "User",
  data() {
    return {
      list: []
    };
  },
  created() {
    api.listUsers().then(list => (this.list = list));
  }
};
</script>
