<template>
  <main>
    <!-- DATEEE CONVERT -->

    <!-- SECTION QUIENES SOMOS -->
    <section>
      <form-wizard
        @on-complete="onComplete"
        next-button-text="Siguiente"
        back-button-text="atras"
        finish-button-text="Finalizar"
        next-button-class="btn"
        finish-button-class="pruebafin"
        class="bgwhite wiz"
        title=""
        subtitle=""
      >
        <tab-content icon="ti ti-id-badge">
          <!-- grid -->
          <div class="grid grid-cols-1 sm:grid-cols-3">
            <!-- colum1 -->
            <div class="img1">
              <img src="@/assets/img/consultas/personaje-vista-1.jpg" />
            </div>
            <!-- colum2 -->
            <div>
              <!-- griedinterno -->
              <div>
                <!-- columnainterna1 imagen -->
                <div></div>

                <!-- colum icono -->
                <div>
                  <img
                    src="@/assets/img/consultas/icono-titulo.png"
                    class="icon1"
                    alt="icono cedula"
                  />
                </div>
                <!-- columnacedula -->
                <div class="col-span-1 md:col-span-1 withd ">
                  <p class="cedula ">Ingrese el Token</p>
                  <p class="leftlorem ">
                    Ingrese el token enviado a su correo electrónico.
                  </p>
                  <input
                    class="leftloremci inp"
                    type="number"
                    min="6"
                    id="token"
                    v-model="token"
                  />
                </div>

                <div class="grid grid-cols-2 mt-10">
                  <div class="col-span-1">
                    <button
                      type="button"
                      style="margin-left: 45%"
                      class="bg-red-700 px-10 py-3 rounded text-white float-right focus:outline-none"
                      @click="searchtoken"
                      @keydown.tab.exact.prevent=""
                    >
                      Validar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- colum3 -->
          </div>
          <!-- tab2    -->
        </tab-content>
      </form-wizard>
    </section>
  </main>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import api from "@/api/formularios";
export default {
  name: "About",
  components: {
    FormWizard,
    TabContent
  },

  data() {
    return {
      token: ""
    };
  },
  onComplete() {},

  methods: {
    onComplete: function() {
      this.$router.push({ name: "home" });
    },
    searchtoken: function() {
      return new Promise(resolve => {
        //
        api.searchtoken(this.token).then(resp => {
          if (resp.status == "ok") {
            console.log("Cedula correcta");
            console.log("res api1", resp);
            this.$swal(
              "Token validado con exito un asesor se pondra en contacto con usted",
              "",
              "success"
            );
          } else {
            this.$swal(
              "Token Ingresado es Incorrecto, Por favor ingrese el token enviado al correo electrónico",
              "",
              "error"
            );
          }
        });

        //
        setTimeout(() => {
          resolve(true);
        }, 1000);
      });
    },
    validateAsync2: function() {
      return new Promise(resolve => {
        //

        setTimeout(() => {
          resolve(true);
        }, 1000);
      });
    },
    validateAsync3: function() {
      return new Promise(resolve => {
        //

        <router-link to="/"></router-link>;
        //
        setTimeout(() => {
          resolve(true);
          console.log("saliendoooo");
        }, 1000);
      });
    },

    cuentas: function() {
      this.table1 = true;
      this.table2 = false;
      this.table3 = false;
      this.table4 = true;
      (this.arraycuentasimprimir = []),
        (this.arraycuentasimprimir = this.cuentaSaldo.data);
      console.log("this.arraycuentasdesaldo", this.arraycuentasimprimir);
    },

    creditos: function() {
      (this.arraycuentasimprimir = []), (this.table4 = false);
      this.table1 = false;
      this.table2 = true;
      this.table3 = false;

      this.arraycuentasimprimir = this.cuentaCredito.data;

      console.log("creditos=====>[0].N", this.arraycuentasimprimir[0].NOMBRE);
      console.log("this.arraycuentasCreditos", this.arraycuentasimprimir);
      this.nombrecredito = this.arraycuentasimprimir[0].NOMBRE;
    },

    inversiones: function() {
      this.table4 = false;

      this.table1 = false;
      this.table2 = false;
      this.table3 = true;
      (this.arraycuentasimprimir = []),
        (this.arraycuentasimprimir = this.cuentaInversiones.data);
      console.log("this.arraycuentasInversiones", this.arraycuentasimprimir);
    }
  }
};
</script>
