<template>
  <carousel
    :perPage="1"
    :autoplay="true"
    :autoplayTimeout="3000"
    :paginationEnabled="false"
    :paginationSize="0"
    :navigationEnabled="true"
    :loop="true"
    class="relative"
  >
    <slide>
      <img
        src="@/assets/img/home/construyeFuturo/img1.png"
        alt="productos crediya"
      />
    </slide>
    <slide>
      <img
        src="@/assets/img/home/construyeFuturo/img2.png"
        alt="productos crediya"
      />
    </slide>
    <slide>
      <img
        src="@/assets/img/home/construyeFuturo/img3.png"
        alt="productos crediya"
      />
    </slide>
  </carousel>
</template>

<script>
export default {
  name: "HomeCarrousel"
};
</script>

<style>
#contruye-futuro .VueCarousel-navigation-button {
  top: 0 !important;
}
#contruye-futuro .VueCarousel-navigation-button.VueCarousel-navigation-prev {
  transform: translateY(330px) translateX(290px) !important;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 7px !important;
}
#contruye-futuro .VueCarousel-navigation-button.VueCarousel-navigation-next {
  transform: translateY(330px) translateX(-20px) !important;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 7px !important;
}
@media screen and (max-width: 690px) {
  #contruye-futuro .VueCarousel-navigation-button.VueCarousel-navigation-prev {
    transform: translateY(300%) translateX(0) !important;
  }
  #contruye-futuro .VueCarousel-navigation-button.VueCarousel-navigation-next {
    transform: translateY(300%) translateX(0) !important;
  }
}
</style>
