<template>
  <main style="with background-color: #f5f5f5;">
    <!-- DATEEE CONVERT -->

    <!-- SECTION QUIENES SOMOS -->
    <section>
      <form-wizard
        @on-complete="onComplete"
        next-button-text="Siguiente"
        back-button-text="atras"
        finish-button-text="Finalizar"
        next-button-class="btn"
        finish-button-class="pruebafin"
        class="bgwhite wiz"
        title=""
        subtitle=""
      >
        <tab-content :before-change="validateAsync" icon="ti ti-id-badge">
          <!-- grid -->
          <div class="grid grid-cols-1 sm:grid-cols-3">
            <!-- colum1 -->
            <div class="img1">
              <img src="@/assets/img/consultas/personaje-vista-1.jpg" />
            </div>
            <!-- colum2 -->
            <div>
              <!-- griedinterno -->
              <div>
                <!-- columnainterna1 imagen -->
                <div></div>

                <!-- colum icono -->
                <div>
                  <img
                    src="@/assets/img/consultas/icono-titulo.png"
                    class="icon1"
                    alt="icono cedula"
                  />
                </div>
                <!-- columnacedula -->
                <div class="col-span-1 md:col-span-1 withd ">
                  <p class="cedula ">Número de Cédula</p>
                  <p class="leftlorem ">
                    Ingrese su número de cédula sin guión.
                  </p>
                  <input
                    class="leftloremci inp"
                    type="number"
                    min="10"
                    v-model="cedula"
                  />
                </div>
              </div>
            </div>
            <!-- colum3 -->
            <div></div>
          </div>
          <!-- tab2    -->
        </tab-content>

        <tab-content
          :before-change="validateAsync2"
          icon="ti ti-unlock"
          class=""
        >
          <div class="grid grid-cols-1 sm:grid-cols-3">
            <!-- colum1 -->
            <div>
              <img
                class="img2 colum1movil"
                src="@/assets/img/consultas/personaje-vista-2.jpg"
              />
            </div>
            <!-- colum2 -->
            <div>
              <!-- griedinterno -->
              <div class="grid grid-cols-1 sm:grid-cols-3">
                <!-- columnainterna1 imagen -->

                <!-- colum icono -->
                <div>
                  <img
                    src="@/assets/img/consultas/icono-titulo.png"
                    class="icon2"
                    alt="icono ti"
                  />
                </div>
                <!-- columnacedula -->
                <div>
                  <p class="tokenT">Código de Verificación</p>
                  <p class="tokenL">
                    Hemos enviado un código vía sms a tú teléfono registrado,
                    Por favor ingrésalo
                  </p>
                  <div class=" di2 tamtxt grid grid-cols-3 sm:grid-cols-6">
                    <div class="di">
                      <input
                        class="inp2 "
                        type="text"
                        id="txt1"
                        v-model="txt1"
                        onKeyUp="if(this.value>9){this.value='9';}else if(this.value<0){this.value='0';}"
                      />
                    </div>
                    <div class="di">
                      <input
                        class=" inp2"
                        type="text"
                        id="txt2"
                        v-model="txt2"
                        onKeyUp="if(this.value>9){this.value='9';}else if(this.value<0){this.value='0';}"
                      />
                    </div>
                    <div class="di">
                      <input
                        class=" inp2"
                        type="number"
                        id="txt3"
                        v-model="txt3"
                        onKeyUp="if(this.value>9){this.value='9';}else if(this.value<0){this.value='0';}"
                      />
                    </div>
                    <div class="di">
                      <input
                        class=" inp2"
                        type="number"
                        id="txt4"
                        v-model="txt4"
                        onKeyUp="if(this.value>9){this.value='9';}else if(this.value<0){this.value='0';}"
                      />
                    </div>
                    <div class="di">
                      <input
                        class=" inp2"
                        type="number"
                        id="txt5"
                        v-model="txt5"
                        onKeyUp="if(this.value>9){this.value='9';}else if(this.value<0){this.value='0';}"
                      />
                    </div>
                    <div class="di">
                      <input
                        class=" inp2"
                        type="number"
                        id="txt6"
                        v-model="txt6"
                        onKeyUp="if(this.value>9){this.value='9';}else if(this.value<0){this.value='0';}"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- colum3 -->
            <div></div>
          </div>
        </tab-content>

        <tab-content icon="ti ti-id-badge" class="wizaedtam">
          <div class="grid grid-cols-1 sm:grid-cols-3">
            <div></div>
            <div></div>
            <div>
              <!-- culumnavacia -->
            </div>
            <div>
              <!-- culumnavacia -->
            </div>

            <div>
              <div class="grid grid-cols-1 sm:grid-cols-3 ">
                <!-- cuentas -->
                <div class="centrar">
                  <button class="cuenta " type="button" @click="cuentas">
                    Saldos de Cuenta
                  </button>
                </div>
                <div class="centrar">
                  <button class="cuenta " type="button" @click="creditos">
                    Créditos
                  </button>
                </div>
                <div class="centrar">
                  <button class="cuenta " type="button" @click="inversiones">
                    Inversiones
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <!-- culumnavacia3 -->
          </div>
          <div class="nombrecredito">
            <!-- //nombrecredito -->
            {{ this.nombrecredito }}
          </div>
          <!-- tabla 1 -->
          <div class="tablacss1">
            <table
              id="example"
              v-if="table1"
              style="overflow-y:scroll"
              class=" border-separate border border-gray-800 ..."
            >
              <thead>
                <tr>
                  <th class="center border border-gray-600 ...">N°</th>
                  <th class="center border border-gray-600 ...">Saldo</th>
                  <th class="center border border-gray-600 ...">
                    Saldos Disponibles
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="center border border-gray-600 ..."
                  v-for="(a, i) in arraycuentasimprimir"
                  :key="a.id"
                >
                  <td class="center border border-gray-600 ...">{{ i + 1 }}</td>
                  <td class="center border border-gray-600 ...">
                    {{ a.CUENTA }}
                  </td>
                  <td class="center border border-gray-600 ...">
                    {{ a["SALDO DISPONIBLE"] }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- tabla 2 -->
          <div class="tablacss">
            <table
              id="example2"
              v-if="table2"
              style="overflow-y:scroll"
              class=" border-separate border border-gray-800 ..."
            >
              <thead>
                <tr>
                  <th class="center border border-gray-600 ...">N°</th>
                  <th class="center border border-gray-600 ...">
                    Nombre Crédito
                  </th>
                  <th class="center border border-gray-600 ...">
                    Cuota a Pagar
                  </th>
                  <th class="center border border-gray-600 ...">
                    Saldo de Crédito
                  </th>
                  <th class="center border border-gray-600 ...">
                    Pendiente de Pago
                  </th>
                  <th class="center border border-gray-600 ...">
                    Días de Mora
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="center border border-gray-600 ..."
                  v-for="(a, i) in arraycuentasimprimir"
                  :key="a.id"
                >
                  <td>{{ i + 1 }}</td>

                  <td class="center border border-gray-600 ...">
                    {{ a.TIPO }}
                  </td>
                  <td class="center border border-gray-600 ...">
                    {{ a.VALORPENDIENTEPAGOMASCUOTAACTUAL }}
                  </td>
                  <td class="center border border-gray-600 ...">
                    {{ a.VALORPARALIQUIDARELPRESTAMO }}
                  </td>
                  <td class="center border border-gray-600 ...">
                    {{ a.PENDIENTEDEPAGO }}
                  </td>
                  <td class="center border border-gray-600 ...">
                    {{ a.DIASMORA }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- tabla 3 -->
          <div class="tablacss3">
            <table
              id="example3"
              v-if="table3"
              style="overflow-y:scroll"
              class=" border-separate border border-gray-800 ..."
            >
              <thead>
                <tr>
                  <th class="center border border-gray-600 ...">N°</th>
                  <th class="center border border-gray-600 ...">
                    Número Inversion
                  </th>
                  <th class="center border border-gray-600 ...">Monto</th>
                  <th class="center border border-gray-600 ...">
                    Total a Recibir
                  </th>
                  <th class="center border border-gray-600 ...">
                    Fecha de Vencimiento
                  </th>
                </tr>
              </thead>
              <tbody>
                <!-- 2021-02-01T00:29:26.420Z -->
                <tr
                  class="center border border-gray-600 ..."
                  v-for="(a, i) in arraycuentasimprimir"
                  :key="a.id"
                >
                  <td class="center border border-gray-600 ...">{{ i + 1 }}</td>
                  <td class="center border border-gray-600 ...">
                    {{ a["NUMERO INVERSION"] }}
                  </td>
                  <td class="center border border-gray-600 ...">
                    {{ a.MONTO }}
                  </td>
                  <td class="center border border-gray-600 ...">
                    {{ a["TOTAL RECIBIR"] }}
                  </td>
                  <td class="center border border-gray-600 ...">
                    {{
                      new Date(a["FECHA VENCIMIENTO"]).getFullYear() +
                        "/" +
                        (new Date(a["FECHA VENCIMIENTO"]).getMonth() + 1) +
                        "/" +
                        new Date(a["FECHA VENCIMIENTO"]).getDate()
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="tablecss4">
            <table
              id="example4"
              v-if="table4"
              style="overflow-y:scroll"
              class=" border-separate border border-gray-800 ..."
            >
              <thead>
                <tr>
                  <th class="center border border-gray-600 ...">N°</th>
                  <th class="center border border-gray-600 ...">
                    Fecha Proceso
                  </th>
                  <th class="center border border-gray-600 ...">Ahorro</th>
                  <th class="center border border-gray-600 ...">Detalle</th>
                  <th class="center border border-gray-600 ...">Saldo</th>
                  <th class="center border border-gray-600 ...">Valor</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="center border border-gray-600 ..."
                  v-for="(a, i) in cuentaMovimientos"
                  :key="a.id"
                >
                  <td class="center border border-gray-600 ...">{{ i + 1 }}</td>
                  <td class="center border border-gray-600 ...">
                    {{
                      new Date(a.FECHAPROCESO).getFullYear() +
                        "/" +
                        (new Date(a.FECHAPROCESO).getMonth() + 1) +
                        "/" +
                        new Date(a.FECHAPROCESO).getDate()
                    }}
                  </td>
                  <td class="center border border-gray-600 ...">
                    {{ a.AHORRRO }}
                  </td>
                  <td class="center border border-gray-600 ...">
                    {{ a.DETALLE }}
                  </td>
                  <td class="center border border-gray-600 ...">
                    {{ a.SALDO }}
                  </td>
                  <td class="center border border-gray-600 ...">
                    {{ a.VALOR }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- saldo -->
          <div></div>
          <div>
            <!-- tala2 -->
            <div></div>
          </div>
          <div></div>
        </tab-content>
      </form-wizard>
    </section>
  </main>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import apiback from "@/api/apiback";
export default {
  name: "About",
  components: {
    FormWizard,
    TabContent
  },

  data() {
    return {
      table1: false,
      table2: false,
      table3: false,
      table4: false,
      cedula: "",
      subtitulo: "",
      cantidad: "",

      txt1: "",
      txt2: "",
      txt3: "",
      txt4: "",
      txt5: "",
      txt6: "",
      tokenstring: "",
      cuentaSaldo: [],
      cuentaCredito: [],
      cuentaInversiones: [],
      cuentaMovimientos: [],
      arraycuentasimprimir: [],
      nombrecredito: "",
      saldodisponible: ""
    };
  },
  // mounted(){
  // console.log("mounteeed")
  onComplete() {},
  methods: {
    onComplete: function() {
      this.$router.push({ name: "home" });
    },
    validateAsync: function() {
      return new Promise(resolve => {
        //
        apiback.enviarCedula(this.cedula).then(resp => {
          if (resp.status == "ok") {
            console.log("Cedula correcta");
            console.log("res api1", resp);

            this.cedula = resp.cliente.ci;

            console.log("resp.cliente.ci", resp.cliente.ci);
          } else {
            this.$swal(
              "Al momento no es Soci@ de Cooperativa Credi Ya, le sugerimos que aperture una cuenta para acceder a este Servicio.",
              "",
              "error"
            );

            location.reload();
          }
        });

        //
        setTimeout(() => {
          resolve(true);
        }, 1000);
      });
    },
    validateAsync2: function() {
      return new Promise(resolve => {
        //
        this.tokenstring =
          this.txt1 + this.txt2 + this.txt3 + this.txt4 + this.txt5 + this.txt6;

        apiback.enviarToken(this.tokenstring, this.cedula).then(resp => {
          //console.log("respp=",resp)

          if (resp.status == "ok") {
            console.log("tienen cuentas activas");

            // console.log("jose",resp)
            // console.log(typeof resp)
            // console.log("token correcto trayendo datos de cuentas..");

            console.log("a)inversiones", resp.cuentaInversiones);
            console.log("b)saldos", resp.cuentaSaldo);
            console.log("c)creditos", resp.cuentasCredito);
            console.log("d)movimientos", resp.movimientos);

            if (resp.cuentaInversiones.status == "ok") {
              console.log("existe inversiones");
              this.cuentaInversiones = resp.cuentaInversiones;
            } else {
              console.log("no tiene inversiones");
            }
            if (resp.cuentasCredito.status == "ok") {
              console.log("existe creditos");
              this.cuentaCredito = resp.cuentasCredito;
            } else {
              console.log("no tiene credito");
            }
            if (resp.movimientos.status == "ok") {
              console.log("existen movimientos");
              this.cuentaMovimientos = resp.movimientos.data;
            } else {
              console.log("no tiene movimientos");
            }
            if (resp.cuentaSaldo.status == "ok") {
              console.log("existe saldo");
              this.cuentaSaldo = resp.cuentaSaldo;
            } else {
              console.log("no tiene saldo");
            }
          } else {
            console.log("token invalido");
            this.$swal("Código ingresado inválido ", "", "error");

            location.reload();
          }
          console.log("Token", resp);
        });
        //
        setTimeout(() => {
          resolve(true);
        }, 1000);
      });
    },
    validateAsync3: function() {
      return new Promise(resolve => {
        //

        <router-link to="/"></router-link>;
        //
        setTimeout(() => {
          resolve(true);
          console.log("saliendoooo");
        }, 1000);
      });
    },

    cuentas: function() {
      this.table1 = true;
      this.table2 = false;
      this.table3 = false;
      this.table4 = true;
      (this.arraycuentasimprimir = []),
        (this.arraycuentasimprimir = this.cuentaSaldo.data);
      console.log("this.arraycuentasdesaldo", this.arraycuentasimprimir);
    },

    creditos: function() {
      (this.arraycuentasimprimir = []), (this.table4 = false);
      this.table1 = false;
      this.table2 = true;
      this.table3 = false;

      this.arraycuentasimprimir = this.cuentaCredito.data;

      console.log("creditos=====>[0].N", this.arraycuentasimprimir[0].NOMBRE);
      console.log("this.arraycuentasCreditos", this.arraycuentasimprimir);
      this.nombrecredito = this.arraycuentasimprimir[0].NOMBRE;
    },

    inversiones: function() {
      this.table4 = false;

      this.table1 = false;
      this.table2 = false;
      this.table3 = true;
      (this.arraycuentasimprimir = []),
        (this.arraycuentasimprimir = this.cuentaInversiones.data);
      console.log("this.arraycuentasInversiones", this.arraycuentasimprimir);
    }
  }
};
</script>
<style>
.wizard-footer-left {
  display: none;
}
.tablecss4 {
  margin-top: 25px;
  margin-left: 550px;
}

.tablacss1 {
  margin-top: 25px;
  margin-left: 630px;
}
.tablacss {
  margin-top: 25px;
  margin-left: 420px;
}
.tablacss3 {
  margin-top: 25px;
  margin-left: 500px;
}
.di {
  width: 40px;
  margin-left: 5px;
  margin-right: 5px;
}
.colums {
  margin-top: 25px;
  margin-bottom: 25px;

  background: lightblue;
  text-align: center;
  border-bottom-width: 10px;
  border-top-width: 15px;
  border-left-width: thin;
  border-right: thin;
  border-bottom-color: rgb(84, 155, 140);
  border-right-color: rgb(84, 155, 140);
  border-left-color: rgb(84, 155, 140);
}
.hr-per {
  border-color: #cd1e29;
}
.bgwhite {
  background-color: white;
  height: auto;
  margin-top: 40px;
}
.largowizard {
  height: 400px;
}
.withd {
  width: 400px;
}
.withd2 {
  width: 600px;
}
.token {
  margin-left: 200px;
}
.margin {
  margin: 10px;
}
.money {
  margin-top: 10px;
  font-size: 32pt;
  font-weight: 600;
  font-weight: 500;
}
.saldo {
  margin-top: 10px;
  font-size: 22px;
  color: gray;
}
.cuenta {
  margin-top: 20px;
  width: 120px;
}
.cuenta:hover {
  background: rgb(231, 76, 60);
  color: white;
  font-weight: bold;
  border-radius: 8px;
  width: 120px;
}
.wit {
  width: 600px;
  margin-left: 50px;
}
.borde_discontinuo {
  border-style: solid double dashed;

  border-width: 30px;
  border-color: black;
  width: 10px;
}
.hr-per::after {
  content: "";
  width: 150px;
  border: 2px solid #cd1e29;
  height: 2px;
  margin-left: 20px;
  margin-top: -4px;
  display: block;
}
.img2 {
  width: 400px;
  height: 320px;
  margin-left: 300px;
  margin-top: 70px;
}
.img1 {
  width: 541px;
  height: 361px;
  margin-left: 380px;
}

.center {
  text-align: center;
}
.inp {
  border: none;
  border-bottom: 2px solid gray;
  border-radius: none;
}
.di2 {
  margin-left: 150px;
  width: 400px;
}
.inp2 {
  border: none;
  border-bottom: 2px solid gray;
  border-width: 10px;
  width: 100%;
  border-radius: none;
  margin-top: 100px;
  text-align: center;
  font-size: 14pt;
}
.img-banner-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 250px;
  width: 100%;
  position: relative;
}
.centrar {
  text-align: center;
}
.texto-principal-right {
  position: absolute;
  right: 10%;
  top: 40%;
}
.top {
  margin-top: 10px;
}
.icon2 {
  margin-left: 240px;
  margin-top: 130px;
  width: 30px;
}
.icon1 {
  margin-left: 240px;
  margin-top: 130px;
  width: 30px;
}
.leftlorem {
  font-size: 11pt;

  margin-left: 300px;
  width: 300px;
}
.cedula {
  font-size: 22pt;
  font-weight: 500;
  margin-top: -40px;
  margin-left: 300px;
  width: 300px;
}
.leftloremci {
  margin-left: 300px;
  margin-top: 80px;
  width: 250px;
  text-align: center;
  font-size: 14pt;
}

.leftloremci2 {
  margin-left: 320px;
  margin-top: 80px;

  width: 250px;
}

.table {
  margin-top: 40px;
  width: 500px;
  margin-left: 490px;
}
.tableformat {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #000;
}
.th {
  width: 150px;
  text-align: center;
  color: gray;
}
.t {
  width: 150px;
  text-align: center;
}
.flecha {
  width: 25px;

  margin-left: auto;
  margin-right: auto;
  margin-top: 2px;
  margin-bottom: 2px;
}
.tokenT {
  font-size: 22pt;
  font-weight: 500;
  margin-top: 120px;
  margin-left: 150px;
  width: 300px;
}
.tokenL {
  font-size: 11pt;
  /* text-align: left; */
  margin-left: 150px;
  width: 315px;
  margin-top: 10px;
}
.tokenI {
  margin-left: 150px;
  margin-top: 80px;

  width: 250px;
}
.tamtxt {
  width: 400px;
}
.nombrecredito {
  margin-top: 40px;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .wizard-footer-left {
    display: none !important;
  }
  .t {
    width: 250px;
  }
  .bgwhite {
    background-color: white;
    height: auto;
    margin-top: 40px;
    widows: auto;
  }
  .centrar {
    width: 270px;
  }
  .tablecss4 {
    margin-top: 25px;
    margin-left: 0px;
    margin-bottom: 20px;

    overflow: scroll;
  }

  .tablacss1 {
    margin-top: 25px;
    margin-left: 45px;
    overflow: scroll;
  }

  .tablacss3 {
    margin-top: 25px;
    margin-left: 0px;
    overflow: scroll;
  }

  .tablacss {
    margin-top: 25px;
    margin-left: 0px;
    overflow: scroll;
  }

  .taminp2 {
    width: auto;
  }
  .di {
    width: 40px;
    margin-left: -25px;
    margin-right: 5px;
    margin-bottom: 25px;
  }
  .marginbotonc {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 40px;
    margin-right: 100px;
    width: 200px;
  }
  .inp2 {
    margin-left: 40px;
    margin-top: 20px;
    border: none;
    border-bottom: 2px solid gray;
    border-width: 10px;
    width: 100%;
  }

  .wizaedtam {
    width: 250px;
    height: auto;
  }
  .table {
    margin-top: 40px;

    margin-left: 40px;
    margin-top: 20px;
  }
  .tokenT {
    font-size: 22pt;
    font-weight: 500;
    margin: 10px 0;
    width: 100%;
  }
  .tokenL {
    font-size: 11pt;
    text-align: justify;
    margin: 15px 0;
    width: 100%;
  }
  .tokenI {
    margin-left: 40px;
    margin-top: 20px;
    margin-top: 10px;
  }

  .img2 {
    margin-top: 20px;
    margin-left: 0;
    margin-top: 10px;
    width: 300px;
    height: 200px;
  }

  .icon1 {
    margin: 20px auto;
    width: 30px;
  }
  .wiz {
    width: auto;
    height: auto;
  }

  .img1 {
    width: 280px;
    height: 300px;
    margin: 0 auto;
  }
  .leftlorem {
    font-size: 11pt;
    text-align: center;
    margin: 0 auto;
  }
  .cedula {
    font-size: 22pt;
    font-weight: 500;
    text-align: center;
    margin-left: 15px;
    margin-top: 20px;
    width: 280px;
  }
  .leftloremci {
    margin: 10px 30px;
    text-align: center;
  }

  .leftloremci2 {
    margin-left: 40px;
    margin-top: 20px;
    margin-top: 10px;

    width: 200px;
  }
  .token {
    margin-left: 40px;
    margin-top: 20px;
  }
  .icon2 {
    margin: 20px auto;
    width: 30px;
  }
  .tamtxt {
    margin-left: 40px;
    width: 200px;
    width: auto;
  }
  .withd {
    width: auto;
  }
  .wizard-card-footer {
    display: flex;
    justify-content: center;
  }
}
</style>
