<template>
  <main>
    
    <modal
      name="modal-new-user"
      :adaptive="true"
      transition="pop-out"
      :scrollable="true"
      :width="977"
      :height="290"
      :margin-top="65"
      
    >
      
    <p class="text-black pr-5 mt-3" style="margin-left: 1%;">
      Nuestro sitio web utilizamos cookies propias para usos estadísticos, mejorar su experiencia, funcionalidad y navegación. Como organización nos servimos de las tecnologías, incluidas las cookies, para recopilar información sobre usted con diversas finalidades
    </p> 
    <p class="text-black pr-5 mt-3">
      <ul class="p-0 mr-2">
        <li class="mb-3">
            <font-awesome-icon
              :icon="['fas', 'angle-double-right']"
              class="rojo"
              style="margin-left: 5%;"
            />
            <span class="gris ml-3 text-sm">Estadísticas</span>
        </li>
        <li class="mb-3">
            <font-awesome-icon
              :icon="['fas', 'angle-double-right']"
              class="rojo"
              style="margin-left: 5%;"
            />
            <span class="gris ml-3 text-sm">Publicidad</span>
        </li>
        <li class="mb-3">
          <font-awesome-icon
              :icon="['fas', 'angle-double-right']"
              class="rojo"
              style="margin-left: 5%;"
            />
            
            <span class="gris ml-3 text-sm">Mejora</span>
        </li>
        
      </ul>
    </p>
    <p class="text-black pr-5 mt-3" style="margin-left: 1%;">
      Puede aceptar el uso de cookies al presionar el botón  <a class="inline-block text-red-700 ">"Aceptar" </a> así como rechazar al presionar el botón <a class="inline-block text-red-700 ">"Rechazar"</a>.
Para más informacion sobre el uso y tratamiento cookies presione <a class="inline-block text-red-700 " href="/politicas-seguridad">"AQUÍ"</a>
 
     
    </p>
      <div class="grid grid-cols-2 mt-2" style="margin-left:52%;">
          <div class="col-span-1">
            <button
              type="button"
              class="bg-red-700 px-10 py-3 mr-0 md:mr-10 rounded text-white float-right focus:outline-none"
              @click="closeModal"
            >
              Aceptar
            </button>
          </div>
          <div class="col-span-1">
            <button
              type="button"
              class="bg-red-700 px-10 py-3 rounded text-white  "
              @click="closeModal"
            >
              Rechazar
            </button>
          </div>
        </div>
          
    </modal>
    <div class="mt-20 ">
      <swiper-c />
    </div>
    <!--DESARROLLO-->
    <!--SEGUNDO MODAL-->
    <modal
      name="modal-new-user"
      :adaptive="true"
      transition="pop-out"
      :scrollable="true"
      :width="577"
      :height="577"
    >
      <img
        src="@/assets/img/home/bannerPrincipal/banner0/imginicial.png"
        alt="popup"
      />
    </modal>
    <!-- CONSTRUYE TU FUTURO -->
    <section
      id="contruye-futuro"
      class="pb-20"
      style="background-color: #f3f3f3;"
    >
      <div
        class="banner-futuro z-0"
        :style="{
          'background-image':
            'url(' + require('@/assets/img/home/banner.jpg') + ')'
        }"
      ></div>
      <div class="grid grid-cols-2 z-1  -mt-24 container mx-auto px-2 md:px-24">
        <div
          class="col-span-2 md:col-span-1 px-20"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <home-carrousel />
        </div>
        <div
          class="col-span-2 md:col-span-1 "
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <h2 class="text-black md:text-white text-3xl font-bold mb-5">
            Construye tu futuro<br />con nosotros
          </h2>
          <h6 class="gris mb-4 text-xl font-bold">Credi Ya te Premia</h6>
          <p class="mb-5 text-gray-700">
            Tus inversiones se encuentran seguras con una institución fuerte con
            trayectoria. Gana la mejor tasa de interés del mercado.
          </p>
          <a
            class="inline-block bg-red-700 text-white px-8 py-2 mt-4 mb-5 hover:bg-red-600 transition duration-700 ease-in-out"
            href="/producto/inversion-gold"
            >Más Información</a
          >
        </div>
      </div>
    </section>

    <!-- PRODUCTOS -->
    <section id="productos">
      <div class="">
        <div class="grid grid-cols-2">
          <!-- col 1 -->
          <div class="col-span-2 md:col-span-1 order-0 sm:order-0">
            <div class="grid grid-cols-2">
              <div class="p-0" data-aos="fade-down" data-aos-duration="1000">
                <img
                  class="img-fluid"
                  src="@/assets/img/home/servicio-1.jpg"
                  alt="servicio crediya"
                />
              </div>
              <div
                class="p-0 md:p-10"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div
                  class="flex justify-center flex-wrap items-center flex-col h-full contenido-texto"
                >
                  <img
                    class="img-fluid img-icono"
                    src="@/assets/img/home/icono-servicio2.png"
                    alt="servicio icono crediya"
                    width="70"
                  />
                  <h4 class="mt-3 mb-3 font-bold text-lg">Créditos</h4>
                  <p class="text-center mb-4">
                    Te brindamos facilidad de financiamiento para cumplir tus
                    metas.
                  </p>
                  <a
                    class="inline-block bg-red-700 text-white px-8 py-1 mt-4 hover:bg-red-600 transition duration-700 ease-in-out"
                    href="/producto/crediconsumo-prioritario"
                    >Más Información</a
                  >
                </div>
              </div>
            </div>
          </div>

          <!-- col 2 -->
          <div class="col-span-2 md:col-span-1 order-3 sm:order-1">
            <div class="grid grid-cols-2">
              <div class="p-0" data-aos="fade-down" data-aos-duration="1000">
                <img
                  class="img-fluid"
                  src="@/assets/img/home/servicio-2.jpg"
                  alt="servicio crediya"
                />
              </div>
              <div
                class="p-0 md:p-10"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div
                  class="flex justify-center flex-wrap items-center flex-col h-full contenido-texto"
                >
                  <img
                    class="img-fluid img-icono"
                    src="@/assets/img/home/icono-servicio4.png"
                    alt="servicio icono crediya"
                    width="70"
                  />
                  <h4 class="mt-3 mb-3 font-bold text-lg">Ahorros</h4>
                  <p class="text-center mb-4">
                    La mejor forma de asegurar tu futuro es con el ahorro.
                  </p>
                  <a
                    class="inline-block bg-red-700 text-white px-8 py-1 mt-4 hover:bg-red-600 transition duration-700 ease-in-out"
                    href="/producto/ahorro-vista"
                    >Más Información</a
                  >
                </div>
              </div>
            </div>
          </div>

          <!-- col 3 -->
          <div class="col-span-2 md:col-span-1 order-2 sm:order-2">
            <div class="grid grid-cols-2">
              <div class="p-0 md:p-10">
                <div
                  class="flex justify-center flex-wrap items-center flex-col h-full contenido-texto"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <img
                    class="img-fluid img-icono"
                    src="@/assets/img/home/icono-servicio2.png"
                    alt="servicio icono crediya"
                    width="70"
                  />
                  <h4 class="mt-3 mb-3 font-bold text-lg">Plazo Fijo</h4>
                  <p class="text-center mb-4">
                    Tus inversiones ganan la mejor tasa de interés. Invierte
                    seguro.
                  </p>
                  <a
                    class="inline-block bg-red-700 text-white px-8 py-1 mt-4 hover:bg-red-600 transition duration-700 ease-in-out"
                    href="/producto/inversion-gold"
                    >Más Información</a
                  >
                </div>
              </div>
              <div class="p-0" data-aos="fade-up" data-aos-duration="1000">
                <img
                  class="img-fluid"
                  src="@/assets/img/home/servicio-3.jpg"
                  alt="servicio crediya"
                />
              </div>
            </div>
          </div>
          <div class="col-span-2 md:col-span-1 order-3 sm:order-3">
            <div class="grid grid-cols-2">
              <div
                class="p-0 md:p-10"
                data-aos="fade-down"
                data-aos-duration="1000"
              >
                <div
                  class="flex justify-center flex-wrap items-center flex-col h-full contenido-texto"
                >
                  <img
                    class="img-fluid img-icono"
                    src="@/assets/img/home/icono-servicio4.png"
                    alt="servicio icono crediya"
                    width="70"
                  />
                  <h4 class="mt-3 mb-3 font-bold text-lg">
                    Certificados de Aportación
                  </h4>
                  <p class="text-center mb-4">
                    Mayor monto en certificados de aportación, puedes acceder a
                    mayor financiamiento.
                  </p>
                  <a
                    class="inline-block bg-red-700 text-white px-8 py-1 mt-4 hover:bg-red-600 transition duration-700 ease-in-out"
                    href="/producto/crediconsumo-ordinario"
                    >Más Información</a
                  >
                </div>
              </div>
              <div class="p-0" data-aos="fade-up" data-aos-duration="1000">
                <img
                  class="img-fluid"
                  src="@/assets/img/home/servicio-4.jpg"
                  alt="servicio crediya"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- PARALLAX -->
    <div
      class="contenedor-parallax mb-10 relative"
      style="max-height:300px; width:100%; overflow:hidden;"
    >
      <parallax-c />
    </div>

    <!-- INVIERTE CON NOSOTROS -->
    <section id="reloj">
      <div class="container grid grid-cols-2 grap-10">
        <div
          class="col-span-2 md:col-span-1"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <img
            class="-mt-10 md:mt-0 w-3/4 mx-auto"
            src="@/assets/img/home/dinero-reloj.png"
            alt="dinero reloj crediya"
          />
        </div>
        <div
          class="col-span-2 md:col-span-1 px-3"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <h1 class="font-bold text-3xl rojo">Invierte con<br />nosotros</h1>
          <br />
          <p class="text-white pr-5 mt-3">
            Invierte desde $500 USD, y recibe grandes beneficios. Tu dinero
            seguro con CrediYa. Te invitamos a que tu dinero trabaje por ti.
          </p>
          <button
            class="mt-5 mb-5 inline-block px-6 py-1 font-normal leading-7 text-center text-gray-100 transition bg-transparent border-2 border-gray-100 border-opacity-75 shadow ripple hover:shadow-lg hover:bg-red-400 transition duration-700 ease-in-out"
            type="button"
          >
            Más Información
          </button>
        </div>
      </div>
    </section>

    <!-- CALIFICACION -->
    <section id="calificacion">
      <div class="container">
        <div class="grid grid-cols-2">
          <div
            class="col-span-2 md:col-span-1"
            data-aos="fade-down"
            data-aos-duration="1000"
          >
            <img
              class="img-fluid"
              src="@/assets/img/home/calificacion.jpg"
              alt="calificacion crediya"
            />
          </div>
          <div
            class="col-span-2 md:col-span-1 flex flex-col justify-center p-10"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h1 class="gris font-semibold text-3xl">Calificación AA</h1>
            <br />
            <p class="pr-5 gris">
              La Cooperativa de Ahorro y Crédito CREDI YA Ltda., fue calificada
              inicialmente por la CONAFIPS el 14 de octubre 2013, y con estados
              financieros con corte a: 30 septiembre 2019 obtuvo una
              calificación CAFi A2 con 82 puntos y, un cupo de financiamiento,
              vigente hasta el 30 mayo 2020.
            </p>
            <img
              class="img-fluid mt-5"
              src="@/assets/img/home/Innovamos.png"
              alt="innovamos para ti crediya"
              width="260"
            />
          </div>
        </div>
      </div>
    </section>

    <!-- TEATIMONIALES -->
    <testimonials />
    <!-- <section class="testimonials">

    </section> -->
  </main>
</template>

<script>
import Testimonials from "../components/home/Testimonials.vue";
import HomeCarrousel from "../components/home/HomeCarrousel";
import ParallaxC from "../components/home/ParallaxC";
import SwiperC from "../components/home/SwiperC.vue";

export default {
  name: "Home",
  components: {
    ParallaxC,
    SwiperC,
    HomeCarrousel,
    Testimonials
  },
  mounted() {
    this.$modal.show("modal-new-user");
  },
  methods: {
    show() {
      this.$modal.show("modal-new-user");
    },
    hide() {
      this.$modal.hide("modal-new-user");
    },
    closeModal(){
      this.$modal.hide("modal-new-user");
    }
  }
};
</script>

<style scoped>
.ta {
  height: 500px;
}
/* CONSTRUYE TU FUTURO */
.banner-futuro {
  background-repeat: no-repeat;
  background-size: cover;
  height: 250px;
  width: 100%;
}

/* PRODUCTOS */
#productos .contenido-texto img {
  width: 60px;
}
#productos .contenido-texto p {
  padding: 0 !important;
  margin-bottom: 5px !important;
}

#productos .img-icono {
  width: 40px !important;
}

/* section reloj */
#reloj {
  position: relative;
  margin-bottom: 50px;
}
#reloj::before {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 150px;
  background-color: #cd1f27;
  top: 100px;
}
@media screen and (max-width: 690px) {
  #reloj::before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 200px;
    background-color: #cd1f27;
    top: 250px;
  }
}

/* CALIFICACION */
#calificacion {
  background-color: #f3f3f3;
}
</style>
