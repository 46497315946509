<template>
  <main style="background-color: #f5f5f5;">
    <!-- BANNER -->
    <div
      id="banner"
      :style="{
        'background-image':
          'url(' + require('@/assets/img/governance/banner.jpg') + ')'
      }"
    >
      <div class="texto-banner">
        <h2 class="text-white text-3xl font-bold">Gobernanza</h2>
        <p class="text-gray-100">Home / Gobernanza</p>
      </div>
    </div>

    <!-- CONTENT -->
    <section>
      <div class="container mt-12  px-4 md:px-20">
        <!-- DESCRIPTION -->
        <div class="grid grid-cols-2 mb-12">
          <div
            class="col-span-2 md:col-span-1 mb-4"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <p class="text-gray-700 mb-0">Bienvenidos</p>
            <h4 class="text-3xl text-gray-700 mb-4">Conócenos</h4>
            <p class="text-gray-600">
              La Cooperativa de Ahorro y Crédito Credi Ya Ltda.; es una entidad
              que realiza intermediaciones financieras y de responsabilidad
              social ayudando a mejorar las condiciones de vida de nuestros
              socios desde el año 2011.
            </p>
          </div>
          <div
            class="col-span-2 md:col-span-1"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <img
              src="@/assets/img/governance/iconoGOBER1.png"
              class="float-center md:float-right m-auto"
              alt="credito crediya"
              width="300"
            />
          </div>
        </div>
      </div>

      <!-- Consejo de Administración -->
      <div
        class="img-banner-bg"
        :style="{
          'background-image':
            'url(' + require('@/assets/img/governance/banner2.png') + ')'
        }"
      >
        <div
          class="texto-principal"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <h3 class="text-white text-4xl font-bold">
            Consejo de <br />Administración
          </h3>
        </div>
      </div>

      <!-- CONTENT TEXT -->
      <div class="owners bg-white py-4">
        <div class="grid grid-cols-2 mx-auto px-2 md:px-20">
          <!-- COL 1 -->
          <div class="col-span-2 md:col-span-1">
            <!-- REPRESNT -->
            <div
              class="flex"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              <img
                src="@/assets/img/governance/iconom.png"
                alt="icono mujer"
                class="w-20 h-20 inline-block"
              />
              <div class="textos my-3">
                <span class="block gris font-semibold"
                  >LUZ MARIA CHUQUI BUENO</span
                >
                <span>Presidenta</span>
              </div>
            </div>
            <!-- REPRESNT -->
            <div
              class="flex"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              <img
                src="@/assets/img/governance/iconom.png"
                alt="icono mujer"
                class="w-20 h-20 inline-block"
              />
              <div class="textos my-3">
                <span class="block gris font-semibold"
                  >ANA GRACIELA MOPOSITA MOPOSITA</span
                >
                <span>Vicepresidenta</span>
              </div>
            </div>
            <!-- REPRESNT -->
            <div
              class="flex"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="300"
            >
              <img
                src="@/assets/img/governance/iconoh.png"
                alt="icono hombre"
                class="w-20 h-20 inline-block"
              />
              <div class="textos my-3">
                <span class="block gris font-semibold"
                  >FAUSTO DANIEL PEREZ QUIROGA</span
                >
                <span>Secretario</span>
              </div>
            </div>
          </div>

          <!-- COL 2 -->
          <div class="col-span-2 md:col-span-1 mt-2 md:mt-10">
            <!-- REPRESNT -->
            <div
              class="flex"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="400"
            >
              <img
                src="@/assets/img/governance/iconom.png"
                alt="icono mujer"
                class="w-20 h-20 inline-block"
              />
              <div class="textos my-3">
                <span class="block gris font-semibold"
                  >ANA MARÍA CALERO LOPÉZ</span
                >
                <span>Vocal principal</span>
              </div>
            </div>
            <!-- REPRESNT -->
            <div
              class="flex"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="500"
            >
              <img
                src="@/assets/img/governance/iconoh.png"
                alt="icono hombre"
                class="w-20 h-20 inline-block"
              />
              <div class="textos my-3">
                <span class="block gris font-semibold"
                  >MARLON VINICIO ULLOA MARTINEZ</span
                >
                <span>Vocal principal</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Consejo de Vigilancia -->
      <div
        class="img-banner-bg"
        :style="{
          'background-image':
            'url(' + require('@/assets/img/governance/banner3.png') + ')'
        }"
      >
        <div
          class="texto-principal-right text-right"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <h3 class="text-white text-4xl font-bold">
            Consejo de <br />Vigilancia
          </h3>
        </div>
      </div>

      <!-- CONTENT TEXT -->
      <div class="owners bg-white py-4">
        <div class="grid grid-cols-2 mx-auto px-2 md:px-20">
          <!-- COL 1 -->
          <div class="col-span-2 md:col-span-1">
            <!-- REPRESNT -->
            <div
              class="flex"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              <img
                src="@/assets/img/governance/iconom.png"
                alt="icono mujer"
                class="w-20 h-20 inline-block"
              />
              <div class="textos my-3">
                <span class="block gris font-semibold">JUANA CHUQUI GUANO</span>
                <span>Presidenta</span>
              </div>
            </div>
            <!-- REPRESNT -->
            <div
              class="flex"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              <img
                src="@/assets/img/governance/iconoh.png"
                alt="icono hombre"
                class="w-20 h-20 inline-block"
              />
              <div class="textos my-3">
                <span class="block gris font-semibold"
                  >ALVARO GIOVANNI CRIOLLO PAUCAR</span
                >
                <span>Secretario</span>
              </div>
            </div>
            <!-- REPRESNT -->
            <div
              class="flex"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="300"
            >
              <img
                src="@/assets/img/governance/iconom.png"
                alt="icono mujer"
                class="w-20 h-20 inline-block"
              />
              <div class="textos my-3">
                <span class="block gris font-semibold"
                  >NARCIZA GIOCONDA MORETA PIMBO</span
                >
                <span>Vocal principal</span>
              </div>
            </div>
          </div>

          <!-- COL 2 -->
          <div class="col-span-2 md:col-span-1 mt-2 md:mt-10">
            <!-- REPRESNT -->
            <div
              class="flex"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="400"
            >
              <img
                src="@/assets/img/governance/iconom.png"
                alt="icono mujer"
                class="w-20 h-20 inline-block"
              />
              <div class="textos my-3">
                <span class="block gris font-semibold"
                  >ABIGAIL CELESTE NARANJO TORRES</span
                >
                <span>Vocal principal</span>
              </div>
            </div>
            <!-- REPRESNT -->
            <div
              class="flex"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="500"
            >
              <img
                src="@/assets/img/governance/iconom.png"
                alt="icono mujer"
                class="w-20 h-20 inline-block"
              />
              <div class="textos my-3">
                <span class="block gris font-semibold"
                  >CARMEN BERSAIDA AZOGUE AZOGUE</span
                >
                <span>Vocal principal</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- EQUIPO ADMINISTRATIVO -->
      <div
        class="img-banner-bg"
        :style="{
          'background-image':
            'url(' + require('@/assets/img/governance/banner4.png') + ')'
        }"
      >
        <div
          class="texto-principal"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <h3 class="text-white text-4xl font-bold">
            Equipo<br />Administrativo
          </h3>
        </div>
      </div>

      <!-- CONTENT TEXT -->
      <div class="owners py-4">
        <div class="grid grid-cols-2 mx-auto px-2 md:px-20">
          <!-- COL 1 -->
          <div class="col-span-2 md:col-span-1">
            <!-- REPRESNT -->
            <div
              class="flex"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              <img
                src="@/assets/img/governance/iconom.png"
                alt="icono mujer"
                class="w-20 h-20 inline-block"
              />
              <div class="textos my-3">
                <span class="block gris font-semibold"
                  >ING. CRISTINA SANTAMARIA</span
                >
                <span>Gerente General</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "Governance"
};
</script>

<style scoped>
/* Banner */
#banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 350px;
  width: 100%;
  position: relative;
}
.img-banner-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 250px;
  width: 100%;
  position: relative;
}
.texto-banner {
  position: absolute;
  left: 10%;
  top: 40%;
}
.texto-principal {
  position: absolute;
  left: 10%;
  top: 40%;
}
.texto-principal-right {
  position: absolute;
  right: 10%;
  top: 40%;
}
</style>
