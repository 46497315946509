<template>
  <parallax
    :speed-factor="0.1"
    :fixed="false"
    breakpoint="(min-width: 580px)"
    :parallax="true"
  >
    <div
      class="contanier flex justify-around flex-wrap content-center mt-20 absolute top-0 left-0 right-0 h-48"
    >
      <div class="text-white">
        <img
          src="@/assets/img/home/icons/icono1.png"
          alt="icono estadistica crediya"
          width="120"
        />
        <h4 class="text-white text-center font-bold mt-2">Socios</h4>
      </div>
      <div class="text-white">
        <img
          src="@/assets/img/home/icons/icono2.png"
          alt="icono estadistica crediya"
          width="120"
        />
        <h4 class="text-white text-center font-bold mt-2">En Activos</h4>
      </div>
      <div class="text-white">
        <img
          src="@/assets/img/home/icons/icono3.png"
          alt="icono estadistica crediya"
          width="120"
        />
        <h4 class="text-white text-center font-bold mt-2">
          Créditos Entregados
        </h4>
      </div>
    </div>
    <img src="@/assets/img/home/parallax.jpg" alt="parallax crediya" />
  </parallax>
</template>
<script>
import Parallax from "vue-parallaxy";
export default {
  name: "ParallaxC",
  components: {
    Parallax
  }
};
</script>
