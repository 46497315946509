<template>
  <main style="background-color: #f5f5f5;">
    <!-- BANNER -->
    <div
      id="banner"
      :style="{
        'background-image':
          'url(' + require('@/assets/img/contacts/banner.png') + ')'
      }"
    >
      <div class="texto-banner">
        <h2 class="text-white text-3xl font-bold">Contactos</h2>
        <p class="text-gray-100">Home / Contactos</p>
      </div>
    </div>

    <!-- CONTENT -->
    <section>
      <div class="container mt-12  px-4 md:px-20">
        <!-- DIRECTIONS -->
        <div class="grid grid-cols-2 pb-12">
          <div class="col-span-2 md:col-span-1 p-4 md:p-10 bg-white">
            <h5 class="text-gray-800 text-2xl">Oficinas</h5>
            <tab-c v-on:changeImg="updateImg" />
          </div>
          <div class="col-span-2 md:col-span-1">
            <img :src="img" alt="beneficios crediya" />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import TabC from "../components/TabC.vue";
export default {
  components: { TabC },
  name: "Contacts",
  data() {
    return {
      img: require("@/assets/img/contacts/oficina-ambato.png")
    };
  },
  methods: {
    updateImg(id) {
      console.log("desde el hijo", id);
      if (id == 1) {
        this.img = require("@/assets/img/contacts/oficina-ambato.png");
      } else if (id == 2) {
        this.img = require("@/assets/img/contacts/oficina-quito.jpg");
      } else if (id == 3) {
        this.img = require("@/assets/img/contacts/Agencia-Riobamba.jpg");
      } else if (id == 4) {
        this.img = require("@/assets/img/contacts/Agencia-Ibarra.jpg");
      } else if (id == 5) {
        this.img = require("@/assets/img/contacts/Agencia-Macas.jpg");
      } else if (id == 6) {
        this.img = require("@/assets/img/contacts/Agencia-Guayaquil.jpg");
      } else if (id == 7) {
        this.img = require("@/assets/img/contacts/oficina-quisapincha.jpg");
      } else if (id == 8) {
        this.img = require("@/assets/img/contacts/Agencia-Latacunga.jpg");
      } else if (id == 9) {
        this.img = require("@/assets/img/contacts/sangolqui.jpg");
      } else if (id == 10) {
        this.img = require("@/assets/img/contacts/izamba.jpg");
      } else if (id == 11) {
        this.img = require("@/assets/img/contacts/santo-domingo.jpg");
      } else if (id == 12) {
        this.img = require("@/assets/img/contacts/Huachi-Grande.jpg");
      }
    }
  }
};
</script>

<style scoped>
/* Banner */
#banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 300px;
  width: 100%;
  position: relative;
}
.texto-banner {
  position: absolute;
  left: 10%;
  top: 40%;
}
</style>
