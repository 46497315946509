import axios from "axios";
// import url from "./url";
import https from "https";

var url = "https://procesostres.crediya.fin.ec";

const instance = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  })
});

// SAVE credit
function saveQuejas(
  tipoidentification,
  identification,
  tipooperacion,
  landline,
  lastname,
  names,
  phone,
  email,
  province,
  parish,
  city,
  barrio,
  referencia,
  agencia,
  fincidente,
  ncuenta,
  tiporeclamo,
  detalle,
  descripText1,
  descripText2
) {
  return instance
    .post(`${url}/admin/postquejas`, {
      tipoidentification,
      identification,
      tipooperacion,
      landline,
      lastname,
      names,
      phone,
      email,
      province,
      parish,
      city,
      barrio,
      referencia,
      agencia,
      fincidente,
      ncuenta,
      tiporeclamo,
      detalle,
      descripText1,
      descripText2
    })
    .then(resp => resp.data);
}
function saveAhorros(
  tipoidentification,
  identification,
  tipooperacion,
  landline,
  lastname,
  names,
  phone,
  email,
  province,
  parish,
  city,
  barrio,
  referencia,
  agencia,
  fincidente,
  ncuenta,
  tiporeclamo,
  detalle,
  descripText1,
  descripText2
) {
  return instance
    .post(`${url}/admin/ahorros`, {
      tipoidentification,
      identification,
      tipooperacion,
      landline,
      lastname,
      names,
      phone,
      email,
      province,
      parish,
      city,
      barrio,
      referencia,
      agencia,
      fincidente,
      ncuenta,
      tiporeclamo,
      detalle,
      descripText1,
      descripText2
    })
    .then(resp => resp.data);
}

export default {
  saveQuejas,
  saveAhorros
};
