<template>
  <div class="relative" @mouseover="showMenu" @mouseleave="hideMenu">
    <a
      href="#"
      @click="toggleMenu"
      class="block mt-4 lg:inline-block lg:mt-0 text-gray-800  hover:text-gray-700  mr-4"
      >Productos</a
    >
    <div class="absolute ">&nbsp;</div>
    <transition name="mega-menu-fade">
      <div
        v-show="isVisible"
        class="mega-menu absolute normal-case font-normal rounded-sm text-white"
      >
        <div class="flex bg-white rounded p-3 shadow flex-col md:flex-row">
          <div class="w-full md:w-1/3">
            <h6 class="font-bold text-gray-800 ml-16">
              Créditos de Consumo
            </h6>
            <ul class="px-2 md:px-16">
              <li>
                <router-link
                  class="text-sm py-2  px-1 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-red-600 hover:text-white"
                  to="/producto/crediconsumo-ordinario"
                  >Ordinario</router-link
                >
              </li>
              <li>
                <router-link
                  class="text-sm py-2  px-1 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-red-600 hover:text-white"
                  to="/producto/crediconsumo-prioritario"
                  >Prioritario</router-link
                >
              </li>
              <li>
                <router-link
                  class="text-sm py-2  px-1 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-red-600 hover:text-white"
                  to="/producto/credipoliza"
                  >CrediPóliza</router-link
                >
              </li>
              <li>
                <router-link
                  class="text-sm py-2  px-1 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-red-600 hover:text-white"
                  to="/producto/credilaboral"
                  >Credi Ya laboral</router-link
                >
              </li>
              <li>
                <router-link
                  class="text-sm py-2  px-1 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-red-600 hover:text-white"
                  to="/producto/credimed-plus"
                  >CrediMed Plus</router-link
                >
              </li>
              <li>
                <router-link
                  class="text-sm py-2  px-1 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-red-600 hover:text-white"
                  to="/producto/credifarma-plus"
                  >CrediFarma Plus</router-link
                >
              </li>
            </ul>
          </div>
          <div class="w-full md:w-1/3">
            <h6 class="font-bold text-gray-800 ml-16">Microcrédito</h6>
            <ul class="px-2 md:px-16">
              <li>
                <router-link
                  class="text-sm py-2  px-1 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-red-600 hover:text-white"
                  to="/producto/micro-credito-ordinario"
                  >Ordinario</router-link
                >
              </li>
              <li>
                <router-link
                  class="text-sm py-2  px-1 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-red-600 hover:text-white"
                  to="/producto/credifarma-vip"
                  >CrediFarma Vip</router-link
                >
              </li>
              <li>
                <router-link
                  class="text-sm py-2  px-1 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-red-600 hover:text-white"
                  to="/producto/credimed-vip"
                  >CrediMed Vip</router-link
                >
              </li>
              <li>
                <router-link
                  class="text-sm py-2  px-1 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-red-600 hover:text-white"
                  to="/producto/credimanager"
                  >CrediManager</router-link
                >
              </li>
              <li>
                <router-link
                  class="text-sm py-2  px-1 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-red-600 hover:text-white"
                  to="/producto/crediagil"
                  >CrediAgil</router-link
                >
              </li>
              <!-- <li><router-link class="text-sm py-2  px-1 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-red-600 hover:text-white" to="#">Credil Yaiquidéz</router-link></li> -->
            </ul>
          </div>

          <!-- AHORROS -->
          <div class="w-full md:w-1/3">
            <h6 class="font-bold text-gray-800 ml-16">Ahorros</h6>
            <ul class="px-2 md:px-16">
              <li>
                <router-link
                  class="text-sm py-2  px-1 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-red-600 hover:text-white"
                  to="/producto/ahorro-vista"
                  >Ahorro a la vista</router-link
                >
              </li>
              <li>
                <router-link
                  class="text-sm py-2  px-1 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-red-600 hover:text-white"
                  to="/producto/ahorro-programado"
                  >Ahorro Programado</router-link
                >
              </li>
              <li>
                <router-link
                  class="text-sm py-2  px-1 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-red-600 hover:text-white"
                  to="/producto/ahorro-productivo"
                  >Ahorro Productivo</router-link
                >
              </li>
              <li>
                <router-link
                  class="text-sm py-2  px-1 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-red-600 hover:text-white"
                  to="/producto/ahorro-navideno"
                  >Ahorro Navideño</router-link
                >
              </li>
              <li>
                <router-link
                  class="text-sm py-2  px-1 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-red-600 hover:text-white"
                  to="/producto/ahorro-ardillita"
                  >Ahorro Ardillita</router-link
                >
              </li>
              <!-- <li>
                <router-link
                  class="text-sm py-2  px-1 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-red-600 hover:text-white"
                  to=""
                  >Ahorro Futuro</router-link
                >
              </li> -->
              <li>
                <router-link
                  class="text-sm py-2  px-1 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-red-600 hover:text-white"
                  to="/producto/ahorro-verano"
                  >Ahorro Verano</router-link
                >
              </li>
              <li>
                <router-link
                  class="text-sm py-2  px-1 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-red-600 hover:text-white"
                  to="/producto/ahorro-salud"
                  >Ahorro Salud</router-link
                >
              </li>
            </ul>
          </div>

          <div class="w-full md:w-1/3">
            <h6 class="font-bold text-gray-800 ml-16">Inversiones</h6>
            <ul class="px-2 md:px-16">
              <li>
                <router-link
                  class="text-sm py-2  px-1 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-red-600 hover:text-white"
                  to="/producto/inversion-gold"
                  >Inversión Gold</router-link
                >
              </li>
              <li>
                <router-link
                  class="text-sm py-2  px-1 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-red-600 hover:text-white"
                  to="/producto/inversion-silver"
                  >Inversión Silver</router-link
                >
              </li>
              <li>
                <router-link
                  class="text-sm py-2  px-1 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-red-600 hover:text-white"
                  to="/producto/inversion-platino"
                  >Inversión Platino</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "MegaMenu",
  data() {
    return {
      isVisible: false
    };
  },
  methods: {
    showMenu() {
      this.isVisible = true;
    },
    hideMenu() {
      this.isVisible = false;
    },
    toggleMenu() {
      this.isVisible = !this.isVisible;
    }
  }
};
</script>
<style scoped>
.mega-menu {
  width: 80vw;
  z-index: 2;
  top: 30px;
  left: -30vw;
  right: 0;
  padding: 15px;
  color: #000;
  /* transform: translateX(-30%); */
}
.mega-menu-fade-enter-active,
.mega-menu-fade-leave-active {
  transition: all 1s ease-in-out;
}
.mega-menu-fade-enter,
.mega-menu-fade-leave-to {
  opacity: 0;
  transform: translateY(-12px);
}
@media screen and (max-width: 690px) {
  .mega-menu {
    left: 0;
  }
}
</style>
