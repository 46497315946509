<template>
  <main style="background-color: #f5f5f5;" class="pb-10">
    <!-- BANNER -->
    <div
      id="banner"
      :style="{
        'background-image':
          'url(' + require('@/assets/img/services/convenios/banner.jpg') + ')'
      }"
    >
      <div class="texto-banner">
        <h2 class="text-white text-3xl font-bold">Convenios</h2>
        <p class="text-gray-100">Home / Convenios</p>
      </div>
    </div>

    <!-- CONTENT -->
    <section>
      <div class="titular mt-5">
        <h2 class="text-center text-3xl gris">CONVENIOS INSTITUCIONALES</h2>
        <p class="text-center mx-auto px-4 md:px-12  gris w-full md:w-7/12">
          Al formar parte de la Familia CrediYa, tienen un sin fin de
          beneficios. Nuestros Socios pueden hacer uso de nuestros convenios con
          instituciones de Salud, bienestar, casas comerciales, restaurantes en
          cualquier momento.
        </p>
      </div>
      <div class="container mt-12  px-4 md:px-20">
        <!-- CONVENIO 1 -->
        <div class="grid grid-cols-7 bg-white ">
          <div class="col-span-7 md:col-span-3">
            <img
              src="@/assets/img/services/logos/HampiLab.png"
              alt="beneficios crediya"
              class="w-2/4 mx-auto"
            />
          </div>
          <div class="col-span-7 md:col-span-4 p-4 bg-white ">
            <h4 class="gris text-2xl">Hampi Lab</h4>
            <ul class="list-disc px-5">
              <li class="text-gray-800 gris">
                Descuento en exámenes familiares en primer grado socios de la
                Cooperativa.
              </li>
              <li class="text-gray-800 gris">
                Descuento en exámenes de rutina.
              </li>
            </ul>
            <div class="flex mt-3">
              <span class="font-semibold ml-5 ">Beneficiarios:</span>
              <div class="container ml-4">
                <p>Socios- Clientes 20%</p>
                <p>Personal Institución 20%</p>
              </div>
            </div>
          </div>
        </div>

        <!-- CONVENIO 2 -->
        <div class="grid grid-cols-7 bg-white my-6">
          <!-- Col 1 -->
          <div
            class="col-span-7 md:col-span-4 p-4 bg-white md:pl-40 order-2 md:order-1"
          >
            <h4 class="gris text-2xl">Centro Médico Dr. Medina</h4>
            <ul class="list-disc px-5">
              <li class="text-gray-800 gris">
                Descuento en Consulta Médica.
              </li>
              <li class="text-gray-800 gris">
                Descuento en Laboratorio.
              </li>
              <li class="text-gray-800 gris">
                Descuento en Atención en Imagenología.
              </li>
            </ul>
            <div class="flex mt-3">
              <span class="font-semibold ml-5 ">Beneficiarios:</span>
              <div class="container ml-4">
                <p>Socios- Clientes 20%</p>
                <p>Personal Institución 20%</p>
              </div>
            </div>
          </div>
          <!-- Col 2 -->
          <div class="col-span-7 md:col-span-3 order-1 md:order-2">
            <img
              src="@/assets/img/services/logos/DrMedina.png"
              alt="beneficios crediya"
              class="w-2/4 mx-auto md:mx-0"
            />
          </div>
        </div>

        <!-- CONVENIO 3 -->
        <div class="grid grid-cols-7 bg-white ">
          <div class="col-span-7 md:col-span-3">
            <img
              src="@/assets/img/services/logos/FarmaciaHiperEconomica.png"
              alt="beneficios crediya"
              class="w-2/4 mx-auto"
            />
          </div>
          <div class="col-span-7 md:col-span-4 p-4 bg-white ">
            <h4 class="gris text-2xl">Farmacia Hipereconómica</h4>
            <ul class="list-disc px-5">
              <li class="text-gray-800 gris">
                Descuento del precio normal en todos los productos de la
                Farmacia.
              </li>
            </ul>
            <div class="flex mt-3">
              <span class="font-semibold ml-5 ">Beneficiarios:</span>
              <div class="container ml-4">
                <p>Socios- Clientes 10%</p>
                <p>Personal Institución 10%</p>
              </div>
            </div>
          </div>
        </div>

        <!-- CONVENIO 4 -->
        <div class="grid grid-cols-7 bg-white my-6">
          <!-- Col 1 -->
          <div
            class="col-span-7 md:col-span-4 p-4 bg-white md:pl-40 order-2 md:order-1"
          >
            <h4 class="gris text-2xl">Amada Nails SPA</h4>
            <ul class="list-disc px-5">
              <li class="text-gray-800 gris">
                Atención completa en nuestro servicio de Spa.
              </li>
            </ul>
            <div class="flex mt-3">
              <span class="font-semibold ml-5 ">Beneficiarios:</span>
              <div class="container ml-4">
                <p>Socios- Clientes 20%</p>
                <p>Personal Institución 20%</p>
              </div>
            </div>
          </div>
          <!-- Col 2 -->
          <div class="col-span-7 md:col-span-3 order-1 md:order-2">
            <img
              src="@/assets/img/services/logos/AmandaNailSpa.png"
              alt="beneficios crediya"
              class="w-2/4 mx-auto md:mx-0"
            />
          </div>
        </div>

        <!-- CONVENIO 5 -->
        <div class="grid grid-cols-7 bg-white ">
          <div class="col-span-7 md:col-span-3">
            <img
              src="@/assets/img/services/logos/OpticaLuque.png"
              alt="beneficios crediya"
              class="w-2/4 mx-auto"
            />
          </div>
          <div class="col-span-7 md:col-span-4 p-4 bg-white ">
            <h4 class="gris text-2xl">Optica Luque</h4>
            <ul class="list-disc px-5">
              <li class="text-gray-800 gris">
                Atención completa en el cuidado de su vista.
              </li>
            </ul>
            <div class="flex mt-3">
              <span class="font-semibold ml-5 ">Beneficiarios:</span>
              <div class="container ml-4">
                <p>Socios- Clientes 25%</p>
                <p>Personal Institución 30%</p>
              </div>
            </div>
          </div>
        </div>

        <!-- CONVENIO 6 -->
        <div class="grid grid-cols-7 bg-white my-6">
          <!-- Col 1 -->
          <div
            class="col-span-7 md:col-span-4 p-4 bg-white md:pl-40 order-2 md:order-1"
          >
            <h4 class="gris text-2xl">Llantandina</h4>
            <ul class="list-disc px-5">
              <li class="text-gray-800 gris">
                Compra de: aros para autos, camionetas, buses, camiones e
                incluso para equipo agrícolas, baterías de las mejores marcas.
              </li>
            </ul>
            <div class="flex mt-3">
              <span class="font-semibold ml-5 ">Beneficiarios:</span>
              <div class="container ml-4">
                <p>Socios- Clientes 5%</p>
                <p>Personal Institución 5%</p>
              </div>
            </div>
          </div>
          <!-- Col 2 -->
          <div class="col-span-7 md:col-span-3 order-1 md:order-2">
            <img
              src="@/assets/img/services/logos/Llantandina.png"
              alt="beneficios crediya"
              class="w-2/4 mx-auto md:mx-0"
            />
          </div>
        </div>

        <!-- CONVENIO 7 -->
        <div class="grid grid-cols-7 bg-white ">
          <div class="col-span-7 md:col-span-3">
            <img
              src="@/assets/img/services/logos/LubriSpeed.png"
              alt="beneficios crediya"
              class="w-2/4 mx-auto"
            />
          </div>
          <div class="col-span-7 md:col-span-4 p-4 bg-white ">
            <h4 class="gris text-2xl">Lubrispeed</h4>
            <ul class="list-disc px-5">
              <li class="text-gray-800 gris">
                Descuento del 20% en Lavado Express – Completo
              </li>
              <li class="text-gray-800 gris">
                Descuento del 10% en Cambio de Aceite
              </li>
              <li class="text-gray-800 gris">
                Descuento del 15 % en Mano de Obra en Mecánica Automotriz
              </li>
            </ul>
            <div class="flex mt-3">
              <span class="font-semibold ml-5 ">Beneficiarios:</span>
              <div class="container ml-4">
                <p>Socios- Clientes 10%</p>
                <p>Personal Institución 10%</p>
              </div>
            </div>
          </div>
        </div>

        <!-- CONVENIO 8 -->
        <div class="grid grid-cols-7 bg-white my-6">
          <!-- Col 1 -->
          <div
            class="col-span-7 md:col-span-4 p-4 bg-white md:pl-40 order-2 md:order-1"
          >
            <h4 class="gris text-2xl">Classic Boutique Cafetería Restaurant</h4>
            <ul class="list-disc px-5">
              <li class="text-gray-800 gris">
                Por la compra de tres almuerzos uno gratis.
              </li>
            </ul>
            <div class="flex mt-3">
              <span class="font-semibold ml-5 ">Beneficiarios:</span>
              <div class="container ml-4">
                <p>Socios- Clientes 10%</p>
                <p>Personal Institución 10%</p>
              </div>
            </div>
          </div>
          <!-- Col 2 -->
          <div class="col-span-7 md:col-span-3 order-1 md:order-2">
            <img
              src="@/assets/img/services/logos/ClassicCafe.png"
              alt="beneficios crediya"
              class="w-2/4 mx-auto md:mx-0"
            />
          </div>
        </div>

        <!-- CONVENIO 9 -->
        <div class="grid grid-cols-7 bg-white ">
          <div class="col-span-7 md:col-span-3">
            <img
              src="@/assets/img/services/logos/OpticaLuque.png"
              alt="beneficios crediya"
              class="w-2/4 mx-auto"
            />
          </div>
          <div class="col-span-7 md:col-span-4 p-4 bg-white ">
            <h4 class="gris text-2xl">Heladería Classic</h4>
            <ul class="list-disc px-5">
              <li class="text-gray-800 gris">
                Por la compra de tres helados uno gratis.
              </li>
            </ul>
            <div class="flex mt-3">
              <span class="font-semibold ml-5 ">Beneficiarios:</span>
              <div class="container ml-4">
                <p>Socios- Clientes 5%</p>
                <p>Personal Institución 5%</p>
              </div>
            </div>
          </div>
        </div>

        <!-- CONVENIO 10 -->
        <div class="grid grid-cols-7 bg-white my-6">
          <!-- Col 1 -->
          <div
            class="col-span-7 md:col-span-4 p-4 bg-white md:pl-40 order-2 md:order-1"
          >
            <h4 class="gris text-2xl">Farmacias Económicas</h4>
            <ul class="list-disc px-5">
              <li class="text-gray-800 gris">
                Se desarrollarán Campañas Médicas.
              </li>
            </ul>
            <div class="flex mt-3">
              <span class="font-semibold ml-5 ">Beneficiarios:</span>
              <div class="container ml-4">
                <p>Socios- Clientes 20%</p>
                <p>Personal Institución 20%</p>
              </div>
            </div>
          </div>
          <!-- Col 2 -->
          <div class="col-span-7 md:col-span-3 order-1 md:order-2">
            <img
              src="@/assets/img/services/logos/FarmaciasEconomicas.png"
              alt="beneficios crediya"
              class="w-2/4 mx-auto md:mx-0"
            />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "Convenios"
};
</script>

<style scoped>
/* Banner */
#banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 350px;
  width: 100%;
  position: relative;
}
.texto-banner {
  position: absolute;
  left: 10%;
  top: 30%;
}
.gris {
  color: #575757;
}
</style>
