<template>
  <div class="flex flex-wrap">
    <div class="w-full">
      <ul class="flex mb-0 list-none flex-wrap pt-3 flex-row tab-principal">
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a
            class="text-xs px-5 py-3 block leading-normal"
            v-on:click="toggleTabs(1)"
            v-bind:class="{
              'text-red-600 bg-white': openTab !== 1,
              'text-red-700 borde_per': openTab === 1
            }"
          >
            Ambato Matriz
          </a>
        </li>
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a
            class="text-xs px-5 py-3 block leading-normal"
            v-on:click="toggleTabs(2)"
            v-bind:class="{
              'text-red-600 bg-white': openTab !== 2,
              'text-red-700 borde_per': openTab === 2
            }"
          >
            Quito
          </a>
        </li>
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a
            class="text-xs px-5 py-3 block leading-normal"
            v-on:click="toggleTabs(3)"
            v-bind:class="{
              'text-red-600 bg-white': openTab !== 3,
              'text-red-700 borde_per': openTab === 3
            }"
          >
            Riobamba
          </a>
        </li>
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a
            class="text-xs px-5 py-3 block leading-normal"
            v-on:click="toggleTabs(4)"
            v-bind:class="{
              'text-red-600 bg-white': openTab !== 4,
              'text-red-700 borde_per': openTab === 4
            }"
          >
            Ibarra
          </a>
        </li>
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a
            class="text-xs px-5 py-3 block leading-normal"
            v-on:click="toggleTabs(5)"
            v-bind:class="{
              'text-red-600 bg-white': openTab !== 5,
              'text-red-700 borde_per': openTab === 5
            }"
          >
            Macas
          </a>
        </li>
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a
            class="text-xs px-5 py-3 block leading-normal"
            v-on:click="toggleTabs(6)"
            v-bind:class="{
              'text-red-600 bg-white': openTab !== 6,
              'text-red-700 borde_per': openTab === 6
            }"
          >
            Guayaquil
          </a>
        </li>
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a
            class="text-xs px-5 py-3 block leading-normal"
            v-on:click="toggleTabs(7)"
            v-bind:class="{
              'text-red-600 bg-white': openTab !== 7,
              'text-red-700 borde_per': openTab === 7
            }"
          >
            Quisapincha
          </a>
        </li>
        <!-- LATACUNGA -->
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a
            class="text-xs px-5 py-3 block leading-normal"
            v-on:click="toggleTabs(8)"
            v-bind:class="{
              'text-red-600 bg-white': openTab !== 8,
              'text-red-700 borde_per': openTab === 8
            }"
          >
            Latacunga
          </a>
        </li>
        <!-- SANGOLQUI -->
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a
            class="text-xs px-5 py-3 block leading-normal"
            v-on:click="toggleTabs(9)"
            v-bind:class="{
              'text-red-600 bg-white': openTab !== 9,
              'text-red-700 borde_per': openTab === 9
            }"
          >
            Sangolqui
          </a>
        </li>
        <!-- IZAMBA -->
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a
            class="text-xs px-5 py-3 block leading-normal"
            v-on:click="toggleTabs(10)"
            v-bind:class="{
              'text-red-600 bg-white': openTab !== 10,
              'text-red-700 borde_per': openTab === 10
            }"
          >
            Izamba
          </a>
        </li>
        <!-- SANTO DOMINGO -->
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a
            class="text-xs px-5 py-3 block leading-normal"
            v-on:click="toggleTabs(11)"
            v-bind:class="{
              'text-red-600 bg-white': openTab !== 11,
              'text-red-700 borde_per': openTab === 11
            }"
          >
            Santo Domingo
          </a>
        </li>
        <!-- huachi Grande -->
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a
            class="text-xs px-5 py-3 block leading-normal"
            v-on:click="toggleTabs(12)"
            v-bind:class="{
              'text-red-600 bg-white': openTab !== 12,
              'text-red-700 borde_per': openTab === 12
            }"
          >
            Huachi Grande
          </a>
        </li>
      </ul>
      <div
        class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6"
      >
        <div class="p-4 flex-auto">
          <div class="tab-content tab-space">
            <div v-bind:class="{ hidden: openTab !== 1, block: openTab === 1 }">
              <!-- OFICINA AMABTO -->
              <h4 class="text-gray-900 text-2xl mb-6">Ambato Matriz</h4>
              <span class="text-gray-900 font-bold">Dirección: </span>
              <span class="text-gray-700 font-normal"
                >Calle Bolivar entre Manuela Cañizares y Ayllón (Edificio Credi
                Ya)
              </span>
              <br />
              <span class="text-gray-900 font-bold">Teléfono: </span>
              <span class="text-gray-700 font-normal"
                >0995258404 / (03)2427433</span
              >
              <br />
              <span class="text-gray-900 font-bold">Correo: </span>
              <span class="text-gray-700 font-normal">info@crediya.fin.ec</span>
              <br />

              <iframe
                src="https://www.google.com/maps/d/embed?mid=1Xq4NuWphQf7roiWOn_FwBT_osYBCzmvw&ehbc=2E312F"
                style="border:0;"
                allowfullscreen=""
                loading="lazy"
                class="mt-5 w-full h-48"
              ></iframe>
            </div>

            <!-- OFICINA QUITO -->
            <div v-bind:class="{ hidden: openTab !== 2, block: openTab === 2 }">
              <h4 class="text-gray-900 text-2xl mb-6">Quito</h4>
              <span class="text-gray-900 font-bold">Dirección: </span>
              <span class="text-gray-700 font-normal"
                >Mariscal Sucre y Caranqui parroquia la Magdalena
              </span>
              <br />
              <span class="text-gray-900 font-bold">Teléfono: </span>
              <span class="text-gray-700 font-normal"
                >0997460582 / (02)3922162
              </span>
              <br />
              <span class="text-gray-900 font-bold">Correo: </span>
              <span class="text-gray-700 font-normal">info@crediya.fin.ec</span>
              <br />
              <iframe
                src="https://www.google.com/maps/d/embed?mid=1Xq4NuWphQf7roiWOn_FwBT_osYBCzmvw&ehbc=2E312F"
                style="border:0;"
                allowfullscreen=""
                loading="lazy"
                class="mt-5 w-full h-48"
              ></iframe>
            </div>
            <!-- OFICINA RIOBAMBA -->
            <div v-bind:class="{ hidden: openTab !== 3, block: openTab === 3 }">
              <h4 class="text-gray-900 text-2xl mb-6">Riobamba</h4>

              <span class="text-gray-900 font-bold">Dirección: </span>
              <span class="text-gray-700 font-normal"
                >Av. José Veloz y Carabobo Esq.
              </span>
              <br />
              <span class="text-gray-900 font-bold">Teléfono: </span>
              <span class="text-gray-700 font-normal"
                >0987634448 /(03) 299 4580
              </span>
              <br />
              <span class="text-gray-900 font-bold">Correo: </span>
              <span class="text-gray-700 font-normal">info@crediya.fin.ec</span>
              <br />
              <iframe
                src="https://www.google.com/maps/d/embed?mid=1Xq4NuWphQf7roiWOn_FwBT_osYBCzmvw&ehbc=2E312F"
                style="border:0;"
                allowfullscreen=""
                loading="lazy"
                class="mt-5 w-full h-48"
              ></iframe>
            </div>
            <!-- OFICINA IBARRA -->
            <div v-bind:class="{ hidden: openTab !== 4, block: openTab === 4 }">
              <h4 class="text-gray-900 text-2xl mb-6">Ibarra</h4>

              <span class="text-gray-900 font-bold">Dirección: </span>
              <span class="text-gray-700 font-normal"
                >Obispo Mosquera y Calixto Miranda Esq
              </span>
              <br />
              <span class="text-gray-900 font-bold">Teléfono: </span>
              <span class="text-gray-700 font-normal"
                >0986271908 / (03) 242 7433
              </span>
              <br />
              <span class="text-gray-900 font-bold">Correo: </span>
              <span class="text-gray-700 font-normal">info@crediya.fin.ec</span>
              <br />
              <iframe
                src="https://www.google.com/maps/d/embed?mid=1Xq4NuWphQf7roiWOn_FwBT_osYBCzmvw&ehbc=2E312F"
                style="border:0;"
                allowfullscreen=""
                loading="lazy"
                class="mt-5 w-full h-48"
              ></iframe>
            </div>
            <!-- OFICINA MACAS -->
            <div v-bind:class="{ hidden: openTab !== 5, block: openTab === 5 }">
              <h4 class="text-gray-900 text-2xl mb-6">Macas</h4>

              <span class="text-gray-900 font-bold">Dirección: </span>
              <span class="text-gray-700 font-normal"
                >Calle Bolivar entre Amazonas y Soasti
              </span>
              <br />
              <span class="text-gray-900 font-bold">Teléfono: </span>
              <span class="text-gray-700 font-normal"
                >0997195917 / (03)3928100</span
              >
              <br />
              <span class="text-gray-900 font-bold">Correo: </span>
              <span class="text-gray-700 font-normal">info@crediya.fin.ec</span>
              <br />
              <iframe
                src="https://www.google.com/maps/d/embed?mid=1Xq4NuWphQf7roiWOn_FwBT_osYBCzmvw&ehbc=2E312F"
                style="border:0;"
                allowfullscreen=""
                loading="lazy"
                class="mt-5 w-full h-48"
              ></iframe>
            </div>
            <!-- OFICINA GUAYAQUIL -->
            <div v-bind:class="{ hidden: openTab !== 6, block: openTab === 6 }">
              <h4 class="text-gray-900 text-2xl mb-6">Guayaquil</h4>

              <span class="text-gray-900 font-bold">Dirección: </span>
              <span class="text-gray-700 font-normal"
                >Av. Machala entre 9 de Octubre y Hurtado
              </span>
              <br />
              <span class="text-gray-900 font-bold">Teléfono: </span>
              <span class="text-gray-700 font-normal"
                >0994869980 / (04)3917375</span
              >
              <br />
              <span class="text-gray-900 font-bold">Correo: </span>
              <span class="text-gray-700 font-normal">info@crediya.fin.ec</span>
              <br />
              <iframe
                src="https://www.google.com/maps/d/embed?mid=1Xq4NuWphQf7roiWOn_FwBT_osYBCzmvw&ehbc=2E312F"
                style="border:0;"
                allowfullscreen=""
                loading="lazy"
                class="mt-5 w-full h-48"
              ></iframe>
            </div>
            <!-- OFICINA QUISAPINCHA -->
            <div v-bind:class="{ hidden: openTab !== 7, block: openTab === 7 }">
              <h4 class="text-gray-900 text-2xl mb-6">Quisapincha</h4>

              <span class="text-gray-900 font-bold">Dirección: </span>
              <span class="text-gray-700 font-normal"
                >García Moreno y Sucre Esq. (Plaza Central)
              </span>
              <br />
              <span class="text-gray-900 font-bold">Teléfono: </span>
              <span class="text-gray-700 font-normal"
                >0993579085 / (032)2772926</span
              >
              <br />
              <span class="text-gray-900 font-bold">Correo: </span>
              <span class="text-gray-700 font-normal">info@crediya.fin.ec</span>
              <br />

              <iframe
                src="https://www.google.com/maps/d/embed?mid=1Xq4NuWphQf7roiWOn_FwBT_osYBCzmvw&ehbc=2E312F"
                style="border:0;"
                allowfullscreen=""
                loading="lazy"
                class="mt-5 w-full h-48"
              ></iframe>
            </div>

            <!-- OFICINA LATACUNGA -->
            <div v-bind:class="{ hidden: openTab !== 8, block: openTab === 8 }">
              <h4 class="text-gray-900 text-2xl mb-6">Latacunga</h4>

              <span class="text-gray-900 font-bold">Dirección: </span>
              <span class="text-gray-700 font-normal"
                >Av. Amazonas y Calixto Pino Esq.
              </span>
              <br />
              <span class="text-gray-900 font-bold">Teléfono: </span>
              <span class="text-gray-700 font-normal"
                >0988501278 / (03)2427433</span
              >
              <br />
              <span class="text-gray-900 font-bold">Correo: </span>
              <span class="text-gray-700 font-normal">info@crediya.fin.ec</span>
              <br />

              <iframe
                src="https://www.google.com/maps/d/embed?mid=1Xq4NuWphQf7roiWOn_FwBT_osYBCzmvw&ehbc=2E312F"
                style="border:0;"
                allowfullscreen=""
                loading="lazy"
                class="mt-5 w-full h-48"
              ></iframe>
            </div>
            <!-- OFICINA SANGOLQUI -->
            <div v-bind:class="{ hidden: openTab !== 9, block: openTab === 9 }">
              <h4 class="text-gray-900 text-2xl mb-6">Sangolqui</h4>

              <span class="text-gray-900 font-bold">Dirección: </span>
              <span class="text-gray-700 font-normal"
                >Av. Luis Cordero y calle Pichincha
              </span>
              <br />
              <span class="text-gray-900 font-bold">Teléfono: </span>
              <span class="text-gray-700 font-normal"
                >0990428873 / (03)2427433</span
              >
              <br />
              <span class="text-gray-900 font-bold">Correo: </span>
              <span class="text-gray-700 font-normal">info@crediya.fin.ec</span>
              <br />
              <iframe
                src="https://www.google.com/maps/d/embed?mid=1Xq4NuWphQf7roiWOn_FwBT_osYBCzmvw&ehbc=2E312F"
                style="border:0;"
                allowfullscreen=""
                loading="lazy"
                class="mt-5 w-full h-48"
              ></iframe>
            </div>
            <!-- OFICINA IZAMBA -->
            <div
              v-bind:class="{ hidden: openTab !== 10, block: openTab === 10 }"
            >
              <h4 class="text-gray-900 text-2xl mb-6">Izamba</h4>

              <span class="text-gray-900 font-bold">Dirección: </span>
              <span class="text-gray-700 font-normal"
                >Av. Indoamérica y Pedro Vásconez
              </span>
              <br />
              <span class="text-gray-900 font-bold">Teléfono: </span>
              <span class="text-gray-700 font-normal"
                >0988396068 / (03)2427433</span
              >
              <br />
              <span class="text-gray-900 font-bold">Correo: </span>
              <span class="text-gray-700 font-normal">info@crediya.fin.ec</span>
              <br />

              <iframe
                src="https://www.google.com/maps/d/embed?mid=1Xq4NuWphQf7roiWOn_FwBT_osYBCzmvw&ehbc=2E312F"
                style="border:0;"
                allowfullscreen=""
                loading="lazy"
                class="mt-5 w-full h-48"
              ></iframe>
            </div>
            <!-- OFICINA SANTO DOMINGO -->
            <div
              v-bind:class="{ hidden: openTab !== 11, block: openTab === 11 }"
            >
              <h4 class="text-gray-900 text-2xl mb-6">Santo Domingo</h4>

              <span class="text-gray-900 font-bold">Dirección: </span>
              <span class="text-gray-700 font-normal"
                >Av. De los Tsáchilas y Machala
              </span>
              <br />
              <span class="text-gray-900 font-bold">Teléfono: </span>
              <span class="text-gray-700 font-normal"
                >0985564617 / (03)2427433</span
              >
              <br />
              <span class="text-gray-900 font-bold">Correo: </span>
              <span class="text-gray-700 font-normal">info@crediya.fin.ec</span>
              <br />

              <iframe
                src="https://www.google.com/maps/d/embed?mid=1Xq4NuWphQf7roiWOn_FwBT_osYBCzmvw&ehbc=2E312F"
                style="border:0;"
                allowfullscreen=""
                loading="lazy"
                class="mt-5 w-full h-48"
              ></iframe>
            </div>
            <!-- OFICINA HUACHI GRANDE -->
            <div
              v-bind:class="{ hidden: openTab !== 12, block: openTab === 12 }"
            >
              <h4 class="text-gray-900 text-2xl mb-6">Huachi Grande</h4>

              <span class="text-gray-900 font-bold">Dirección: </span>
              <span class="text-gray-700 font-normal"
                >Av. Atahualpa y Alaska
              </span>
              <br />
              <span class="text-gray-900 font-bold">Teléfono: </span>
              <span class="text-gray-700 font-normal"
                >0980234055 / (03)2427433</span
              >
              <br />
              <span class="text-gray-900 font-bold">Correo: </span>
              <span class="text-gray-700 font-normal">info@crediya.fin.ec</span>
              <br />

              <iframe
                src="https://www.google.com/maps/d/embed?mid=1Xq4NuWphQf7roiWOn_FwBT_osYBCzmvw&ehbc=2E312F"
                style="border:0;"
                allowfullscreen=""
                loading="lazy"
                class="mt-5 w-full h-48"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "blue-tabs",
  data() {
    return {
      openTab: 1,
      imgOffice: "@/assets/img/contacts/oficina-ambato.png"
    };
  },
  methods: {
    toggleTabs: function(tabNumber) {
      this.openTab = tabNumber;
      this.$emit("changeImg", tabNumber);
    }
  }
};
</script>
<style scoped>
/* tabs */
.tab-principal > li > a {
  color: #cd1e29;
  cursor: pointer;
}
.tab-principal {
  border-bottom: 1px solid #cd1e29;
}
.borde_per {
  border-radius: 0;
  border-bottom: 3px solid #cd1e29;
  font-weight: bolder;
}
</style>
