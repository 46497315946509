<template>
  <main style="background-color: #f5f5f5;">
    <!-- BANNER -->
    <div
      id="banner"
      :style="{
        'background-image':
          'url(' + require('@/assets/img/responsability/banner1.jpg') + ')'
      }"
    >
      <div class="texto-banner">
        <h2 class="text-white text-3xl font-bold">Protección de Datos</h2>
        <p class="text-gray-100">Home / Politicas</p>
      </div>
    </div>

    <!-- CONTENT -->
    <section>
      <div class="container mt-12  px-4 md:px-20">
        <!-- DESCRIPTION -->
        <div class="grid grid-cols-2 mb-12">
          <div
            class="col-span-2 md:col-span-2 mb-2"
            data-aos="fade-down"
            data-aos-duration="1000"
          >
            <p class="gris font-semibold text-2xl">
              1. OBJETIVO GENERAL
            </p>
            <p class="gris text-justify mb-5">
              Definir las políticas para la protección y tratamiento de los
              datos personales que son recolectados, almacenados en los
              diferentes procesos de la Cooperativa, de acuerdo a las normas de
              la Constitución de la República y a la Ley Orgánica de Protección
              de datos Personales; así también, determinar mecanismos para la
              difusión o socialización a los titulares de los datos personales.
            </p>
            <p class="gris font-semibold text-2xl">
              2. BASE LEGAL
            </p>
            <p class="gris text-justify mb-5">
              Constitución de la República del Ecuador, artículo 66: «El derecho
              a la protección de datos de carácter personal, que incluye el
              acceso y la decisión sobre información y datos de este carácter,
              así como su correspondiente protección. La recolección, archivo,
              procesamiento, distribución o difusión de estos datos o
              información requerirán la autorización del titular o el mandato de
              la ley»
            </p>

            <p class="gris font-semibold text-2xl">
              3. RECOLECCIÓN DE DATOS PERSONALES
            </p>
            <p class="gris text-justify mb-5">
              La Cooperativa actuando en calidad de responsable del Tratamiento
              de Datos Personales, para la adecuada prestación de sus productos
              y servicios, dentro del marco constitucional y la ley, recolectará
              la información de los, socios/clientes, y visitantes para lo cual
              debe contar con el respectivo consentimiento explícito o
              autorización para el tratamiento de los datos personales. Los
              datos recolectados que se darán la protección necesaria son:
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              a) Datos de identificación.
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              b) Datos de contacto.
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              c) Datos laborales.
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              d) Datos financieros.
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              e) Datos biométricos.
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              f) Datos de género.
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              g) Datos Médicos.
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              h) Datos de las cookies
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              i) Etnia.
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              j) Religión.
            </p>

            <p class="gris font-semibold text-2xl" style="margin-left: 7%;">
              3.1 Recolección de datos de menores de edad
            </p>
            <p class="gris text-justify mb-5" style="margin-left: 5%;">
              La Cooperativa podrá recolectar datos de niños, niñas y
              adolescentes en los siguientes casos:
            </p>
            <p class="gris text-justify mb-5" style="margin-left: 5%;">
              a) Cuando oferte productos y servicios dirigido para niños, niñas
              y adolescentes, mismo que no deberán afectar al interés superior,
            </p>
            <p class="gris text-justify mb-5" style="margin-left: 5%;">
              b) Cuando exista consentimiento y/o autorización sea por parte del
              representante legal o persona que tenga la patria potestad del
              menor
            </p>
            <p class="gris text-justify mb-5">
              La Cooperativa de Ahorro y Crédito Credi Ya Ltda, cuenta con los
              siguientes canales de informacion.
            </p>
            <p class="gris text-justify mb-5" style="margin-left: 5%;">
              <b> • SITIO WEB TRANSACCIONA</b>
            </p>
            <p
              class="gris text-justify mb-5"
              style="margin-left: 6%; margin-top: -2%;"
            >
              https://virtual.crediya.fin.ec
            </p>
            <p class="gris text-justify mb-5" style="margin-left: 5%;">
              <b> • PAGINA WEB INSTITUCIONAL</b>
            </p>
            <p
              class="gris text-justify mb-5"
              style="margin-left: 6%; margin-top: -2%;"
            >
              • https://crediya.fin.ec
            </p>
            <p class="gris text-justify mb-5" style="margin-left: 5%;">
              <b> • APLICACIONES MÓVILES</b>
            </p>
            <p
              class="gris text-justify mb-5"
              style="margin-left: 6%; margin-top: -2%;"
            >
              Credi Ya Móvil (iOS y Android)
            </p>

            <p class="gris font-semibold text-2xl">
              4. FINALIDAD DEL TRATAMIENTO DE DATOS
            </p>
            <p class="gris text-justify mb-5">
              La Cooperativa utilizará y compartirá la información personal de
              los clientes para procesos relacionados al giro del negocio y con
              instituciones públicas competentes autorizadas por la ley, para
              los siguientes fines:
            </p>

            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              a) Para envió de correos electrónicos con información acerca de
              los productos y/o servicios de la Cooperativa.
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              b) Para fines de mercadeo de los productos y/o servicios que
              mantenga la institución.
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              c) Para compartir los datos con las empresas o personas encargadas
              de la cobranza de cartera y proveedores de servicios
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              d) Para suministrar los datos a las autoridades de control para
              llevar a cabo procesos de auditoría interna o externa, a la
              policía, judiciales y/o administrativas, en virtud de un
              requerimiento legal y/o usar o revelar esa información y datos
              personales en defensa de los derechos de la Cooperativa y/o su
              patrimonio
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              e) Para contactar telefónicamente y realizar encuestas,
              promociones, campañas, estudios y/o confirmación de datos
              personales necesarios para la ejecución de una relación
              contractual
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              f) Para contactar al Titular a través de medios electrónicos - SMS
              o chat para el envío de notificaciones de pago y/o noticias
              relacionadas con campañas de fidelización y mejora de servicio.
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              g) Para reportar a los entes de control el estado de la cartera
              con relación a los productos adquiridos con la Cooperativa.
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              h) Para permitir el acceso a la información a los entes de control
              para llevar a cabo procesos de auditoría interna o externa.
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              i) Para efectuar encuestas de satisfacción respecto de los bienes
              y servicios ofrecidos por la Cooperativa
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              j) Para mantener vigente los datos personales de los titulares.
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              k) Para realizar invitaciones a eventos y ofrecer nuevos productos
              y/o servicios,.
            </p>
            <p class="gris text-justify mb-2" style="margin-left: 5%;">
              i) Transferir los datos personales a un eventual comprador de una
              o varias de las operaciones de crédito
            </p>

            <p class="gris text-justify mb-5">
              Suministrar los datos recolectados a las autoridades de control y
              vigilancia, de la policía, judiciales y/o administrativas, en
              virtud de un requerimiento legal y/o usar o revelar esa
              información y datos personales en defensa de los derechos de la
              Cooperativa y/o su patrimonio, en cuanto la referida defensa tenga
              relación con los productos y servicios contratados.
            </p>
            <p class="gris font-semibold text-2xl" style="margin-left: 7%;">
              4.1 Tratamiento y protección de datos para el uso de cookies
            </p>
            <p class="gris text-justify mb-5" style="margin-left: 5%;">
              La información de los Cookies recolectada mediante la página web,
              canales electrónicos se utilizarán en:
            </p>
            <p class="gris text-justify mb-5" style="margin-left: 5%;">
              a) Permitir que los sitios web recuerden información sobre la
              visita.
            </p>
            <p class="gris text-justify mb-5" style="margin-left: 5%;">
              b) Agilizar el reingreso de forma más rápida a los sitios y hacer
              que estos resulten más útiles.
            </p>

            <p class="gris font-semibold text-2xl">
              5. DERECHOS DEL TITULAR DE LA INFORMACIÓN
            </p>
            <p class="gris text-justify mb-5">
              El titular tiene derecho a:
            </p>
            <p class="gris text-justify mb-5" style="margin-left: 5%;">
              a) Reclamar, conocer, actualizar, rectificar, suprimir, eliminar
              y/o inactivar los datos personales frente a la Cooperativa. Este
              derecho se podrá ejercer, entre otros frente a datos parciales,
              inexactos, incompletos, fraccionados, que induzcan a error, o
              aquellos cuyo tratamiento esté expresamente prohibido o no haya
              sido autorizado.
            </p>
            <p class="gris text-justify mb-5" style="margin-left: 5%;">
              b) Revocación de la autorización, solicitar la supresión,
              eliminación y/o Inactivación de datos cuando en el tratamiento no
              respete los principios, derechos y garantías constitucionales y
              legales, siempre que no exista un deber legal o contractual que
              impida eliminarlos.
            </p>

            <p class="gris text-justify mb-5">
              Para hacer uso de sus derechos el titulas pues hacer uso de los
              siguientes canales:
            </p>

            <p class="gris text-justify mb-5" style="margin-left: 5%;">
              - Enviando una solicitud de manera directa al correo
              <a href="seguridaddelainformacion@crediya.fin.ec"
                ><u>seguridaddelainformacion@crediya.fin.ec</u></a
              >
            </p>
            <p class="gris text-justify mb-5" style="margin-left: 5%;">
              - En la página web institucional en el apartado Reclamos/Quejas y
              Reclamos.
            </p>
            <p class="gris text-justify mb-5" style="margin-left: 5%;">
              - En nuestra agencia Credi Ya Matriz en la ciudad de Ambato.
            </p>

            <p class="gris font-semibold text-2xl">
              6. RESPONSABILIDADES DEL TITULAR DE LA INFORMACION
            </p>
            <p class="gris font-semibold text-2xl" style="margin-left: 7%;">
              6.1 Responsabilidad de los titulares de la información
            </p>
            <p class="gris text-justify mb-5">
              Los Titulares tendrán la responsabilidad de informar sobre los
              cambios que se realicen en sus datos personales para que la
              Cooperativa se asegure que la información de la que dispone está
              actualizada y, en consecuencia, modificará o eliminará los datos
              personales de acuerdo a la actualización que realice los
              titulares.
            </p>

            <p class="gris font-semibold text-2xl">
              7. TIEMPO DE CONSERVACIÓN Y DESTRUCCIÓN DE LA INFORMACIÓN
            </p>
            <p class="gris text-justify mb-5">
              Los datos personales serán conservados durante un tiempo no mayor
              al necesario para cumplir con la finalidad de su tratamiento, más
              sin embargo la Cooperativa mantendrá obligatoriamente, sus
              archivos contables físicos, incluyendo los respaldos respectivos,
              por el plazo de diez (10) años contados a partir de la conclusión
              de la operación correspondiente, y por quince años en el formato
              digital autorizado por la Superintendencia de Economía Popular y
              Solidaria
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- PROJECT 11 -->
    <section class="bg-red-600 pl-4 md:pl-20 pt-4 md:pt-16 pb-5">
      <div class="grid grid-cols-5 mx-auto px-4 md:px-32 bg-white">
        <!-- col 1 -->

        <!-- col 2 -->
        <div
          class="col-span-5 md:col-span-3 p-4 md:p-10"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <light-box />
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import LightBox from "../../components/howWeAre/LightBox.vue";

export default {
  name: "TerminosApp",
  components: {
    LightBox
  }
};
</script>

<style scoped>
/* Banner */
#banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 350px;
  width: 100%;
  position: relative;
}

.texto-banner {
  position: absolute;
  left: 10%;
  top: 40%;
}
.texto-principal {
  position: absolute;
  left: 10%;
  top: 40%;
}
.texto-principal-right {
  position: absolute;
  right: 10%;
  top: 40%;
}
</style>
