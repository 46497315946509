<template>
  <main style="background-color: #f5f5f5;" class="pb-10">
    <!-- BANNER -->
    <div
      id="banner"
      :style="{
        'background-image':
          'url(' +
          require('@/assets/img/educacionfinanciera/bannereducacion.png') +
          ')'
      }"
    >
      <div class="texto-banner">
        <h2 class="text-white text-3xl font-bold">Educación Financiera</h2>
        <p class="text-gray-100">Educación Financiera</p>
      </div>
    </div>

    <!-- CONTENT -->
    <section>
      <div class="titular mt-5" style="text-align: center;">
        <br />
        <div
          class="blanco block mt-4 lg:inline-block lg:mt-0 rojo text-white   mr-4 pl-4 pr-4"
          style="height: 24px; border-radius: 10px; "
        >
          <a
            href="https://campus.rfd.org.ec/course/index.php?categoryid=39"
            target="_blank"
            ><b>Ingresar Aula Virtual</b></a
          >
        </div>
        <b
          ><h2 class="text-center text-3xl red" style="color:#cd1e29">
            PROGRAMA 1. EducaciónFinanciera CREDIYA - RFD
          </h2></b
        >
        <h2 class="text-center text-3xl gris">
          ¿Porqué Certificarme en Educación Financiera?
        </h2>
      </div>
      <div class="container mt-12  px-4 md:px-20">
        <!-- CONVENIO 1 -->
        <div class="grid grid-cols-12 bg-white ">
          <div class="col-span-7 md:col-span-4">
            <img
              src="@/assets/img/educacionfinanciera/Icono1.png"
              alt="beneficios crediya"
              class="w-2/4 mx-auto"
            />
            <!--<span class="font-semibold ml-5 ">Beneficiarios:</span>-->
            <div class="container ml-4">
              <p>Para que consigas tener unas finazas sanas</p>
              <p>y le saques el mayor provecho a tu dinero</p>
            </div>
          </div>
          <div class="col-span-7 md:col-span-4">
            <img
              src="@/assets/img/educacionfinanciera/Icono2.png"
              alt="beneficios crediya"
              class="w-2/4 mx-auto"
            />
            <span class="font-semibold ml-5 ">Aprender sobre:</span>
            <div class="container ml-4">
              <p>• La diferencia entre ahorror e invertir</p>
              <p>• Administrar de manera óptima tus dinanzas</p>
              <p>• Evaluar los pros y con de cada una de tus transacciones</p>
              <p>• Tomarás mejores decisiones sobre en que invertir</p>
              <p>• Llegar a fin de mes con dinero mediante</p>
            </div>
          </div>
          <div class="col-span-7 md:col-span-4">
            <img
              src="@/assets/img/educacionfinanciera/Icono3.png"
              alt="beneficios crediya"
              class="w-2/4 mx-auto"
            />
            <div class="container ml-4">
              <p>
                Si te capacitas continuamente mejora significativamente tu
                futuro y el de tu familia
              </p>
            </div>
          </div>
        </div>
        <br />

        <div style="text-align: center;">
          <div
            class="blanco block mt-4 lg:inline-block lg:mt-0 plomo text-white   mr-4 pl-4 pr-4"
            style="height: 24px; border-radius: 10px; "
          >
            <a
              href="https://campus.rfd.org.ec/course/index.php?categoryid=39"
              target="_blank"
              ><b>Certicate Gratuitamente - Click aquí</b></a
            >
          </div>
        </div>
        <br />
        <!-- CONVENIO 2 -->
        <div class="grid grid-cols-12 bg-white ">
          <div class="col-span-7 md:col-span-6">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/mRibQvlKU5E"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
            <span class="font-semibold ml-5 ">Pasos para registrarse:</span>
            <div class="container ml-4">
              <p>
                • Realizar Click en Quiero Certificarme GRATIS en educacion
                Financiera.
              </p>
              <p>
                • Ingresa toda tu información en el formulario, pues esta
                servira para obtener tu certificado
              </p>
              <p>
                • Además, asegúrate de que esté correctamente escritos, pues
                serán sus identicativos en el curso
              </p>
              <p>
                • Recuerda Guardar muy bien tus credenciales(Usuario y
                Contraseña) para ingresar al aula virtual
              </p>
            </div>
          </div>
          <div class="col-span-7 md:col-span-6">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/wm1gEP6DhzQ"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
            <span class="font-semibold ml-5 "
              >Pasos para ingresar al Aula Virtual:</span
            >
            <div class="container ml-4">
              <p>
                • En la parte superior encontrarás el Botón de Aula Virtual
                Credi Ya ingresa
              </p>
              <p>
                • Una vez que entraste a nuestra Paágina del Aula Virtual,
                Ingresa tus credenciales(Usuario y Contraseña) para acceder a
                nuestras clases
              </p>
              <p>
                • Sácale el máximo provecho al curso de Educación Financiera
              </p>
            </div>
          </div>
        </div>
        <br />
        <div style="text-align: center;">
          <h3 class="text-center text-3xl red">
            RECUERDA: Debes dinalizar todos los módulos para obtener tu
            certificado
          </h3>
          <div
            class="blanco block mt-4 lg:inline-block lg:mt-0 plomo text-white   mr-4 pl-4 pr-4"
            style="height: 24px; border-radius: 10px; "
          >
            <a
              href="https://campus.rfd.org.ec/course/index.php?categoryid=39"
              target="_blank"
              ><b>Certicate Gratuitamente - Click aquí</b></a
            >
          </div>
          <b
            ><h2 class="text-center text-3xl red" style="color:#cd1e29">
              PROGRAMA 2. Educación Financiera COSEDE
            </h2></b
          >

          <br />
          <!--INICIO DE INFORMACION-->
          <div class="grid grid-cols-12 bg-white ">
            <div class="col-span-7 md:col-span-6">
              <img
                src="@/assets/img/educacionfinanciera/logoeducate.png"
                alt="beneficios crediya"
                class="w-2/4 mx-auto"
              />
            </div>
            <div class="col-span-7 md:col-span-6">
              <img
                src="@/assets/img/educacionfinanciera/cosede.png"
                alt="beneficios crediya"
                class="w-2/4 mx-auto"
              />
            </div>
          </div>

          <br />
          <div class="grid grid-cols-12 bg-white ">
            <div class="col-span-7 md:col-span-12">
              <p>
                Bienvenidos y bienvenidas a la plataforma de E-learning de la
                Corporación del Seguro de Depósitos, Fondo de Liquidez y Fondo
                de Seguros Privados. En esta herramienta podrá encontrar
                diferentes cursos y programas de capacitación, que tiene como
                finalidad ofrecerles una verdadera experiencia de aprendizaje en
                distintas temáticas relacionadas con el seguro de Depósito,
                Fondo de Liquidez, Fondo de Seguros Privados, inducción para
                funcionarios de la COSEDE
              </p>
            </div>
            <br />
          </div>

          <!--PARRAFO SOLO-->
          <div class="grid grid-cols-12 bg-white ">
            <div class="col-span-7 md:col-span-6">
              <img
                src="@/assets/img/educacionfinanciera/ircurso.png"
                alt="beneficios crediya"
                class="w-2/4 mx-auto"
              />
              <br />
              <div
                class="blanco block mt-4 lg:inline-block lg:mt-0 rojo text-white   mr-4 pl-4 pr-4"
                style="height: 24px; border-radius: 10px; "
              >
                <a href="https://educate.cosede.gob.ec/" target="_blank"
                  ><b>Ir a Cursos COSEDES</b></a
                >
              </div>
            </div>
            <div class="col-span-7 md:col-span-6">
              <img
                src="@/assets/img/educacionfinanciera/registrate.png"
                alt="beneficios crediya"
                class="w-2/4 mx-auto"
              />
              <br />
              <div
                class="blanco block mt-4 lg:inline-block lg:mt-0 rojo text-white   mr-4 pl-4 pr-4"
                style="height: 24px; border-radius: 10px; "
              >
                <a
                  href="https://educate.cosede.gob.ec/login/index.php"
                  target="_blank"
                  ><b>Registrarme en Cursos COSEDES</b></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "Convenios"
};
</script>

<style scoped>
/* Banner */
#banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 350px;
  width: 100%;
  position: relative;
}
.texto-banner {
  position: absolute;
  left: 10%;
  top: 30%;
}
.gris {
  color: #575757;
}

.blanco {
  background-color: white;
}
.rojo {
  background-color: #cd1e29;
}
.plomo {
  background-color: #808080;
}
.top-header {
  background-color: #cd1e29;
}
.top-header p,
.top-header a {
  color: #f6e6e6;
}

.top-header .contenedor-info p {
  font-size: 0.85rem;
  margin: 10px 30px;
}
.top-header .contenedor-redes p {
  font-size: 0.85rem;
  margin: 10px 20px;
}
@media screen and (max-width: 690px) {
  .top-header p {
    margin: 5px;
  }
  .contenedor-info {
    justify-content: space-around;
  }
  .contenedor-redes {
    display: flex;
    justify-content: center;
    margin-left: 1rem;
    margin-bottom: 10px;
  }
}
</style>
