<template>
  <div>
    <!-- LIGHTBOX -->
    <CoolLightBox :items="items" :index="index" @close="index = null">
    </CoolLightBox>

    <div class="images-wrapper flex">
      <div
        class="image m-2"
        style="width:80px; height:80px; background-size:cover; cursor:pointer;"
        v-for="(image, imageIndex) in items"
        :key="imageIndex"
        @click="index = imageIndex"
        :style="{ backgroundImage: 'url(' + image + ')' }"
      ></div>
    </div>
    <!-- FIN LIGHTBOX -->
  </div>
</template>

<script>
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
  name: "LightBox",
  components: {
    CoolLightBox
  },
  props: ["img1", "img2", "img3"],
  data() {
    return {
      items: [
        require(`@/assets/img/responsability${this.img1}`),
        require(`@/assets/img/responsability${this.img2}`),
        require(`@/assets/img/responsability${this.img3}`)
      ],
      index: null
    };
  }
};
</script>
