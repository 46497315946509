<template>
  <div class="flex justify-center align-center">
    <label for="email">Email</label>
    <input
      type="email"
      id="email"
      v-model="email"
      placeholder="Ingresa tu email"
    />

    <label for="password">Contraseña</label>
    <input
      type="password"
      id="password"
      v-model="password"
      placeholder="Ingresa tu contraseña"
    />

    <button type="button" class="btn" @click="login">Ingresar</button>
  </div>
</template>

<script>
import api from "@/api/user";
export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: ""
    };
  },
  methods: {
    login() {
      api.postLogin(this.email, this.password).then(resp => {
        // console.log(resp);
        if (resp.status == "ok") {
          this.$store.dispatch("saveToken", resp.token);
          // var user = {
          //   email: resp.email,
          //   id: resp.id,
          //   token: resp.token,
          //   type: resp.type
          // }
          // this.$store.dispatch("user", user);
          this.$router.push({ name: "nuevo-producto" });
        }
      });
    }
  }
};
</script>
