export const menus = [
  {
    menu: "NACIONAL",
    submenus: [
      {
        submenu: "Cargos por servicios financieros básicos"
      },
      {
        submenu:
          "Servicios financieros con cargos máximos cuyo cobro excede el límite establecido en la norma"
      },
      {
        submenu:
          "Servicios financieros con cargos diferenciado cuyo cobro excede el valor aprobado"
      },
      {
        submenu:
          "Cargos en servicios financieros* que exceden los valores facturados por el prestador del servicio"
      },
      {
        submenu: "Cobros por servicios no financieros* que no estén autorizados"
      },
      {
        submenu: "Comisiones o cargos no autorizados en operaciones de crédito"
      },
      {
        submenu: "Crédito no autorizado"
      },
      {
        submenu:
          "Falta de registro en pagos de la operación por parte de la entidad"
      },
      {
        submenu:
          "Imposición de castigos por pagos anticipados o negativa a recibir pagos anticipados"
      },
      {
        submenu: "Anatocismo"
      },
      {
        submenu:
          "Cobros por intereses no devengados o superiores a los máximos vigentes"
      },
      {
        submenu: "Cargos por servicios financieros básicos"
      },
      {
        submenu:
          "Servicios financieros con cargos máximos cuyo cobro excede el limite establecido en la norma"
      },
      {
        submenu:
          "Servicios financieros con cargos máximos cuyo cobro excede el valor aprobado"
      },
      {
        submenu: "Cobro por consumos no autorizados con tarjeta de débito"
      },
      {
        submenu:
          "Cargos en servicios no financieros* que exceden los valores facturados por el prestador del servicio"
      },
      {
        submenu: "Cobro por servicios no financieros* que no estén autorizados"
      },
      {
        submenu:
          "Descuentos no autorizados en capital o intereses de certificados de depósito a plazo fijo "
      },
      {
        submenu: "Cargos por servicios financieros básicos"
      },
      {
        submenu:
          "Servicios financieros con cargos máximos cuyo cobro excede el limite establecido en la norma"
      },
      {
        submenu:
          "Servicios financieros con cargos diferenciado cuto cobro excede el valor aprobado"
      },
      {
        submenu: "Cobros por consumos no autorizados con tarjetas de crédito"
      },
      {
        submenu:
          "Cargos en servicios no financieros* que exceden los valores facturados por el prestador del servicio"
      },
      {
        submenu: "Emisión de tarjeta de crédito no autorizada"
      },
      {
        submenu: "Solicitud de bloqueo no realizada por la entidad"
      }
    ]

    /*
    menu: "Apertura de cuentas",
    submenus: [
      {
        submenu: "Cuenta de integración de capital"
      },
      {
        submenu: "Cuenta de ahorros"
      },
      {
        submenu: "Inversiones "
      },
      {
        submenu: "Depósitos a plazo"
      },
      {
        submenu: "Cuenta básica"
      },
      {
        submenu: "Información crediticia básica"
      }
    ]
  },
  {
    menu: "Depósitos a cuentas",
    submenus: [
      {
        submenu: "Inversiones"
      },
      {
        submenu: "Depósitos a plazos"
      }
    ]
  },
  {
    menu: "Administración, mantenimiento",
    submenus: [
      {
        submenu: "Cuenta de ahorros"
      },
      {
        submenu: "Depósitos a plazos"
      },
      {
        submenu: "Inversiones"
      }
    ]
  },

  {
    menu: "Consulta de cuentas",
    submenus: [
      {
        submenu: "Consulta, canal celular"
      },
      {
        submenu: "Consulta, canal telefónico"
      },
      {
        submenu: "Consulta, internet"
      },
      {
        submenu: "Consulta, oficina "
      },
      {
        submenu: "Consulta visual, cajero automático"
      },
      {
        submenu:
          "Corte de movimientos de cuenta de cualquier tipo de cuenta y por cualquier medio, a excepción de entrega en oficina en la entidad"
      }
    ]
  },
  {
    menu: "Retiros de dinero",
    submenus: [
      {
        submenu:
          "Retiro de dinero por cajero automático socios/clientes propia entidad "
      },
      {
        submenu: "Retiro de dinero por ventanilla de la propia entidad"
      }
    ]
  },
  {
    menu: "Transferencias dentro de la misma entidad",
    submenus: [
      {
        submenu:
          "Transferencias, medios electrónicos (cajero automático, internet, teléfono,celular y otros)"
      },
      {
        submenu: "Transferencias, medios físicos (ventanilla) "
      }
    ]
  },
  {
    menu: "Cancelación o cierre de cuentas",
    submenus: [
      {
        submenu: "Cuenta de ahorros"
      }
    ]
  },
  {
    menu: "Activación de cuentas",
    submenus: [
      {
        submenu: "Activación de cuenta de ahorros"
      },
      {
        submenu: "Activación de cuenta básica"
      },
      {
        submenu: "Activación de tarjeta de crédito nacional o internacional"
      },
      {
        submenu:
          "Activación de tarjeta de débito y/o pago nacional o internacional"
      }
    ]
  },
  {
    menu: "Mantenimiento de tarjeta de crédito",
    submenus: [
      {
        submenu: "Mantenimiento de tarjeta de crédito"
      },
      {
        submenu: "Mantenimiento pago mínimo de tarjeta de crédito"
      },
      {
        submenu: "Mantenimiento pago total de tarjeta de crédito"
      },
      {
        submenu: "Mantenimiento de tarjeta de débito"
      }
    ]
  },
  {
    menu: "Bloqueo, anulación o cancelación",
    submenus: [
      {
        submenu: "Bloqueo, anulación o cancelación de tarjeta de crédito"
      },
      {
        submenu:
          "Bloqueo, anulación o cancelación de tarjeta de débito y/o pago"
      }
    ]
  },
  {
    menu: "Emisión de tabla de amortización",
    submenus: [
      {
        submenu: "Emisión de tabla de amortización"
      }
    ]
  },
  {
    menu: "Emisión de tabla de amortización",
    submenus: [
      {
        submenu: "Emisión de tabla de amortización"
      }
    ]
  },
  {
    menu: "Reclamos de socios/clientes",
    submenus: [
      {
        submenu: "Reclamos injustificados"
      },
      {
        submenu: "Reclamos justificados"
      }
    ]
  },
  {
    menu: "Frecuencia de transacciones ",
    submenus: [
      {
        submenu: "Cuenta de ahorros"
      },
      {
        submenu: "Tarjeta de crédito "
      }
    ]
  },
  {
    menu: "Servicios de reposición ",
    submenus: [
      {
        submenu: "Reposición libreta/cartola/estado de cuenta por actualización"
      }
    ]
  },
  {
    menu: "Plataformas de pagos móviles",
    submenus: [
      {
        submenu: "Enrolamiento"
      },
      {
        submenu: "Consulta de saldos o movimientos"
      },
      {
        submenu: "Bloqueo, anulación o cancelación de cuenta o usuario"
      },
      {
        submenu: "Transacciones fallidas"
      },
      {
        submenu: "Cambio de clave"
      },
      {
        submenu: "Creación de usuario"
      }
    ]
  },
  {
    menu: "Transferencias Interbancarias SPI recibidas",
    submenus: [
      {
        submenu:
          "Acreditación en cuenta por transferencias electrónicas de dinero"
      }
    ]
  },
  {
    menu: "Servicios con cheques ",
    submenus: [
      {
        submenu: "Cheque de emergencia"
      },
      {
        submenu: "Cheque devuelto del exterior"
      },
      {
        submenu: "Cheque devuelto nacional"
      }
    ]
  },
  {
    menu: "Servicios de retiros",
    submenus: [
      {
        submenu:
          "Retiro de efectivo en corresponsales solidarios de la propia entidad"
      }
    ]
  },
  {
    menu: "Servicios de referencias",
    submenus: [
      {
        submenu: "Confirmaciones financieras para auditores externos"
      },
      {
        submenu:
          "Corte impreso de movimientos de cuentas para cualquier tipo de cuenta y entregado en oficinas de la entidad por solicitud expresa del socio/cliente"
      },
      {
        submenu: "Emisión de referencias financieras"
      }
    ]*/
  },
  {
    menu: "INTERNACIONAL",
    submenus: [
      {
        submenu: "Cargos por servicios financieros básicos"
      },
      {
        submenu:
          "Servicios financieros con cargos máximos cuyo cobro excede el límite establecido en la norma"
      },
      {
        submenu:
          "Servicios financieros con cargos diferenciado cuyo cobro excede el valor aprobado"
      },
      {
        submenu:
          "Cargos en servicios financieros* que exceden los valores facturados por el prestador del servicio"
      },
      {
        submenu: "Cobros por servicios no financieros* que no estén autorizados"
      },
      {
        submenu: "Comisiones o cargos no autorizados en operaciones de crédito"
      },
      {
        submenu: "Crédito no autorizado"
      },
      {
        submenu:
          "Falta de registro en pagos de la operación por parte de la entidad"
      },
      {
        submenu:
          "Imposición de castigos por pagos anticipados o negativa a recibir pagos anticipados"
      },
      {
        submenu: "Anatocismo"
      },
      {
        submenu:
          "Cobros por intereses no devengados o superiores a los máximos vigentes"
      },
      {
        submenu: "Cargos por servicios financieros básicos"
      },
      {
        submenu:
          "Servicios financieros con cargos máximos cuyo cobro excede el limite establecido en la norma"
      },
      {
        submenu:
          "Servicios financieros con cargos máximos cuyo cobro excede el valor aprobado"
      },
      {
        submenu: "Cobro por consumos no autorizados con tarjeta de débito"
      },
      {
        submenu:
          "Cargos en servicios no financieros* que exceden los valores facturados por el prestador del servicio"
      },
      {
        submenu: "Cobro por servicios no financieros* que no estén autorizados"
      },
      {
        submenu:
          "Descuentos no autorizados en capital o intereses de certificados de depósito a plazo fijo "
      },
      {
        submenu: "Cargos por servicios financieros básicos"
      },
      {
        submenu:
          "Servicios financieros con cargos máximos cuyo cobro excede el limite establecido en la norma"
      },
      {
        submenu:
          "Servicios financieros con cargos diferenciado cuto cobro excede el valor aprobado"
      },
      {
        submenu: "Cobros por consumos no autorizados con tarjetas de crédito"
      },
      {
        submenu:
          "Cargos en servicios no financieros* que exceden los valores facturados por el prestador del servicio"
      },
      {
        submenu: "Emisión de tarjeta de crédito no autorizada"
      },
      {
        submenu: "Solicitud de bloqueo no realizada por la entidad"
      }
    ]
  }
];
