<template>
  <header>
    <!-- TOP HEADER -->
    <div class="top-header">
      <div class="flex flex-col md:flex-row justify-end pr-2 md:pr-20">
        <div class="contenedor-info flex">
          <p class="align-middle">Correo: info@crediya.fin.ec</p>
          <p class="align-middle">
            Telf:
            <a
              href="https://api.whatsapp.com/send?phone=+593995258404"
              target="_blank"
            >
              0995258404
            </a>
          </p>
        </div>
        <div class="contenedor-redes flex">
          <p>
            <a
              href="https://www.facebook.com/CooperativaCrediYa"
              target="_blank"
            >
              <font-awesome-icon :icon="['fab', 'facebook']" />
            </a>
          </p>
          <p>
            <a
              href="https://www.youtube.com/channel/UCJGS6ReeuATf0jqVb_wj0Zg"
              target="_blank"
              ><font-awesome-icon :icon="['fab', 'youtube']"
            /></a>
          </p>
          <p>
            <a
              href="https://twitter.com/CoopCrediYa?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
              target="_blank"
              ><font-awesome-icon :icon="['fab', 'twitter']"
            /></a>
          </p>
          <p>
            <a href="https://whatsapp.com/" target="_blank"
              ><font-awesome-icon :icon="['fab', 'whatsapp']"
            /></a>
          </p>
          <p>
            <a href="https://instagram.com/" target="_blank"
              ><font-awesome-icon :icon="['fab', 'instagram']"
            /></a>
          </p>
        </div>
      </div>
    </div>

    <!-- MENU -->
    <nav
      class="flex items-center justify-between flex-wrap p-2 m-0 sm:px-20"
      style="background-color: rgba(255,255,255,.5); position:relative; z-index:10;"
    >
      <div class="flex items-center flex-shrink-0 text-white mr-6">
        <router-link to="/">
          <img
            src="@/assets/img/logo-black.png"
            alt="logo principal crediya"
            style="height:55px; width:auto;"
          />
        </router-link>
      </div>
      <div class="block lg:hidden">
        <button
          id="boton"
          @click="menu"
          class="flex items-center px-3 py-2 border rounded text-red-400 border-red-400 hover:border-white"
        >
          <svg
            class="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div
        id="menu"
        class="w-full block flex-grow lg:flex lg:items-center lg:w-auto hidden "
      >
        <div
          class="text-sm lg:flex-grow flex justify-around flex-col md:flex-row ml-2"
        >
          <router-link
            to="/"
            class="block mt-4 lg:inline-block lg:mt-0 text-gray-800 hover:text-gray-700  mr-4"
          >
            Inicio
          </router-link>
          <drop-nosotros />
          <mega-menu />
          <drop-servicios />
          <drop-simuladores />
          <drop-proveedores />
          <drop-concursos />
          <drop-quejas />
          <drop-appmovils />

          <!-- consultas -->
          <router-link
            to="/inquiries"
            class="block mt-4 lg:inline-block lg:mt-0 text-gray-800 hover:text-gray-700  mr-4"
          >
            Consultas
          </router-link>

          <router-link
            to="/contactos"
            class="block mt-4 lg:inline-block lg:mt-0 text-gray-800 hover:text-gray-700  mr-4"
          >
            Contactos
          </router-link>

          <!-- <router-link
          
            class="block mt-4 lg:inline-block lg:mt-0 text-white bg-red-500  mr-4"
          > -->
          <div
            class="blanco block mt-4 lg:inline-block lg:mt-0 rojo text-white   mr-4 pl-4 pr-4"
            style="height: 24px; border-radius: 10px"
          >
            <a
              href="https://virtual.crediya.fin.ec/Account/Login.aspx"
              target="_blank"
              ><b> Credi Ya Virtual</b></a
            >
          </div>

          <!-- </router-link> -->

          <!-- admin -->
          <!-- <drop-admin /> -->
        </div>
      </div>
    </nav>
    <meta
      name="google-site-verification"
      content="QUhAmP53ferHboGHba_p4_yCUGLFi5tEb7iIFq2oxYg"
    />
  </header>
</template>

<script>
import Popper from "popper.js";
import MegaMenu from "./menu/MegaMenu.vue";
import DropNosotros from "./menu/DropNosotros.vue";
import DropServicios from "./menu/DropServicios.vue";
import DropSimuladores from "./menu/DropSimuladores.vue";
// import DropAdmin from "./menu/DropAdmin.vue";
import DropProveedores from "./menu/DropProveedores.vue";
import DropConcursos from "./menu/DropConcursos.vue";
import DropQuejas from "./menu/DropQuejas.vue";

import DropAppmovils from "./menu/DropAppmovils.vue";

export default {
  components: {
    MegaMenu,
    DropNosotros,
    DropServicios,
    DropSimuladores,
    DropProveedores,
    DropConcursos,
    DropQuejas,
    DropAppmovils
    // DropAdmin
  },
  name: "HeaderC",
  data: () => ({
    isOpen: false,
    dropdownPopoverShow: false
  }),
  methods: {
    menu() {
      console.log("dio click");
      const menu = document.querySelector("#menu");
      menu.classList.toggle("hidden");
    },
    toggleDropdown: function() {
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        new Popper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start"
        });
      }
    }
  }
};
</script>

<style scoped>
/******************** HEADER *******************/
/* TOP HEADER */
.blanco {
  background-color: white;
}
.rojo {
  background-color: #cd1e29;
}
.top-header {
  background-color: #cd1e29;
}
.top-header p,
.top-header a {
  color: #f6e6e6;
}

.top-header .contenedor-info p {
  font-size: 0.85rem;
  margin: 10px 30px;
}
.top-header .contenedor-redes p {
  font-size: 0.85rem;
  margin: 10px 20px;
}
@media screen and (max-width: 690px) {
  .top-header p {
    margin: 5px;
  }
  .contenedor-info {
    justify-content: space-around;
  }
  .contenedor-redes {
    display: flex;
    justify-content: center;
    margin-left: 1rem;
    margin-bottom: 10px;
  }
}
</style>
