<template>
  <div class="ha">
    <carousel
      :perPage="1"
      :autoplay="true"
      :autoplayTimeout="5000"
      :paginationEnabled="false"
      :paginationSize="0"
      :navigationEnabled="false"
      :autoplay-hover-pause="false"
      :loop="true"
      class="relative h-64 md:h-screen mt-20 md:mt-0"
    >
      <!-- Banner Valeria -->
      <slide class="relative z-0 w-full h-64 md:h-screen">
        <img
          src="@/assets/img/home/bannerPrincipal/valeria/elemento3.png"
          alt="productos crediya"
          class="z-20 absolute animacion-izquierda"
        />
        <img
          src="@/assets/img/home/bannerPrincipal/valeria/elemento2.png"
          alt="productos crediya"
          class="z-60 absolute animacion-izquierda"
        />
        <img
          src="@/assets/img/home/bannerPrincipal/valeria/elemento1.png"
          alt="productos crediya"
          class="z-20 absolute animacion-derecha"
        />
      </slide>

      <!-- Banner 0 -->
      <slide class="relative z-0 w-full h-64 md:h-screen">
        <img
          src="@/assets/img/home/bannerPrincipal/banner0/elemento1.jpg"
          alt="productos crediya"
          class="z-20 absolute animacion-abajo"
        />
        <img
          src="@/assets/img/home/bannerPrincipal/banner0/elemento3.png"
          alt="productos crediya"
          class="z-30 absolute animacion-izquierda"
        />
        <img
          src="@/assets/img/home/bannerPrincipal/banner0/elemento2.png"
          alt="productos crediya"
          class="z-20 absolute animacion-derecha"
        />
      </slide>

      <!-- Banner Seguridad -->
      <slide class="relative z-0 w-full h-64 md:h-screen">
        <img
          src="@/assets/img/home/bannerPrincipal/banner7/elemento2.png"
          alt="productos crediya"
          class="z-20 absolute animacion-izquierda"
        />
        <img
          src="@/assets/img/home/bannerPrincipal/banner7/elemento3.png"
          alt="productos crediya"
          class="z-30 absolute animacion-izquierda"
        />
        <img
          src="@/assets/img/home/bannerPrincipal/banner7/elemento1.png"
          alt="productos crediya"
          class="z-20 absolute animacion-derecha"
        />
      </slide>

      <!-- Banner 1 inversiones -->
      <slide class="relative z-0 w-full h-64 md:h-screen">
        <img
          src="@/assets/img/home/bannerPrincipal/banner1/elemento1.png"
          alt="productos crediya"
          class="z-30 absolute animacion-izquierda"
        />
        <img
          src="@/assets/img/home/bannerPrincipal/banner1/elemento3.png"
          alt="productos crediya"
          class="z-30 absolute animacion-izquierda"
        />
        <img
          src="@/assets/img/home/bannerPrincipal/banner1/elemento2.png"
          alt="productos crediya"
          class="z-20 absolute animacion-derecha"
        />
      </slide>

      <!-- Banner 2 PARTE movil-->
      <slide class="relative z-0 w-full h-64 md:h-screen">
        <img
          src="@/assets/img/home/bannerPrincipal/banner2/elemento1.png"
          alt="productos crediya"
          class="z-30 absolute animacion-derecha"
        />
        <img
          src="@/assets/img/home/bannerPrincipal/banner2/elemento3.png"
          alt="productos crediya"
          class="z-30 absolute animacion-izquierda"
        />
        <img
          src="@/assets/img/home/bannerPrincipal/banner2/elemento2.png"
          alt="productos crediya"
          class="z-20 absolute animacion-derecha"
        />
      </slide>

      <!-- Banner 3 -->
      <slide class="relative z-0 w-full h-64 md:h-screen">
        <img
          src="@/assets/img/home/bannerPrincipal/banner3/elemento1.png"
          alt="productos crediya"
          class="z-30 absolute animacion-izquierda"
        />
        <img
          src="@/assets/img/home/bannerPrincipal/banner3/elemento3.png"
          alt="productos crediya"
          class="z-30 absolute animacion-izquierda"
        />
        <img
          src="@/assets/img/home/bannerPrincipal/banner3/elemento2.png"
          alt="productos crediya"
          class="z-20 absolute animacion-derecha"
        />
      </slide>

      <!-- Banner 4 -->
      <slide class="relative z-0 w-full h-64 md:h-screen">
        <img
          src="@/assets/img/home/bannerPrincipal/banner4/elemento1.png"
          alt="productos crediya"
          class="z-30 absolute animacion-izquierda"
        />
        <img
          src="@/assets/img/home/bannerPrincipal/banner4/elemento3.png"
          alt="productos crediya"
          class="z-30 absolute animacion-izquierda"
        />
        <img
          src="@/assets/img/home/bannerPrincipal/banner4/elemento2.png"
          alt="productos crediya"
          class="z-20 absolute animacion-derecha"
        />
      </slide>

      <!-- Banner 5 -->
      <slide class="relative z-0 w-full h-64 md:h-screen">
        <a
          href="https://campus.rfd.org.ec/course/index.php?categoryid=39"
          target="_blank"
        >
          <img
            src="@/assets/img/home/bannerPrincipal/banner5/elemento1.png"
            alt="productos crediya"
            class="z-30 absolute animacion-derecha"
        /></a>
        <a
          href="https://campus.rfd.org.ec/course/index.php?categoryid=39"
          target="_blank"
        >
          <img
            src="@/assets/img/home/bannerPrincipal/banner5/elemento3.png"
            alt="productos crediya"
            class="z-30 absolute animacion-izquierda"
        /></a>
        <a
          href="https://campus.rfd.org.ec/course/index.php?categoryid=39"
          target="_blank"
        >
          <img
            src="@/assets/img/home/bannerPrincipal/banner5/elemento2.png"
            alt="productos crediya"
            class="z-20 absolute animacion-derecha"
        /></a>
      </slide>

      <!-- Banner 6 -->
      <slide class="relative z-0 w-full h-64 md:h-screen">
        <a
          href="https://www.cosede.gob.ec/pago-seguro-de-depositos/"
          target="_blank"
        >
          <img
            src="@/assets/img/home/bannerPrincipal/banner6/elemento1.png"
            alt="productos crediya"
            class="z-20 absolute animacion-izquierda"
          />
        </a>
      </slide>
    </carousel>
  </div>
</template>

<script>
export default {
  name: "HomeCarrouselPrincipal",
  watch: {
    location: function(newVal, oldVal) {
      // watch it
      console.log("Prop changed: ", newVal, " | was: ", oldVal);
      console.log("key: " + this.location.key);
    }
  }
};
</script>

<style scoped>
.animacion-derecha {
  animation-name: derecha;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  /* animation-delay: 1s;
  opacity: 0; */
}
@keyframes derecha {
  10% {
    opacity: 0;
    transform: translateX(20%);
  }

  35% {
    opacity: 1;
    transform: translateX(0);
  }
  50% {
    opacity: 1;
    transform: translateX(0);
  }

  /* 100% {
    opacity: 0;
    transform: translateX(50%);
  } */
}

.animacion-izquierda {
  animation-name: izquierda;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  /* animation-delay: 2s;
  opacity: 0; */
}
@keyframes izquierda {
  10% {
    opacity: 0;
    transform: translateX(-20%);
  }

  35% {
    opacity: 1;
    transform: translateX(0);
  }
  50% {
    opacity: 1;
    transform: translateX(0);
  }

  /* 100% {
    opacity: 0;
    transform: translateX(-50%);
  } */
}
.ha {
  height: 329px;
}
.animacion-abajo {
  animation-name: abajo;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
@keyframes abajo {
  10% {
    opacity: 0;
    transform: translateY(-20%);
  }

  35% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }

  /* 100% {
    opacity: 0;
    transform: translateY(-50%);
  } */
}
</style>
