<template>
  <div class="container">
    <div class="px-4 md:px-32 py-6">
      <h3 class="mb-2 text-xl">Crear nuevo Producto</h3>

      <!-- SEND BANNER IMAGE -->

      <div class="container bg-gray-200 rounded-xl p-5">
        <!-- component -->
        <form enctype="multipart/form-data">
          <div class="flex w-full items-center justify-around bg-grey-lighter">
            <label
              class="w-64 flex flex-col items-center px-4 py-5 bg-white text-blue-500 rounded-lg shadow-lg  border border-blue-600 cursor-pointer hover:bg-blue hover:text-blue-700"
            >
              <svg
                class="w-8 h-8"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z"
                />
              </svg>
              <span class="mt-2 text-base text-center"
                >Seleccione un banner de 2106x592 px</span
              >
              <input
                type="file"
                class="hidden"
                accept="image/*"
                @change="uploadImageBanner($event)"
                id="banner"
              />
            </label>
            <!-- img container -->
            <div class="img-container">
              <bar-loader
                class="custom-class"
                :color="'#cd1e2a'"
                :loading="loading_banner"
                :size="250"
              ></bar-loader>
              <img
                :src="url + banner"
                v-if="banner"
                alt="banner crediya"
                class="h-32 rounded"
              />
            </div>
          </div>
        </form>
      </div>

      <!-- INFORMATION NEW PRODUCT -->
      <div class="grid grid-cols-2 mt-5">
        <div class="col-span-2 md:col-span-1">
          <!-- NAME  -->
          <div class="form-group mb-2 mt-4">
            <label for="nombre" class="block font-normal mb-1"
              >Nombre del Producto
            </label>
            <input
              type="text"
              v-model="name"
              id="nombre"
              class="border px-4 py-1 w-full rounded bg-gray-200"
              ref="nombre"
            />
          </div>
          <div class="form-group mb-2">
            <label for="description" class="block font-normal mb-1"
              >Descripción</label
            >
            <input
              type="text"
              v-model="description"
              id="description"
              class="border px-4 py-1 w-full rounded bg-gray-200"
            />
          </div>

          <!-- Simulator -->
          <div class="form-group mb-4">
            <label for="tipo" class="block font-normal  mb-1"
              >Tipo de Simulador</label
            >
            <select
              name="tipo"
              v-model="simulator"
              id="tipo"
              class="border text-sm px-4 py-2 w-full rounded bg-gray-200"
            >
              <option value="0">Sin simulador</option>
              <option value="credito">Crédito</option>
              <option value="inversiones">Inversiones</option>
            </select>
          </div>
        </div>
        <div class="col-span-2 md:col-span-1 mx-2 mt-8">
          <div class="container bg-gray-200 rounded-xl p-5">
            <!-- component -->
            <form enctype="multipart/form-data">
              <div
                class="flex w-full items-center justify-around bg-grey-lighter"
              >
                <label
                  class="w-40 flex flex-col items-center px-3 py-4 bg-white text-blue-500 rounded-lg shadow-lg  border border-blue-600 cursor-pointer hover:bg-blue hover:text-blue-700"
                >
                  <svg
                    class="w-8 h-8"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z"
                    />
                  </svg>
                  <span class="mt-2 text-base text-center"
                    >Seleccione un ícono de 554x422 px</span
                  >
                  <input
                    type="file"
                    class="hidden"
                    accept="image/*"
                    @change="uploadImageIcon($event)"
                    id="icon"
                  />
                </label>
                <!-- img container -->
                <div class="img-container ">
                  <bar-loader
                    class="custom-class"
                    :color="'#cd1e2a'"
                    :loading="loading_icon"
                    :size="250"
                  ></bar-loader>
                  <img
                    :src="url + icon"
                    v-if="icon"
                    alt="banner crediya"
                    class="h-32 rounded"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- INFORMATION BENEFITS -->
      <div class="grid grid-cols-2 mt-5">
        <div class="col-span-2 md:col-span-1">
          <div class="form-group mb-2">
            <label for="password" class="block font-normal mb-1"
              >Beneficios (al final de cada item añada una punto y coma
              ";")</label
            >
            <textarea
              type="text"
              v-model="characteristics"
              id="characteristics"
              class="border px-4 py-1 w-full rounded bg-gray-200"
              name="characteristics"
            ></textarea>
          </div>
          <div class="container bg-gray-200 rounded-xl p-5">
            <!-- component -->
            <form enctype="multipart/form-data">
              <div
                class="flex w-full items-center justify-around bg-grey-lighter"
              >
                <label
                  class="w-40 flex flex-col items-center px-3 py-4 bg-white text-blue-500 rounded-lg shadow-lg  border border-blue-600 cursor-pointer hover:bg-blue hover:text-blue-700"
                >
                  <svg
                    class="w-8 h-8"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z"
                    />
                  </svg>
                  <span class="mt-2 text-base text-center"
                    >Seleccione una img Beneficios de 744x667 px</span
                  >
                  <input
                    type="file"
                    class="hidden"
                    accept="image/*"
                    @change="uploadImageBenefits($event)"
                    id="imgbeneficio"
                  />
                </label>
                <!-- img container -->
                <div class="img-container ">
                  <bar-loader
                    class="custom-class"
                    :color="'#cd1e2a'"
                    :loading="loading_benefit"
                    :size="250"
                  ></bar-loader>
                  <img
                    :src="url + imgbeneficio"
                    v-if="imgbeneficio"
                    alt="beneficio crediya"
                    class="h-32 rounded"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>

        <!-- Requirements -->
        <div class="col-span-2 md:col-span-1 mx-2">
          <div class="form-group mb-2">
            <label for="password" class="block font-normal mb-1"
              >Requisitos (al final de cada item añada una punto y coma
              ";")</label
            >
            <textarea
              type="text"
              v-model="requirements"
              id="requirements"
              class="border px-4 py-1 w-full rounded bg-gray-200"
              name="requirements"
            ></textarea>
          </div>
          <div class="container bg-gray-200 rounded-xl p-5">
            <!-- component -->
            <form enctype="multipart/form-data">
              <div
                class="flex w-full items-center justify-around bg-grey-lighter"
              >
                <label
                  class="w-40 flex flex-col items-center px-3 py-4 bg-white text-blue-500 rounded-lg shadow-lg  border border-blue-600 cursor-pointer hover:bg-blue hover:text-blue-700"
                >
                  <svg
                    class="w-8 h-8"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z"
                    />
                  </svg>
                  <span class="mt-2 text-base text-center"
                    >Seleccione una img Requisitos de 783x894 px</span
                  >
                  <input
                    type="file"
                    class="hidden"
                    accept="image/*"
                    @change="uploadImageRequirements($event)"
                    id="imgrequisitos"
                  />
                </label>
                <!-- img container -->
                <div class="img-container ">
                  <bar-loader
                    class="custom-class"
                    :color="'#cd1e2a'"
                    :loading="loading_requirements"
                    :size="250"
                  ></bar-loader>
                  <img
                    :src="url + imgrequisitos"
                    v-if="imgrequisitos"
                    alt="requisitos crediya"
                    class="h-32 rounded"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- SEND DATA INPUT -->
      <form>
        <!-- BTN SAVE -->
        <div class="form-group mb-2 mt-5">
          <button
            type="button"
            id="guardar"
            class="bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded w-full"
            @click="saveProduct"
            @keydown.tab.exact.prevent=""
          >
            Guardar Producto
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import api from "@/api/product";
import url from "@/api/url";

export default {
  name: "NewProduct",
  data() {
    return {
      url: url,
      name: "",
      description: "",
      simulator: "",
      characteristics: "",
      requirements: "",
      icon: "",
      banner: "",
      imgbeneficio: "",
      imgrequisitos: "",
      loading_banner: false,
      loading_icon: false,
      loading_benefit: false,
      loading_requirements: false
    };
  },
  methods: {
    saveProduct() {
      if (
        this.name == "" ||
        this.description == "" ||
        this.simulator == "" ||
        this.characteristics == "" ||
        this.requirements == "" ||
        this.banner == "" ||
        this.icon == "" ||
        this.imgbeneficio == "" ||
        this.imgrequisitos == ""
      ) {
        this.$swal("Llene todo los campos", "", "error");
      } else {
        api
          .saveProduct(
            this.name,
            this.description,
            this.simulator,
            this.characteristics,
            this.requirements,
            this.banner,
            this.icon,
            this.imgbeneficio,
            this.imgrequisitos
          )
          .then(resp => {
            console.log(resp);
            if (resp.status == "ok") {
              this.$swal("Producto Guardado", "", "success").then(() => {
                // this.$router.go(this.$router.currentRoute);
                this.name = "";
                this.description = "";
                this.simulator = "";
                this.characteristics = "";
                this.requirements = "";
                this.banner = "";
                this.icon = "";
                this.imgbeneficio = "";
                this.imgrequisitos = "";
              });
            } else {
              this.$swal("Error", "", "error");
            }
          });
      }
    },

    // IMAGEN BANNER
    uploadImageBanner(event) {
      let data = new FormData();
      this.loading_banner = true;
      data.append("name", event.target.files[0].name);
      data.append("file", event.target.files[0]);
      if (data != "")
        api.uploadImage(data).then(resp => {
          console.log("resp upload imagen : ", resp);
          this.loading_banner = false;
          this.banner = resp;
        });
    },

    // IMAGEN ICON
    uploadImageIcon(event) {
      this.loading_icon = true;
      let data = new FormData();
      data.append("name", event.target.files[0].name);
      data.append("file", event.target.files[0]);
      api.uploadImage(data).then(resp => {
        console.log("resp upload imagen : ", resp);
        this.loading_icon = false;
        this.icon = resp;
      });
    },

    // BENEFITS IMAGE
    uploadImageBenefits(event) {
      this.loading_benefit = true;
      let data = new FormData();
      data.append("name", event.target.files[0].name);
      data.append("file", event.target.files[0]);
      api.uploadImage(data).then(resp => {
        console.log("resp upload imagen : ", resp);
        this.loading_benefit = false;
        this.imgbeneficio = resp;
      });
    },

    // REQUIREMENTS IMAGE
    uploadImageRequirements(event) {
      this.loading_requirements = true;
      let data = new FormData();
      data.append("name", event.target.files[0].name);
      data.append("file", event.target.files[0]);
      api.uploadImage(data).then(resp => {
        console.log("resp upload imagen : ", resp);
        this.loading_requirements = false;
        this.imgrequisitos = resp;
      });
    }
  }
};
</script>
<style></style>
