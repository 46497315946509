import axios from "axios";
// import url from "./url";
//import https from "https";

var url = "https://procesostres.crediya.fin.ec";

// CONSULTAR SIMULATION
function consultSimulation(tipoCredito, cantidad, plazo, amortizacion) {
  return axios
    .post(`${url}/simulador/consulta-simulador`, {
      tipoCredito,
      cantidad,
      plazo,
      amortizacion
    })
    .then(resp => resp.data);
}

// CONSULT INVERSION
function consultInversion(cantidad, plazo) {
  return axios
    .post(`${url}/simulador/consulta-inversiones`, {
      cantidad,
      plazo
    })
    .then(resp => resp.data);
}

export default {
  consultSimulation,
  consultInversion
};
