<template>
  <!-- DROPBOX -->
  <div class="relative" @mouseover="showDrop" @mouseleave="hideDrop">
    <button
      @click="toggleMenu"
      class="block mt-4 lg:inline-block lg:mt-0 text-gray-800  hover:text-gray-700  mr-4"
    >
      Servicios
    </button>
    <div class="absolute w-full">&nbsp;</div>
    <div
      v-if="open"
      class="absolute w-44 bg-white border-b z-10 p-3  rounded shadow ml-0 md:-ml-5 "
    >
      <router-link
        to="/pagos"
        class="text-sm py-2  px-1 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-red-600 hover:text-white "
        >Pagos</router-link
      >
      <router-link
        to="/convenios"
        class="text-sm py-2 px-1  font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-red-600 hover:text-white "
        >Convenios</router-link
      >
    </div>
  </div>
  <!-- DROPBOX END -->
</template>
<script>
export default {
  name: "DropServicios",
  data() {
    return {
      open: false
    };
  },
  methods: {
    showDrop() {
      this.open = true;
    },
    hideDrop() {
      this.open = false;
    },
    toggleMenu() {
      this.open = !this.open;
    }
  }
};
</script>
